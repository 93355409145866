import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { submitBrochure, submitFeedback } from "../../../actions/feedback";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const BrochureModal = ({ setFormOpen, formOpen, title, brochureLink }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nameErr, setNameErMsg] = useState("");
  const [emailErr, setEmailErMsg] = useState("");
  const [phErr, setPhErr] = useState("");
  const [msgEr, setErMsg] = useState("");
  const [loadData, setLoadData] = useState(false);
  const [qualification, setQualification] = useState("");
  const [courseName, setCourseName] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  const feedbackForm = async () => {
    setLoadData(true);
    const formData = new FormData();
    const formData2 = new FormData();
    formData2.append("your-name", name);
    formData2.append("your-email", email);
    formData2.append("your-mobile", phone);
    formData2.append("qualification", qualification);
    formData2.append("program-name", title);

    formData.append("FirstName", name);
    formData.append("Email", email);
    formData.append("MobileNumber", phone);
    formData.append("Textb3", qualification);
    formData.append("Course", title);

    try {
      const submitfeedback = await submitBrochure({ formData, formData2 });
      console.log(submitfeedback, "submit feedback browcher");
      if (submitfeedback.status === "validation_failed") {
        // toast.error(submitfeedback.message);
        if (Array.isArray(submitfeedback.invalid_fields)) {
          const name = submitfeedback.invalid_fields.find((item) => {
            // return item.field === "your-name";
            return item.field === "FirstName";
          });

          const email = submitfeedback.invalid_fields.find((item) => {
            // return item.field === "your-email";
            return item.field === "Email";
          });
          const phone = submitfeedback.invalid_fields.find((item) => {
            // return item.field === "your-mobile";
            return item.field === "MobileNumber";
          });

          if (name !== undefined) {
            setNameErMsg(name.message);
          } else {
            setNameErMsg("");
          }

          if (email !== undefined) {
            setEmailErMsg(email.message);
          } else {
            setEmailErMsg("");
          }
          if (phone !== undefined) {
            setPhErr(phone.message);
          } else {
            setPhErr("");
          }
        }
      } else {
        setFormOpen(!formOpen);
        toast.success(submitfeedback.message);
        setLoadData(false);
        setName("");
        setEmail("");
        setPhone("");
        setPhErr("");
        setEmailErMsg("");
        setErMsg("");
        // window.location.replace(
        //   // "https://learningtlms.in/gt/index.php/wp-json/download/pdf_file?file_url=" +
        //   brochureLink
        // );

        window.open(
          // "https://learningtlms.in/gt/index.php/wp-json/download/pdf_file?file_url=" +
          brochureLink,
          "_blank"
        );

        // fetch(
        //   "https://learningtlms.in/gt/index.php/wp-json/download/pdf_file?file_url=https://rwtpl.com/demov/gt/wp-content/uploads/2023/07/ACCA.pdf"
        // );
      }
      setLoadData(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={`modal fade ${formOpen ? "show d-block" : "d-none"}`}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Make a enquiry
              </h1>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setFormOpen(!setFormOpen)}
              />
            </div>
            <div className="modal-body">
              <div className="left-side-form cor_popup">
                <form method="post" action="#" id="email-form" className="">
                  <div className="enquire-now-sideform">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="first-name"
                          id="first-name"
                          placeholder="First Name*"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                        {nameErr && <p style={{ color: "red" }}>{nameErr}</p>}
                        <label htmlFor="first-name">
                          Name <span>*</span>
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          name="your-email"
                          id="your-email"
                          placeholder="Email ID*"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        {emailErr && <p style={{ color: "red" }}>{emailErr}</p>}
                        <label htmlFor="your-email">
                          Email ID <span>*</span>
                        </label>
                      </div>
                    </div>

                    {/* <div style={{ display: "flex", gap: "5px" }}> */}
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="your-mobile"
                        name="your-mobile"
                        placeholder="Mobile No.*"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                      {phErr && <p style={{ color: "red" }}>{phErr}</p>}
                      <label htmlFor="your-mobile">
                        Mobile No.<span>*</span>
                      </label>
                    </div>
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="your-qualification"
                        name="your-qualification"
                        placeholder="Qualification*"
                        value={qualification}
                        onChange={(e) => setQualification(e.target.value)}
                        required
                      />
                      {phErr && <p style={{ color: "red" }}>{phErr}</p>}
                      <label htmlFor="your-designation">
                        Qualification.<span>*</span>
                      </label>
                    </div>
                    {/* </div> */}

                    <div className="col-lg-12 pt-3 text-end">
                      {loadData === true ? (
                        <button
                          type="button"
                          className="btn btn-dark btn-lg py-3 px-5 w-100"
                          //   onClick={() => feedbackForm()}
                          //   disabled
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-dark btn-lg py-3 px-5 w-100"
                          onClick={() => feedbackForm()}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade ${
          formOpen ? "show d-block" : "d-none"
        }`}
      ></div>
    </>
  );
};

export default BrochureModal;
