import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";

export const homeSlice = createSlice({
  name: "homeData",
  initialState: {
    loading: false,
    homeData: [],
  },
  reducers: {
    Requested: (state, action) => {
      state.loading = true;
    },
    Failed: (state, action) => {
      state.loading = false;
    },
    Received: (state, action) => {
      state.loading = false;

      state.homeData = action.payload.data;
    },
  },
});

export const { Received, Requested, Failed } = homeSlice.actions;
//console.log(body);

export const fetchHomeData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/home/page",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: Received.type,
    onStart: Requested.type,
    onError: Failed.type,
  });
