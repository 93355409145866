import { useDispatch, useSelector } from "react-redux";
import Banner from "../components/pagesections/Contact/Banner";
import Content from "../components/pagesections/Contact/Content";
import Locations from "../components/pagesections/Contact/Locations";
import { useEffect } from "react";
import { fetchContactData } from "../store/page";
import SeoComponent from "../components/elements/SeoComponent";

const Contact = () => {

  const dispatch = useDispatch();

  const contactData:any = useSelector<any>(
    (state:any) => state.pageData.contactPageData.data
  );

  const loading = useSelector(
    (state: any) => state.pageData.refundPageData.loading
  );

  useEffect(() => {
    dispatch(fetchContactData());
  }, [dispatch]);


  const contactInfoData = [
    {
      _id: 1,
      icons: "fa-solid fa-envelope",
      info: "contact@gtacademy.in",
      infoType: "Contact Email ID",
      type: "mail",
    },
    {
      _id: 2,
      icons: "fa-solid fa-phone",
      info: "012 4462 8000",
      infoType: "Contact Number",
      type: "phone",
    },
    {
      _id: 3,
      icons: "fa-solid fa-location-dot",
      info: "DLF Square, 21st, Jacaranda Marg, DLF Phase 2, Gurugram, Haryana 122002",
      infoType: "Location",
      type: "location",
    },
    {
      _id: 4,
      icons: "fa-solid fa-globe",
      info: "www.gtacademy.in",
      infoType: "Website",
      type: "website",
    },
  ];

  const contactInfoSocial = [
    {
      _id: 1,
      icons: "fa-brands fa-facebook-f",
    },
    {
      _id: 2,
      icons: "fa-brands fa-twitter",
    },
    {
      _id: 3,
      icons: "fa-brands fa-youtube",
    },
    {
      _id: 4,
      icons: "fa-brands fa-linkedin",
    },
  ];

  const personInfo = [
    {
      _id: 1,
      name: "Kapil Arora",
      position: "Director",
      image: window.location.origin + "/images/kapil_arora.jpg",
      email: "kapil.arora@in.gt.com",
      phone: "+91 965 419 0274",
    },
    {
      _id: 2,
      name: "Pradeep Pandey",
      position: "Director",
      image: window.location.origin + "/images/pradeep-pandey.jpg",
      email: "pradeep.pandey@in.gt.com",
      phone: "78380 00185",
    },
    {
      _id: 3,
      name: "ROHIT GUPTA",
      position: "Director",
      image: window.location.origin + "/images/rohit_gupta.jpg",
      email: "rohit.gupta1@in.gt.com",
      phone: "+91 98913 95363",
    },
  ];



  return (
    <>
     {contactData && contactData?.seo_data && (
            <SeoComponent
              title={contactData?.seo_data?.meta_title}
              description={contactData?.seo_data?.meta_description}
              keyword={contactData?.seo_data?.meta_keyword}
            />
          )}
      <Banner contactData = {contactData} />
      <Content
        contactData={contactData}
        socialIcons={contactInfoSocial}
        personInfo={contactData}
      />
      <Locations contactData = {contactData}/>
    </>
  );
};

export default Contact;
