import React from 'react'
import NewsLetterCTA from '../components/pagesections/Shared/NewsLetterCTA'
import PaymentBody from '../components/pagesections/Thankyou/PaymentBody'

const ThankYou = () => {
    return (
        <>
            <PaymentBody/>
            {/* <NewsLetterCTA/> */}
        </>
    )
}

export default ThankYou