import React from "react";
import { useDeviceType } from "../../../config/device";
import Accordion from "../../elements/Accordion";

interface Props {
  course_structure: any[];
  course_structure_title: any;
  course_structure_content: any;
}

function Coursestructure({
  course_structure,
  course_structure_title,
  course_structure_content,
}: Props) {
  console.log(course_structure, "course_structure");
  return (
    <>
      <section className="Course_Structure-sec py-3 section-bt bg-light-purple-1">
        <div className="container customwid">
          <div className="text-center section-heading-lg mb-4">
            <h4>{course_structure_title}</h4>
            <p> {course_structure_content}</p>
          </div>
          <div className="row justify-content-center desktop-accordion">
            {Array.isArray(course_structure) &&
              course_structure.map((item, index) => {
                return (
                  <>
                    <div className="col-lg-4 col-md-6 col-12">
                      <div className="Course__box">
                        <h3 className="title purple-color">{item.title}</h3>
                        <div className="should_attend-Ul mt-2 d-block">
                          <ul>
                            {Array.isArray(item.courses) &&
                              item.courses.map((itm: any) => {
                                return <li>{itm.course}</li>;
                              })}
                          </ul>
                          {item.bottom_title !== "" ? (
                            <div className="mg-title mb-2">
                              <h6 className="text-white mb-0"> Experience </h6>
                              <p className="mb-0"> {item.bottom_title}</p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
          </div>
          <div className="mobile-accordion">
            <Accordion course_structure={course_structure} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Coursestructure;
