import React from 'react'

interface Props {
    check_your_exemption_title:any,
    check_your_exemption_description:any
   

}
const  Exemption = ({check_your_exemption_title,check_your_exemption_description} : Props) => {
  
    function createMarkup() {
        return {__html: check_your_exemption_description }
      }
    return (
        <><section className=">Check-section py-3 section-content section-bt">
        <div className="container customwid ">
          <div className="text-center section-heading-lg mb-3">
            <h4>{check_your_exemption_title}</h4>
          </div>
          <div className="row">
          <div dangerouslySetInnerHTML={createMarkup()}></div> 
          </div>
        </div>
      </section></>
    )
}

export default Exemption;
