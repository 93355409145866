import ReactPaginate from "react-paginate";

const Pagination = ({
  offset = 0,
  limit = 10,
  count = 20,
  onSubmit = () => {},
}: any) => {
  // const itemCount = (offset + limit) > count ? count : (offset + limit);
  const currentPage = Math.ceil(offset / limit) || 0;
  const perPageCount = Math.ceil(count / limit);

  return (
    <div className="row">
      <div className="col-lg-12 pt-3 text-center">
        <div className="pagination-wrapper d-inline-block">
          <div className="section-pagination">
            <div aria-label="Page navigation">
              {perPageCount > 0 && (
                <ReactPaginate
                  breakLabel="..."
                  breakClassName=""
                  nextLabel="Next"
                  nextLinkClassName="page-link page-link-last"
                  previousLabel="Prev"
                  previousLinkClassName="page-link page-link-first"
                  onPageChange={onSubmit}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  activeLinkClassName="page-link-active"
                  pageCount={perPageCount}
                  forcePage={
                    currentPage - 1 < 0 ? currentPage : currentPage - 1
                  }
                  containerClassName="pagination flex-wrap justify-content-center mt-md-5 mt-3"
                />
              )}
              {/* <ul className='pagination flex-wrap justify-content-center mt-md-5 mt-3'> */}
              {/* <li className='page-item'>
                  {' '}
                  <a className='page-link page-link-first' href='#!'>
                    {' '}
                    Prev
                  </a>{' '}
                </li>
                <li className='page-item'>
                  <a className='page-link page-link-active' href='#!'>
                    1
                  </a>
                </li>
                <li className='page-item'>
                  <a className='page-link' href='#!'>
                    2
                  </a>
                </li>
                <li className='page-item'>
                  <a className='page-link' href='#!'>
                    3
                  </a>
                </li>
                <li className='page-item'>
                  <a className='page-link' href='#!'>
                    4
                  </a>
                </li>
                <li className='page-item'>
                  <a className='page-link' href='#!'>
                    5
                  </a>
                </li>
                <li className='page-item'>
                  <a className='page-link' href='#!'>
                    6
                  </a>
                </li>
                <li className='page-item'>
                  {' '}
                  <a className='page-link page-link-last' href='#!'>
                    Next{' '}
                  </a>{' '}
                </li> */}
              {/* </ul> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
