const LearningSpeech = ({ aboutData }: any) => {
  const image = window.location.origin + "/images/grant-thornton-alumnus-shape.png";
  return (
    <>
      <section className="Learning_Development-sec">
        <div className="container customwid">
          <div className="row">
            <div className="col-lg-7 Development_text-block pe-md-4">
              <h2 className="fs28 fw700">{aboutData?.development_title}</h2>
              <div dangerouslySetInnerHTML={{ __html: aboutData?.development_content }}></div>
            </div>

            <div className="col-lg-5">
              <div className="helping_shape-sec border rounded position-relative">
                <figure className="logoto">
                  <img src={image} alt="logo" />
                </figure>
                <div className="text-center helping_shape_block">
                  <h3 className="purple-color fs27 fw700">{aboutData?.right_side_title}</h3>
                  <div className="border-after-bd rounded mt-2 mb-4"></div>
                  <h6>{aboutData?.right_side_content}</h6>
                  <figure className="mb-5 mt-4">
                    <img src={aboutData?.right_side_image} className="img-fluid" alt="logo" />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LearningSpeech;
