import React, { ReactNode } from "react";

type AppProps = {
  data: {
    description: ReactNode;
    featured_image: any;
    image: string;
    title: string;
    text: string;
  };
};

const Banner = ({ data }: AppProps) => {
  return (
    <>
      <section className="inner-banner-top resp-banner" style={{ backgroundImage: `url(${data?.featured_image})` }}>
        <div className="container customwid h-100">
          <div className="row h-100">
            <div className="col-xl-12 breadcrumbmain">
              <div className="gt-breadcrumb mt-3">
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item active text-white" aria-current="page">
                      Corporate Training
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="main-content">
              {data?.title && <h1 className="banner__title">{data?.title}</h1>}
              {data?.description && <p className="mt-2">{data?.description}</p>}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
