import { useEffect } from "react";
import Banner from "../components/pagesections/About/Banner";
import CeoDeskSec from "../components/pagesections/About/CeoDeskSec";
import GlobalSnapshot from "../components/pagesections/About/GlobalSnapshot";
import GtiSpacing from "../components/pagesections/About/GtiSpacing";
import LearningSpeech from "../components/pagesections/About/LearningSpeech";
import OurMission from "../components/pagesections/About/OurMission";
import NewsLetterCTA from "../components/pagesections/Shared/NewsLetterCTA";
import { useDispatch, useSelector } from "react-redux";
import { fetchAboutData } from "../store/page";
import Award from "../components/pagesections/Shared/Award";
import LeadershipTeam from "../components/pagesections/About/LeadershipTeam";
import HomeLoader from "../shared/HomeLoader";
import SeoComponent from "../components/elements/SeoComponent";

const About = () => {
  const dispatch = useDispatch();
  const aboutData = useSelector(
    (state: any) => state.pageData.aboutPageData.data
  );
  const loading = useSelector(
    (state: any) => state.pageData.aboutPageData.loading
  );

  useEffect(() => {
    dispatch(fetchAboutData());
  }, [dispatch]);

  return (
    <>
      {loading && <section className="About-banner-top"><HomeLoader/></section>}
      {!loading && (
        <>
         {aboutData && aboutData?.seo_data && (
            <SeoComponent
              title={aboutData?.seo_data?.meta_title}
              description={aboutData?.seo_data?.meta_description}
              keyword={aboutData?.seo_data?.meta_keyword}
            />
          )}
          <Banner aboutData={aboutData} />
          <CeoDeskSec aboutData={aboutData} />
          <LearningSpeech aboutData={aboutData} />
          <Award aboutData={aboutData?.awards} />
          <GtiSpacing aboutData={aboutData} />
          <OurMission aboutData={aboutData} />
          <GlobalSnapshot aboutData={aboutData} />
          <LeadershipTeam aboutData={aboutData?.team_member} />
          {/* <NewsLetterCTA /> */}
        </>
      )}
    </>
  );
};

export default About;
