import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrivacyData } from "../store/page";
import Banner from "../components/pagesections/Privacy/Banner";
import Content from "../components/pagesections/Privacy/Content";
import HomeLoader from "../shared/HomeLoader";
import SeoComponent from "../components/elements/SeoComponent";

const Policy = () => {
  const dispatch = useDispatch();

  const privacyData:any = useSelector<any>(
    (state) => state.pageData.privacyPageData.data
  );

  const loading = useSelector(
    (state: any) => state.pageData.privacyPageData.loading
  );

  useEffect(() => {
    dispatch(fetchPrivacyData());
  }, [dispatch]);

  return (
    <>
      {loading && <section className="inner-banner-top"><HomeLoader/></section>}

      {!loading && (
        <>
         {privacyData && privacyData?.seo_data && (
            <SeoComponent
              title={privacyData?.seo_data?.meta_title}
              description={privacyData?.seo_data?.meta_description}
              keyword={privacyData?.seo_data?.meta_keyword}
            />
          )}
          <Banner privacyData={privacyData} />
          <Content privacyData={privacyData} />
        </>
      )}
    </>
  );
};

export default Policy;
