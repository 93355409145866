import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper";
import CompaniesSlide from "../../items/CompaniesSlide";

const Companies = ({ companiesData }: any) => {
  return (
    <>
      <section className=" trusted_by bg-light trusted_company_sec">
        <div className="w-full">
          {companiesData?.logo_heading && (
            <div className=" line_title ">
              <h3> {companiesData?.logo_heading} </h3>
            </div>
          )}

          <div className="w-100 mx-auto">
            <div className="trusted-logo">
              <div className="container">
                <Swiper
                  
                  spaceBetween={30}
                  centeredSlides={true}
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    el: ".swiper-pagination",
                  }}
                  breakpoints={{ 
                    0: {
                      slidesPerView:3
                    }, 
                    768: {
                      slidesPerView:5
                    },
                  }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  modules={[Autoplay]}
                >
                  {Array.isArray(companiesData?.logos) &&
                    companiesData?.logos.map((slide: any, index: any) => (
                      <SwiperSlide key={index}>
                        <CompaniesSlide image={slide.logo_image} name="" />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="img-wrap">
          <img
            src="images/shadow.png"
            alt=""
            className="w-full h-full object-cover"
          />
        </div> */}
      </section>
    </>
  );
};

export default Companies;
