import React, { ReactNode } from "react";
import Image from "../../elements/Image";

type AppProps = {
  data: {
    trusted_brand_image: any;
    trusted_title: ReactNode;
    title: string;
    corporates: any[];
  };
};

const TrustedCorporates = ({ data }: AppProps) => {
  return (
    <>
      <section className="trusted-by-section sec-spacing light-bg border-bottom">
        <div className="container customwid py-3">
          {data?.trusted_title && <h2 className="fs32 fw700 text-center mb-3">{data?.trusted_title}</h2>}
          {data?.trusted_brand_image?.length > 0 && (
            <div className="brands-wrap row justify-content-center g-2">
              {data?.trusted_brand_image?.map((item: any) => (
                <div className="col-6 col-sm-4 col-md-4 col-lg-3" key={item._id}>
                  <div className="brand-block shadow-sm">
                    <Image src={item?.brand_image} alt={item?._id} width={"100%"} height={"100%"} effect={"blur"} classes={"img-fluid"} />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default TrustedCorporates;
