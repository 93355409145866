import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const GlobalSnapshot = ({ aboutData }: any) => {
  Fancybox.bind('[data-fancybox="snapshot-video"]', {
    // Your custom options
  });

  return (
    <>
      <section className="Global_Snapshot sec-spacing">
        <div className="container">
          <h4 className="all_heading text-center">{aboutData?.global_title}</h4>
          <figure className="position-relative mt-4">
            <div className="video-btn">
              <a className="popup-video" data-fancybox="snapshot-video" href="https://www.youtube.com/watch?v=rwm9m1DxQnY">
                <i className="fas fa-play"></i>
              </a>
            </div>
            <img src={aboutData?.background_image_global} className="img-fluid" alt="poster" />
          </figure>

          <div className="Global_Snapshot-text mt-5" dangerouslySetInnerHTML={{ __html: aboutData?.global_extra }}></div>
        </div>
      </section>
    </>
  );
};

export default GlobalSnapshot;
