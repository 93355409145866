import Image from "../elements/Image";

type AppProps = {
  image: string;
  name: string;
};

const CompaniesSlide = ({ image }: AppProps) => {
  return (
    <>
      <div className="item">
        <figure>
          <div className="company-logos-wrap">
            <Image
              src={image}
              alt=""
              width={"100%"}
              height={"100%"}
              effect={"blur"}
            />
          </div>
        </figure>
      </div>
    </>
  );
};

export default CompaniesSlide;
