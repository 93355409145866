import React, { useEffect, useRef, useState } from "react";
import CheckoutButton from "./CheckoutButton";
import { useDispatch } from "react-redux";

interface AppProps {
  setCalculatorOpen: any;
  calculatorOpen: any;
  programData: any;
}

const CalculatorModal = ({
  setCalculatorOpen,
  calculatorOpen,
  programData,
}: AppProps) => {
  const [childData, setChild] = useState([]);
  const [isNext, setNext] = useState(false);
  const [isExamType, setExamType] = useState(false);
  const [isResetArray, setResetArray] = useState([]);
  const checkbox = useRef<HTMLInputElement>(null);
  const [resultData, setMainRes] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selected, setSelectedId] = useState<number[]>([]);
  const [selectedOption, setSelectedoption] = useState<string[]>([]);
  const groupByCategory =
    Array.isArray(programData?.child_programs) &&
    programData?.child_programs.reduce((group: any, product: any) => {
      const { program_type } = product;
      group[program_type] = group[program_type] ?? [];
      group[program_type].push(product);
      return group;
    }, {});
  const dispatch = useDispatch();
  var result: any = Object.keys(groupByCategory);
  useEffect(() => {
    let ar: any = [];
    Array.isArray(result) &&
      result.map((item: any, index: any) => {
        ar.push({ title: item, qty: 1 });
      });
    setMainRes(ar);
  }, [result.length > 0]);

  useEffect(() => {
    let mainArry: any = [];
    Array.isArray(result) &&
      result.map((item: any, index: any) => {
        let findData: any = { program_price: "0" };
        findData =
          Array.isArray(programData?.child_programs) &&
          programData?.child_programs.find((it: any, index: any) => {
            return it.program_type === item;
          });
        mainArry.push({
          program_id: findData?.program_id,
          program_title: item,
          product_sku: 1,
          program_price: findData?.program_price,
        });
      });

    setChild(mainArry);
  }, [calculatorOpen]);

  const selectPaper = (
    value: any,
    program_price: any,
    program_id: any,
    inx: any
  ) => {
    let modifiedData: any = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    modifiedData =
      Array.isArray(resultData) &&
      resultData.map((it: any, i: any) => {
        return i === inx ? { title: it.title, qty: value.value } : it;
      });

    setMainRes(modifiedData);
    let updatedData: any = [];

    updatedData =
      Array.isArray(childData) &&
      childData.map((x: any) =>
        x.program_id === program_id
          ? {
              program_id: program_id,
              program_title: x.program_title,
              program_sku: value.value,
              program_price: program_price * parseInt(value.value),
            }
          : x
      );
    setChild(updatedData);

    // if (parseInt(value.value) === 0) {
    //     let updatedData1: any = updatedData.filter((t: any, m: any) => { return t.program_id !== program_id });

    //     setChild(updatedData1)

    // }

    // if (parseInt(value.value) !== 0) {

    //     setChild(updatedData)

    // }
  };

  const next = () => {
    setNext(true);
    setExamType(true);
  };

  useEffect(() => {
    let checkboxData: any = [];
    Array.isArray(childData) &&
      childData.map((item: any, index: any) => {
        const filterData = programData?.child_programs.filter(
          (it: any, index: any) => {
            return it.program_type === item.program_title;
          }
        );

        const sku = item.program_sku ? parseInt(item.program_sku) : 1;
        checkboxData.push({
          mainTitle: item.program_title,
          sku: sku,
          options: filterData,
          selectCount: 0,
          totalAmount: parseInt(filterData[0].program_price) * sku,
        });
      });

    setSelectedData(checkboxData);
  }, [isNext === true]);

  const selectPro = (check: any, progName: any, sku: any) => {
    if (check.checked) {
      var numberArray = [];
      var ar2: any = [...selected, check.value];
      for (var i = 0; i < ar2.length; i++) {
        numberArray.push(parseInt(ar2[i]));
      }

      setSelectedId(numberArray);
      var filterData: any[] = programData?.child_programs.filter(
        (it: any, index: any) => {
          return it.program_id === parseInt(check.value);
        }
      );

      setSelectedoption([...selectedOption, ...filterData]);
    } else {
      var numberArray1 = [];
      var ar: any = selected.filter((item, index) => {
        return item !== check.value;
      });
      for (var u = 0; u < ar.length; u++) {
        numberArray1.push(parseInt(ar[u]));
      }
      var filterData1: any[] = selectedOption.filter((it: any, index: any) => {
        return it.program_id !== parseInt(check.value);
      });
      // setSelectedoption();
      setSelectedId(numberArray1);
      setSelectedoption(filterData1);
    }
  };

  let total = 0;
  return (
    <React.Fragment>
      <div
        className={`modal fade ${calculatorOpen ? "show d-block" : "d-none"}`}
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-bottom-0">
              {/*<h1 class="modal-title fs-5" id="staticBackdropLabel">ACCA Fee Calculator</h1>*/}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                onClick={() => setCalculatorOpen(false)}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12 main-book-fom">
                    <div className="card px-0 pb-0 mb-3 ps-2 pe-2">
                      <form id="msform">
                        {/* progressbar */}
                        <ul id="progressbar">
                          <li className={"active"} id="account">
                            <strong>Choose No. of Papers</strong>
                          </li>
                          <li id="personal" className={isNext ? "active" : ""}>
                            <strong>Choose Exams</strong>
                          </li>
                        </ul>
                        <br />
                        {/* fieldsets */}
                        {!isNext && (
                          <fieldset
                            style={{ position: "relative", opacity: 1 }}
                          >
                            <div className="form-card text-start">
                              <div className="row justify-content-between">
                                <div className="col-md-6 col-12">
                                  <h2 className="fs-title">
                                    Choose No. of Papers
                                  </h2>
                                  <div className="calc-fields-container">
                                    {Array.isArray(resultData) &&
                                      resultData.map((item: any, inx: any) => {
                                        const findData =
                                          programData?.child_programs.filter(
                                            (it: any, index: any) => {
                                              return (
                                                it.program_type === item.title
                                              );
                                            }
                                          );
                                        return (
                                          <React.Fragment>
                                            {" "}
                                            <div className="row drop-GT align-items-center">
                                              <div className="col-md-8 col-12">
                                                <div className="calc-item__title">
                                                  <span>{item.title}</span>
                                                </div>
                                                <div className="calc-item__description">
                                                  <span>
                                                    INR{" "}
                                                    {findData[0].program_price}{" "}
                                                    Fee Per Papers
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="col-md-4 col-12">
                                                <div className="calc-drop-down">
                                                  <select
                                                    className="form-select drop-downs"
                                                    aria-label="Default select example"
                                                    onChange={(e) =>
                                                      selectPaper(
                                                        e.target,
                                                        findData[0]
                                                          .program_price,
                                                        findData[0].program_id,
                                                        inx
                                                      )
                                                    }
                                                  >
                                                    <option disabled={true}>
                                                      Select Paper
                                                    </option>
                                                    <option
                                                      value={0}
                                                      selected={
                                                        parseInt(item.qty) === 0
                                                          ? true
                                                          : false
                                                      }
                                                    >
                                                      0
                                                    </option>
                                                    {Array.isArray(findData) &&
                                                      findData.map(
                                                        (itm, ii: any) => {
                                                          return (
                                                            <option
                                                              value={ii + 1}
                                                              selected={
                                                                ii + 1 ===
                                                                parseInt(
                                                                  item.qty
                                                                )
                                                                  ? true
                                                                  : false
                                                              }
                                                            >
                                                              {ii + 1}
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </React.Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                                <div className="col-md-5 col-12">
                                  <h2 className="fs-title"> Summary</h2>
                                  <div className="calc-subtotal-list-accordion">
                                    <div className="calc-subtotal-list-header">
                                      <span className="calc-subtotal-list-header__name">
                                        Name
                                      </span>
                                      <span className="calc-subtotal-list-header__unit">
                                        No. of Papers
                                      </span>
                                      <span className="calc-subtotal-list-header__value">
                                        Total
                                      </span>
                                    </div>
                                    {Array.isArray(childData) &&
                                      childData.map((item: any, indx) => {
                                        total += parseInt(item.program_price);
                                        if (parseInt(item.program_sku) !== 0) {
                                          return (
                                            <React.Fragment>
                                              <div className="sub-list-item">
                                                <span className="sub-item-title">
                                                  {item?.program_title}
                                                </span>
                                                <span className="sub-item-unit">
                                                  {item.program_sku
                                                    ? item.program_sku
                                                    : 1}
                                                </span>
                                                <span className="sub-item-value">
                                                  {" "}
                                                  {item.program_price
                                                    ? item.program_price
                                                    : 0}{" "}
                                                </span>
                                              </div>
                                            </React.Fragment>
                                          );
                                        }
                                      })}

                                    <div className="calc-subtotal-list">
                                      <div className="GT-total">
                                        <span className="subtotal-title">
                                          Total Fees
                                        </span>
                                        <span className="subtotal-value">
                                          INR {total}
                                          <small className="d-block gst">
                                            {" "}
                                            (Excluding of 18% GST)
                                          </small>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {total !== 0 && (
                              <input
                                type="button"
                                name="next"
                                className="next btn btn-dark btn-lg mt-3"
                                defaultValue="Next"
                                onClick={() => next()}
                              />
                            )}
                          </fieldset>
                        )}
                        {isNext && (
                          <fieldset
                            style={{
                              opacity: 1,
                              position: "relative",
                            }}
                          >
                            <div className="form-card text-start">
                              <div className="col-lg-12">
                                <div className="program-reg-type">
                                  <h2 className="fs-title">Choose Exams</h2>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="paymentOptions"
                                      id="New"
                                      defaultValue="One"
                                      defaultChecked={true}
                                      onChange={(e) => setExamType(true)}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="New"
                                    >
                                      Now
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="paymentOptions"
                                      id=" Later"
                                      defaultValue="Two"
                                      onChange={(e) => {
                                        setExamType(false);
                                        setSelectedId([
                                          programData?.program_id,
                                        ]);
                                        setSelectedId([
                                          programData?.program_id,
                                        ]);
                                        setSelectedoption([]);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor=" Later"
                                    >
                                      Choose Later
                                    </label>
                                  </div>
                                </div>
                                {isExamType && (
                                  <div
                                    id="showOne"
                                    className="myDiv"
                                    style={{ display: "block" }}
                                  >
                                    <div className="row mt-4">
                                      {Array.isArray(selectedData) &&
                                        selectedData.map(
                                          (item: any, index: any) => {
                                            if (item.sku !== 0) {
                                              return (
                                                <>
                                                  <div className="col-md-4 col-sm-6 mb-2">
                                                    <div className="choose-now-block">
                                                      <h5> {item.mainTitle}</h5>
                                                      {Array.isArray(
                                                        item.options
                                                      ) &&
                                                        item.options.map(
                                                          (
                                                            iti: any,
                                                            inx: any
                                                          ) => {
                                                            const tot =
                                                              selected.filter(
                                                                (
                                                                  mn: any,
                                                                  i: any
                                                                ) => {
                                                                  return (
                                                                    iti !==
                                                                    iti.program_id
                                                                  );
                                                                }
                                                              );

                                                            return (
                                                              <>
                                                                <div className="form-check mb-2">
                                                                  <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    value={
                                                                      iti.program_id
                                                                    }
                                                                    id={
                                                                      iti.program_title
                                                                    }
                                                                    //checked={selected.includes(iti.program_id)}
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      selectPro(
                                                                        e.target,
                                                                        item.mainTitle,
                                                                        parseInt(
                                                                          item.sku
                                                                        )
                                                                      )
                                                                    }
                                                                  />
                                                                  <label
                                                                    className="form-check-label"
                                                                    htmlFor={
                                                                      iti.program_title
                                                                    }
                                                                  >
                                                                    {
                                                                      iti.program_title
                                                                    }
                                                                  </label>
                                                                </div>
                                                              </>
                                                            );
                                                          }
                                                        )}
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          }
                                        )}
                                    </div>
                                  </div>
                                )}
                                {!isExamType && (
                                  <div
                                    className="myDiv mt-3 choose-now-block"
                                    id="showTwo"
                                    style={{ minHeight: "auto" }}
                                  >
                                    <p>
                                      {" "}
                                      You can pay and register for your papers,
                                      if you are not sure about selection of
                                      papers you can opt it later also and you
                                      can proceed with registration now.{" "}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                            <CheckoutButton
                              programData={programData}
                              childData={childData}
                              selected={selected}
                              selectedOption={selectedOption}
                              selectedData={selectedData}
                            />
                            <input
                              type="button"
                              name="previous"
                              className="previous action-button-previous btn btn-light me-2"
                              defaultValue="Previous"
                              onClick={() => {
                                setNext(false);
                                setSelectedoption([]);
                              }}
                            />
                          </fieldset>
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CalculatorModal;
