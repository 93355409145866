import React from 'react'

interface Props {

    certification_process_title:any;
    certification_process_content:any;
    certification_process:any[]
    certification_process_image:any
}

function CertificationProcess({certification_process_title,certification_process_content,certification_process,certification_process_image}: Props) {
   

    return (
        <>
        <section className="cma-process-sec pt-3 section-content section-bt bg-light-purple-1" id=" ">
  <div className="container customwid ">
    <div className="row pb-5 pt-2">
      <div className="col-lg-6 pt-5">
        <div className=" section-heading-lg mb-4">
          <h4>{certification_process_title}</h4>
        </div>
        <p>{certification_process_content} </p>
      </div>
    </div>
    <div className="cp-container">
      <div className="row">
        <div className="col-lg-7">
          <div className="cp-wrapper">
            <h5>{certification_process_title}</h5>
            <div className="row">
            {Array.isArray(certification_process) && certification_process.map((item:any)=>{
                return (<>
                <div className="col-md-6 cp-block my-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0"> <span className="cp-icon"> <img src={item?.icon} alt=""/> </span> </div>
                  <div className="flex-grow-1">
                    <div className="cp-content">
                      <h6>{item?.title}</h6>
                      <p className="mb-0 text-white opacity-75"> {item?.content}</p>
                    </div>
                  </div>
                </div>
              </div>
                </>);
            })}
              
              
            </div>
          </div>
        </div>
        <div className="col-lg-5 Exam-image mb-5">
          <figure> <img className="w-100" src={certification_process_image} alt=""/> </figure>
        </div>
      </div>
    </div>
  </div>
</section>
        </>
    )
}

export default CertificationProcess
