import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import  BASE_URL  from "../config/host";


export const authSlice = createSlice({
    
    name: 'authData',
    initialState: {
      loading : false,
      authData :[],
      signUpData:[]
    },
    reducers:{
         
        Requested: (state, action)=>{
            state.loading = true;
         }, 
         Failed: (state, action)=>{
            state.loading = false;
            state.authData = action.payload;
         },
         Received: (state, action)=>{
            state.loading = false;
            state.authData = action.payload;
             
        },
        signupRequested: (state, action)=>{
            state.loading = true;
            state.signUpData = [];
         }, 
         signupFailed: (state, action)=>{
            state.loading = false;
            state.signUpData = action.payload;
         },
         signupReceived: (state, action)=>{
            state.loading = false;
            state.signUpData = action.payload;
             
        }
     }
});

export const {Received,Requested,Failed,signupReceived,signupRequested,signupFailed} = authSlice.actions;
//console.log(body);

export const logindata = (body:any)=> apiCallBegan({
    url: '/index.php/wp-json/users/login',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: Received.type,
    onStart: Requested.type,
    onError: Failed.type
});

export const signup = (body:any)=> apiCallBegan({
    url: '/index.php/wp-json/users/register',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: signupReceived.type,
    onStart: signupRequested.type,
    onError: signupFailed.type
});

