import React, { useEffect } from "react";
import Banner from "../components/pagesections/CorporateTrainingNew/Banner";
import TraningSolution from "../components/pagesections/CorporateTrainingNew/TraningSolution";
import ReachOut from "../components/pagesections/CorporateTrainingNew/ReachOut";
import TrustedCorporates from "../components/pagesections/CorporateTrainingNew/TrustedCorporates";
import OurApproach from "../components/pagesections/CorporateTrainingNew/OurApproach";
import OurPropositionValue from "../components/pagesections/CorporateTrainingNew/OurPropositionValue";
import { useDispatch, useSelector } from "react-redux";
import { fetchCorporateData } from "../store/page";
import SeoComponent from "../components/elements/SeoComponent";

const CorporateTrainingNew = () => {
  const dispatch = useDispatch();

  const corporateData = useSelector(
    (state: any) => state.pageData.corporatePageData.data
  );
  // const loading = useSelector(
  //   (state: any) => state.pageData.corporatePageData.loading
  // );

  useEffect(() => {
    dispatch(fetchCorporateData());
  }, [dispatch]);

  const bannerData = {
    image: window.location.origin + "/images/corporate-training-banner.jpg",
    title: "Upskill your entire workforce",
    text: "Keep your people engaged help them grow",
  };

  const trainingSolution = {
    title: "Our Corporate Training Solution",
    text: "<p>LearninGT presents a versatile corporate training solution that allows organizations to tailor modules or capsules from our standard program. With our offering, corporates have the freedom to choose the mode of training that best suits their needs, whether it's through traditional classroom sessions, dynamic online live sessions, or convenient e-learning options. We understand the importance of customization and flexibility in meeting the unique requirements of businesses, and our corporate training solution is designed to empower organizations with these choices. </p> <p>In addition to our comprehensive corporate training solutions, we are proud to offer a Learning Management System (LMS) as a service. Our LMS platform is designed to streamline and enhance the learning experience for both organizations and learners.</p> <p>With our LMS, corporates gain access to a robust and user-friendly platform that simplifies the management and delivery of training programs. The platform enables organizations to upload and organize training content, track learner progress, and generate comprehensive reports on training effectiveness. Administrators have full control over course assignments, assessments, and certifications, ensuring a seamless learning journey for employees.</p>",
  };

  const reachUsData = {
    backgroundImage: window.location.origin + "/images/reach-bg.jpg",
    title: "Reach out for more information",
    contact: {
      text: "Contact Us",
      link: "/contact-us",
    },
    contactPerson: {
      name: "Pradeep Pandey",
      designation: "DIRECTOR",
      mobile: "+91 78380 00185",
      phone: "+91 12 4462 8000",
      email: "pradeep.pandey@in.gt.com",
    },
  };

  const trustedCorporateData = {
    title: "Trusted by Corporates",
    corporates: [
      {
        _id: 1,
        image: window.location.origin + "/images/logoss-1.png",
        title: "Mitsui & Co",
      },
      {
        _id: 2,
        image: window.location.origin + "/images/logoss-2.png",
        title: "Total",
      },
      {
        _id: 3,
        image: window.location.origin + "/images/logoss-3.png",
        title: "Airtel",
      },
      {
        _id: 4,
        image: window.location.origin + "/images/logoss-4.png",
        title: "TATA",
      },
      {
        _id: 5,
        image: window.location.origin + "/images/logoss-5.png",
        title: "HDFC Credila",
      },
      {
        _id: 6,
        image: window.location.origin + "/images/logoss-6.png",
        title: "General Electric",
      },
      {
        _id: 7,
        image: window.location.origin + "/images/logoss-7.png",
        title: "Examity",
      },
      {
        _id: 8,
        image: window.location.origin + "/images/logoss-8.png",
        title: "Brigade",
      },
      {
        _id: 9,
        image: window.location.origin + "/images/logoss-9.png",
        title: "Adani",
      },
      {
        _id: 10,
        image: window.location.origin + "/images/logoss-10.png",
        title: "Goldsman Sachs",
      },
      {
        _id: 11,
        image: window.location.origin + "/images/logoss-11.png",
        title: "HFCL",
      },
      {
        _id: 12,
        image: window.location.origin + "/images/logoss-12.png",
        title: "ONGC",
      },
      {
        _id: 13,
        image: window.location.origin + "/images/logoss-13.png",
        title: "Manipal Group",
      },
      {
        _id: 14,
        image: window.location.origin + "/images/logoss-14.png",
        title: "Federal Mogul",
      },
      {
        _id: 15,
        image: window.location.origin + "/images/logoss-15.png",
        title: "British Council",
      },
      {
        _id: 16,
        image: window.location.origin + "/images/logoss-16.png",
        title: "ESSAR",
      },
    ],
  };

  const ourApproachData = {
    title: "Our Approach",
    approach: [
      {
        _id: 1,
        image: window.location.origin + "/images/approach-step-1.png",
        title: "Needs Analysis",
        description:
          "<p>Grant Thornton will access learning need of employees based on role and hierarchy</p>",
      },
      {
        _id: 2,
        image: window.location.origin + "/images/approach-step-2.png",
        title: "Designing",
        description:
          "<ul> <li>Design program based on need analysis</li> <li>Design Learning pedagogy and delivery mode</li> <li>Create/Customize content</li> <li>Design Learning calendar</li> </ul>",
      },
      {
        _id: 3,
        image: window.location.origin + "/images/approach-step-3.png",
        title: "Implementation",
        description:
          "<ul> <li>Ensure effective training delivery of the programs</li> <li>Effective assessment and monitor feedback</li> <li>Include learning paths for each employees as a part of their performance management</li> </ul>",
      },
      {
        _id: 4,
        image: window.location.origin + "/images/approach-step-4.png",
        title: "Post Training Support",
        description:
          "<ul> <li>Refresher Sessions</li> <li>Query Management</li> <li>Recorded Videos of Select Programs</li> </ul>",
      },
    ],
  };

  const ourPropositionValueData = {
    image: window.location.origin + "/images/proposition-values.jpg",
    title: "Our Value proposition",
  };

  return (
    <>
      {corporateData && corporateData?.seo_data && (
        <SeoComponent
          title={corporateData?.seo_data?.meta_title}
          description={corporateData?.seo_data?.meta_description}
          keyword={corporateData?.seo_data?.meta_keyword}
        />
      )}
      <Banner data={corporateData} />
      <TraningSolution data={corporateData} />
      <ReachOut data={corporateData} />
      <TrustedCorporates data={corporateData} />
      <OurApproach data={corporateData} />
      <OurPropositionValue data={corporateData} />
    </>
  );
};

export default CorporateTrainingNew;
