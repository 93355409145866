import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../components/pagesections/Faq/Banner";
import FaqSec from "../components/pagesections/Faq/FaqSec";
import NewsLetterCTA from "../components/pagesections/Shared/NewsLetterCTA";
import HomeLoader from "../shared/HomeLoader";
import { fetchFaqData } from "../store/page";

const Faq = () => {
  const [courseData, setCourseData] = useState([]);
  const [enrolData, setEnrolData] = useState([]);
  const [faqultyData, setFaqultyData] = useState([]);
  const [modeData, setModeData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);

  const faqData1 = [
    {
      _id: 1,
      title: "Courses",
      dropdown: courseData,
    },
    {
      _id: 2,
      title: "Enrollment & Admissions",
      dropdown: enrolData,
    },
    {
      _id: 3,
      title: "Faculty & Teachers",
      dropdown: faqultyData,
    },
    {
      _id: 4,
      title: "Modes of Learning",
      dropdown: modeData,
    },
    {
      _id: 5,
      title: "Payments & Fee Structure",
      dropdown: paymentData,
    },
  ];
  const dispatch = useDispatch();
  const faqData = useSelector((state: any) => state.pageData.faqPageData.data);
  const loading = useSelector(
    (state: any) => state.pageData.faqPageData.loading
  );

  useEffect(() => {
    dispatch(fetchFaqData());
  }, [dispatch]);

  useEffect(() => {
    if (faqData) {
      let data: any = [];
      Array.isArray(faqData.courses_faq) &&
        faqData.courses_faq.map((item: any) => {
          let postData = {
            heading: item?.title,
            details: item?.description,
          };
          data.push(postData);
          setCourseData(data);
        });
      let enrol: any = [];
      Array.isArray(faqData.enrollment_faq) &&
        faqData.enrollment_faq.map((item: any) => {
          let enrollData = {
            heading: item?.title,
            details: item?.description,
          };
          enrol.push(enrollData);
          setEnrolData(data);
        });
      let faqulty: any = [];
      Array.isArray(faqData.faculty_faq) &&
        faqData.faculty_faq.map((item: any) => {
          let faqulData = {
            heading: item?.title,
            details: item?.description,
          };
          faqulty.push(faqulData);
          setFaqultyData(faqulty);
        });
      let mode: any = [];
      Array.isArray(faqData.modes_faq) &&
        faqData.modes_faq.map((item: any) => {
          let Modes = {
            heading: item?.title,
            details: item?.description,
          };
          mode.push(Modes);
          setModeData(mode);
        });
      let payment: any = [];
      Array.isArray(faqData.payment_faq) &&
        faqData.payment_faq.map((item: any) => {
          let pay = {
            heading: item?.title,
            details: item?.description,
          };
          payment.push(pay);
          setPaymentData(payment);
        });
    }
  }, [faqData]);
  return (
    <>
      {loading && <section className="inner-banner-top"><HomeLoader/></section>}
      {!loading && (
        <>
          <Banner faqData={faqData} />
          <FaqSec faqData={faqData1} />
          {/* <NewsLetterCTA /> */}
        </>
      )}
    </>
  );
};

export default Faq;
