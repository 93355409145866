import React from 'react'

interface Props { gleim_title:any,gleim_description:any,gleim_image:any}

function ContentPartner({gleim_title,gleim_description,gleim_image}: Props) {
    function createMarkup() {
        return {__html: gleim_description }
      }

    return (
        <>
            <section className="type-members-sect py-3 section-content section-bt"  >
                <div className="container customwid ">
                    
                    <div className="row pt-3">
                        <div className="col-md-6">
                            <div className=" section-heading-lg mb-4">
                                <h4>{gleim_title}</h4>
                            </div>

                            <div className="should_attend-Ul">
                            <div dangerouslySetInnerHTML={createMarkup()}></div>
                               
                            </div>


                        </div>

                        <div className="col-md-6 col-12">
                            <figure><img src={gleim_image} className="w-100"/> </figure>
                        </div>
                    </div>
               </div>
            </section>
        </>
    )
}

export default ContentPartner
