import { Link } from "react-router-dom"

const Banner = () => {
    const image = window.location.origin + "/images/testimonials-banner.jpg"
    return (
        <section className="inner-banner-top " style={{ backgroundImage: ` url(${image})` }}>
            <div className="container customwid h-100">
                <div className="row h-100">
                    <div className="col-xl-12 breadcrumbmain">
                        <div className="gt-breadcrumb mt-3">
                            <div aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                    <li className="breadcrumb-item active text-white" aria-current="page">Testimonials</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div className="main-content">
                        <h1 className="banner__title ">Testimonials </h1>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Banner