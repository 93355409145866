import React from "react";
import TestimonialItem from "../../items/TestimonialItem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";

type AppProps = {
  testimonialData: any[];
};

const Testimonials = ({ testimonialData }: AppProps) => {
  return (
    <>
      <section
        id="testimonials"
        className="enroll-feat-section section-spacing-tb50 section-content bg-light-purple-1 section-bt"
      >
        <div className="container customwid mb-4">
          <div className="text-center section-heading-lg mt-4">
            <h4 className="mb-1">Students Testimonials</h4>
            <div className="row">
              {/* <p className="fs18 col-md-8 mx-auto">
                Lorem ipsum dolor sit amet consectetur. Integer praesent tellus pellentesque semper viverra. Ut sapien dictum gravida suspendisse condimentum eu cursus tempor.
              </p> */}
            </div>
          </div>

          <div className="row">
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                575: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
            >
              {testimonialData &&
                testimonialData.map((item, index) => (
                  <SwiperSlide key={index}>
                    <TestimonialItem
                      key={index}
                      comments={item.description}
                      user={item.title}
                      location={item.company}
                      image={item.featured_image}
                      styleType={2}
                      company={item.company}
                      designation={item.designation}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
