import { useSelector } from "react-redux";
import PageDetailLoader from "../Shared/page";

const Policy = ({ cookieData }: any) => {
  const loading = useSelector(
    (state: any) => state.pageData.cookiePageData.loading
  );

  return (
    <main className="content-wrapper bg-light">
      {loading && (
        <div className="container">
          {" "}
          <PageDetailLoader />
        </div>
      )}
      {!loading && (
        <div
          className="container"
          dangerouslySetInnerHTML={{
            __html: cookieData?.description,
          }}
        >
          {/* <p className='lead'>
            Grant Thornton International Ltd (GTIL) wants to protect the privacy
            of visitors to our website. Please read the following policy; it will
            help you to understand how we use your personal data. We may change
            our privacy policy at any time without giving you notice, so please
            check it each time you visit our website.{' '}
          </p>
          <h5>What personal data do we collect?</h5>
          <p>
            When you visit our website, make an enquiry, order publications or
            request more information, you may be asked to provide some personal
            data such as your name, address, telephone number and e-mail address.
            In the event you decide to provide personal data, this policy will
            apply. We do not log your Internet Protocol (IP) address.
          </p>
          <h5>What is a cookies?</h5>
          <p>
            A cookie is a small piece of data or message that is sent from an
            organisation's web server to your web browser and is then stored on
            your hard drive. Cookies can't read data off your hard drive or cookie
            files created by other sites, and do not damage your system.
          </p>
          <p>
            However, you can reset your browser so as to refuse any cookie or to
            alert you to when a cookie is being sent. Web browsers allow you to
            control cookies stored on your hard drive through the web browser
            settings. To find out more about cookies, including what cookies have
            been set and how to manage and delete them, visit{' '}
            <a href='#!' target='_blank'>
              http://www.allaboutcookies.org.
            </a>
          </p>
          <p>
            We only use cookies to monitor the performance of our website and to
            improve user experience.
          </p>
          <p>
            If you choose not to accept our cookies, some of the features of our
            site may not work as well as we intend.
          </p>
          <h5> How do we use your personal data?</h5>
          <p>
            We may also use your personal data to carry out research about our
            visitors' demographics, interests and behaviour. We do this to better
            understand our visitors. This research is compiled and analysed on an
            aggregated and anonymous basis.
          </p>
          <p>
            When you give us personal data, those data may be sent electronically
            to servers anywhere in the world and may be used, stored and processed
            anywhere in the world.
          </p>
          <p>
            Whenever and wherever we collect, process or use personal data, we
            take steps to ensure that it is treated securely and in accordance
            with our privacy policy.
          </p>
          <h5>To whom might we disclose your personal data?</h5>
          <p>
            We may pass your personal data to anyone who needs the data in order
            to fulfil your request for our services, or process any payment. Some
            of these may be located outside the European Economic Area.
          </p>
          <p>
            We may pass your personal data to Grant Thornton member firms or to
            our data processors.
          </p>
          <p>
            Except as set out above, we will not disclose your personal
            information unless we are obliged to do so or allowed to do so, by
            law, or where we need to do so in order to run our business (for
            instance where we outsource services or other people process data for
            us).
          </p>
          <h5>Direct Marketing</h5>
          <p>
            You may at any time request us to stop using your personal data for
            direct marketing purposes. If you wish to do this, please contact us.
          </p>
          <h5>Links</h5>
          <p>
            Our website contains links to Grant Thornton member and correspondent
            firm websites, but this privacy policy applies only to personal data
            collected via websites operated by GTIL which include{' '}
            <a href='www.gti.org' target='_blank'>
              www.gti.org
            </a>{' '}
            ,{' '}
            <a href='www.internationalbusinessreport.com' target='_blank'>
              www.internationalbusinessreport.com
            </a>{' '}
            and{' '}
            <a href='www.globaldynamismindex.com' target='_blank'>
              www.globaldynamismindex.com{' '}
            </a>{' '}
            and to how GTIL processes personal data. It does not apply to specific
            member or correspondent firms practising under the Grant Thornton
            name. We are not responsible for the privacy practices of these or
            other sites. We encourage our visitors to be aware when they leave our
            website, and to read the privacy policy of other sites that collect or
            use personal data.
          </p>
          <h5>Security</h5>
          <p>
            Unfortunately, no data transmission over the Internet or any other
            network can be guaranteed as 100% secure, but we take appropriate
            steps to try to protect the security of your personal data.
          </p>
          <h5>Contacts</h5>
          <p>
            This is the website of GTIL, a non-practicing, non-trading
            international umbrella organisation that does not deliver services.
            GTIL and its member firms are not a worldwide partnership. GTIL and
            each member firm of GTIL is a separate legal entity.
          </p>
          <p>
            If you would like access to or a copy of the personal information we
            hold about you, to request a correction, or have any questions about
            how we may use it or to make a complaint, please contact the Data
            Protection Officer at the address shown below, or, email{' '}
            <a href='mailto:gti.legal@gti.gt.com'>gti.legal@gti.gt.com</a>.
          </p>
          <p>
            Complaints will be dealt with by the Data Protection Officer, and will
            be responded to within 30 days at the latest.
          </p>
          <p>
            If you are not satisfied with the way your complaint was handled, you
            may be able to refer your complaint to your local data protection
            regulator. For example, in the United Kingdom this is the Information
            Commissioner’s Office.
          </p>
          <p>
            <strong>Registered office address:</strong> Milton Gate, 60 Chiswell
            Street, London, EC1Y 4AG
          </p>
          <h5>Inaccuracies and Corrections</h5>
          <p>
            We would like to keep your personal data accurate and up to date. If
            you become aware of any errors or inaccuracies please let us know by
            contacting us at our registered office.
          </p> */}
        </div>
      )}
    </main>
  );
};

export default Policy;
