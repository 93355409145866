import { Link } from 'react-router-dom';

const Banner = ({ refundData }: any) => {

  return (
    <section
      className='inner-banner-top '
      style={{ backgroundImage: `url(${refundData?.featured_image || ''})` }}
    >
      <div className='container h-100'>
        <div className='row h-100'>
          <div className='col-xl-12 breadcrumbmain'>
            <div className='gt-breadcrumb mt-3'>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <Link to='/'>Home</Link>
                  </li>
                  <li
                    className='breadcrumb-item active text-white'
                    aria-current='page'
                  >
                    { refundData?.title || '' }
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className='main-content'>
            <h1 className='banner__title '>{ refundData?.title || '' }</h1>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
