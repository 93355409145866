import React from "react";

const Accordion = ({ course_structure = [] }: any) => {
  return (
    <div className="accordion accordion-content" id="accordionExample">
      {course_structure?.map((item: any, index: any) => (
        <div className="accordion-item accordion-item-list" key={index}>
          <h2
            className="accordion-header accordion-main-header"
            id={`heading${index}`}
          >
            <button
              className="accordion-button collapsed accordion-btn"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded="true"
              aria-controls={`collapse${index}`}
            >
              {item.title}
            </button>
          </h2>
          <div
            id={`collapse${index}`}
            className="accordion-collapse collapse"
            aria-labelledby={`heading${index}`}
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body accordion-body-list">
              <ul>
                {Array.isArray(item.courses) &&
                  item.courses.map((itm: any) => {
                    return <li>{itm.course}</li>;
                  })}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
