import React, { useEffect, useState } from 'react'

interface Props {
  programData: any;
}

function LicensedCPA({ programData }: Props) {
  const [selected, setSelected] = useState(0);

  return (
    <>
      <section className="exam-information-section py-3 section-content section-bt">
        <div className="container customwid">
          <div className="text-center section-heading-lg mb-4">
            <h4>{programData.requirements_title}</h4>
          </div>
          <div className="row pt-2">
            <div className="col-12">
              <div className="Pathway-tab">
                <ul className="nav nav-tabs border-0" id="trains-list" role="tablist">
                  {Array.isArray(programData.requirements) && programData.requirements.map((item: any, i: any) => {
                    return (<>
                      <li className="nav-item" role="presentation">
                        <button
                          className={selected === i ? "nav-link active":"nav-link"}
                          id={"offer" + (i + 1)}
                          data-bs-toggle="tab"
                          data-bs-target={"#offer" + (i + 1) + "-pane"}
                          type="button"
                          role="tab"
                          aria-controls={"#offer" + (i + 1) + "-pane"}
                          aria-selected="true"
                          onClick={() => setSelected(i)}
                        >
                          {item.tab_name}
                        </button>
                      </li>
                    </>);
                  })}
                </ul>
                <div className="tab-content p-3 shadow-sm" id="offers-list">
                  {Array.isArray(programData.requirements) && programData.requirements.map((item: any, inx: any) => {
                    return (<><div
                      className={selected === inx ? "tab-pane fade active show":"tab-pane fade"}
                      id={"#offer" + (inx + 1) + "-pane"}
                      role="tabpanel"
                      aria-labelledby={"offer" + (inx + 1)}
                      tabIndex={0}
                    >
                      <div className="Pathway-wrapper">
                        <div className="row gx-3">
                          <div className="should_attend-Ul" dangerouslySetInnerHTML={{__html: item.tab_content}}></div>
                        </div>
                      </div>
                    </div></>)
                  })}
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>



    </>
  )
}

export default LicensedCPA
