import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: any = {
  formOpen:false,
  enqId:null
}

export const enquerySlice = createSlice({
  name: 'formOpen',
  initialState,
  reducers: {
  
    enqueryFormOpen: (state, action: PayloadAction<any>) => {
       state.formOpen = action?.payload
    },
   
    enqueryId: (state, action: PayloadAction<any>) => {
      state.enqId = action?.payload?.enqId
   },
  },
})

export const { enqueryFormOpen,enqueryId } = enquerySlice.actions
