import React from 'react'
import PersonInformationItem from '../../items/PersonInformationItem'


const PersonInformation = ({ personInfo }: any) => {
    
    return (
        <>
            <div className="row">
                {personInfo?.directors?.map((person:any) => (
                    <div  key={person._id} className="col-xl-4 col-md-6">
                        <PersonInformationItem name={person.name} image={person.profile_pic} position={person.designation} email={person.email_id} phone={person.mobile_number} />
                    </div>
                ))}
            </div>
        </>
    )
}

export default PersonInformation