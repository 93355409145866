import React from "react";
import Image from "../elements/Image";

type AppProps = {
  user: string;
  company?: string;
  designation?: string;
  title?: string;
  comments: string;
  location?: string;
  image?: string;
  styleType: number;
};

const TestimonialItem = ({
  user,
  company,
  designation,
  title,
  comments,
  location,
  image,
  styleType,
}: AppProps) => {
  return (
    <>
      {styleType === 1 && (
        <div className="item w-100">
          <div className="alumnus_block">
            <div className="d-flex ">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-4">
                  <div className="top-txt red-light rounded-circle">
                    <img
                      // src={"images/quote-icon.png"}
                      src={image || "images/no_user.png"}
                      alt={""}
                      width={"70"}
                      height={"70"}
                      style={{ borderRadius: "100%" }}
                      // effect={"blur"}
                      // classes={"contain"}
                    />
                  </div>
                </div>
                <div className="  flex-grow-1 d-flex align-items-center">
                  {/* <span className="ps-2 fw-semibold">
                  <div className="footer-txt">
                    <div className="mb-0 name">{user}</div>
                    <div className="deg mb-1">{company}</div>
                    <div className="deg comp purple-color fw-semibold">
                      {designation}
                    </div>
                  </div>
                </span> */}
                  <span className="fw-semibold">
                    <div className="mb-0 name">{user}</div>
                    <div className="deg mb-1">{company}</div>
                    <div className="deg comp purple-color fw-semibold">
                      {designation}
                    </div>
                  </span>
                </div>
              </div>
              {/* <div className="top-txt red-light rounded-circle ms-auto testimonial-quote-img">
                <Image
                  src={"images/quote-icon.png"}
                  // src={"images/no_user.png"}
                  alt={""}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"contain"}
                />
              </div> */}
            </div>
            <div className=""></div>
            {comments.length > 300 && (
              <div className="desc">{comments.substring(0, 300)}...</div>
            )}
            {comments.length < 300 && <div className="desc">{comments}</div>}
          </div>
        </div>
      )}
      {styleType === 2 && (
        <div className="card-testimonial card card-style p-4 shadow-sm position-relative">
          {/* <div className="position-absolute end-0 top-0 pt-4 pe-4">
            <img
              src={"/images/dq.png"}
              alt={""}
              width={"32"}
              height={"27"}
              style={{ borderRadius: "100%" }}
              // effect={"blur"}
              // classes={""}
            />
          </div> */}
          <div className="testimonial-header d-flex align-items-center">
            <div className="testimonial-image flex-shrink-0 me-4">
              <img
                // src={image ? image : "images/no_user.png"}
                src={image || window.location.origin + "/images/no_user.png"}
                alt={user}
                width={"70"}
                height={"70"}
                style={{ borderRadius: "100%" }}
                // effect={"blur"}
                // classes={"img-fluid rounded-circle"}
              />
            </div>

            <div className="testimonial-detail flex-grow-1">
              <h6 className="mb-1">{user}</h6>
              <p className="small mb-0 lh-1 fs12 text-dark-purple">
                {location}
              </p>
            </div>
          </div>
          <h6 className="fw600 mb-2 mt-3" style={{ lineHeight: "1.4" }}>
            {title}
          </h6>
          <p style={{ lineHeight: "1.6" }}>{comments}</p>
        </div>
      )}
    </>
  );
};

export default TestimonialItem;
