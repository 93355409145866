import React, { ReactNode } from "react";

type AppProps = {
  data: {
    our_corporate_content: string | TrustedHTML;
    our_corporate_title: ReactNode;
    title: string;
    text: string;
  };
};

const TraningSolution = ({ data }: AppProps) => {
  return (
    <>
      <section className="corporate-training-section sec-spacing border-bottom">
        <div className="container customwid py-3">
          {data?.our_corporate_title && (
            <h2 className="fs32 fw700 text-center mb-3">
              {data?.our_corporate_title}
            </h2>
          )}
          {data?.our_corporate_content && (
            <div
              dangerouslySetInnerHTML={{ __html: data?.our_corporate_content }}
            ></div>
          )}
        </div>
      </section>
    </>
  );
};

export default TraningSolution;
