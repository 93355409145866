import LeadershipNewsItem from "../../items/LeadershipNewsItem";
import Pagination from "../../elements/Pagination";

const LeadershipNews = ({ leadershipNewsData }: any) => {
  return (
    <>
      <section className="Thought_Leadership sec-spacing light-bg">
        <div className="container">
          <div className="row">
            {Array.isArray(leadershipNewsData.leadership_listing) &&
              leadershipNewsData.leadership_listing.map(
                (news: any, index: any) => (
                  <LeadershipNewsItem
                    key={index}
                    title={news.title}
                    image={news.image}
                    description={news.content}
                    pdf={news?.pdf_url}
                  />
                )
              )}
          </div>
          <Pagination />
        </div>
      </section>
    </>
  );
};

export default LeadershipNews;
