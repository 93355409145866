import React from "react";
import Image from "../../elements/Image";

type AppProps = {
  data: {
    our_value_image: any;
    our_value_title: any;
    image: string;
    title: string;
  };
};

const OurPropositionValue = ({ data }: AppProps) => {
  return (
    <>
      <section className="corporate-training-section sec-spacing light-bg border-bottom">
        <div className="container customwid py-3">
          {data?.our_value_title && <h2 className="fs32 fw700 text-center mb-3">{data?.our_value_title}</h2>}
          {data?.our_value_image && (
            <figure className="mb-0 d-block">
              <Image src={data?.our_value_image} alt={data?.title} width={"100%"} height={"100%"} effect={"blur"} classes={"img-fluid d-block mx-auto rounded-3 shadow-sm"} />
            </figure>
          )}
        </div>
      </section>
    </>
  );
};

export default OurPropositionValue;
