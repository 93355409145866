import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper";
import BannerSlide from "../../items/BannerSlide";
import { useNavigate } from "react-router-dom";

type AppProps = {
  data: any[];
};

const Banner = ({ data }: AppProps) => {
  const navigator = useNavigate();
  const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

  return (
    <>
      <section className="hero-main">
        <Swiper
          pagination={{
            clickable: true,
          }}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          speed={1200}
          modules={[Pagination, Autoplay]}
        >
          {data.length > 0 &&
            data.map((item, index) => (
              <SwiperSlide key={index}>
                <BannerSlide
                  imageDesktop={item.banner_image}
                  imageMobile={item.mobile_banner}
                  title={item.banner_heading}
                  text={item.banner_text}
                  link={item.banner_link}
                  linkText={"View Programme Details"}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      </section>
    </>
  );
};

export default Banner;
