import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import DefaultLayout from "./layouts/DefaultLayout";
import Home from "./pages/Home";
import Listing from "./pages/Listing";
import ProgramDetails from "./pages/ProgramDetails";
import Register from "./pages/Register";
import Policy from "./pages/Policy";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Clients from "./pages/Clients";
import LeaderShipOne from "./pages/LeaderShipOne";
import CorporateTraining from "./pages/CorporateTraining";
import Testimonials from "./pages/Testimonials";
import Gallery from "./pages/Gallery";
import ShareFeedback from "./pages/ShareFeedback";
import Terms from "./pages/Terms";
import Emailer from "./pages/Emailer";
import Cookies from "./pages/Cookies";
import ThankYou from "./pages/ThankYou";
import Payment from "./pages/Payment";
import Faq from "./pages/Faq";
import CancellationAndRefund from "./pages/CancellationAndRefund";
import CorporateTrainingNew from "./pages/CorporateTrainingNew";
import Blogs from "./pages/Blogs";
import BlogDetails from "./pages/BlogDetails";
import Enquire from "./pages/Enquire";
import EnquireSlug from "./pages/EnquireSlug";
import NotFound from "./pages/NotFound";
import FeedbackForm from "./pages/FeedbackForm";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import NewSignUp from "./pages/NewSignUp";

const CreateRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route path="/" element={<Home />} />
          </Route>
          <Route path="/listing" element={<DefaultLayout />}>
            <Route path="/listing" element={<Listing />} />
          </Route>
          <Route path="/program-detail/:id" element={<DefaultLayout />}>
            <Route path="/program-detail/:id" element={<ProgramDetails />} />
          </Route>
          <Route path="/:id" element={<DefaultLayout />}>
            <Route path="/:id" element={<ProgramDetails />} />
          </Route>
          <Route path="/register/:id" element={<DefaultLayout />}>
            <Route path="/register/:id" element={<Register />} />
          </Route>
          <Route path="/checkout/" element={<DefaultLayout />}>
            <Route path="/checkout/" element={<Register />} />
          </Route>
          <Route path="/payment/" element={<DefaultLayout />}>
            <Route path="/payment/" element={<Payment />} />
          </Route>

          <Route path="/privacy-policy" element={<DefaultLayout />}>
            <Route path="/privacy-policy" element={<Policy />} />
          </Route>
          <Route path="/contact-us" element={<DefaultLayout />}>
            <Route path="/contact-us" element={<Contact />} />
          </Route>
          <Route path="/about-us" element={<DefaultLayout />}>
            <Route path="/about-us" element={<About />} />
          </Route>
          <Route path="/our-clients" element={<DefaultLayout />}>
            <Route path="/our-clients" element={<Clients />} />
          </Route>
          <Route path="/thought-leadership/" element={<DefaultLayout />}>
            <Route path="/thought-leadership/" element={<LeaderShipOne />} />
          </Route>
          <Route path="/corporate-training" element={<DefaultLayout />}>
            <Route
              path="/corporate-training"
              element={<CorporateTrainingNew />}
            />
          </Route>
          <Route path="/testimonials" element={<DefaultLayout />}>
            <Route path="/testimonials" element={<Testimonials />} />
          </Route>
          <Route path="/gallery" element={<DefaultLayout />}>
            <Route path="/gallery" element={<Gallery />} />
          </Route>
          <Route path="/share-feedback" element={<DefaultLayout />}>
            <Route path="/share-feedback" element={<ShareFeedback />} />
          </Route>
          <Route path="/terms-of-use" element={<DefaultLayout />}>
            <Route path="/terms-of-use/" element={<Terms />} />
          </Route>

          <Route path="*" element={<Navigate replace to="/" />}></Route>

          <Route path="/emailer" element={<DefaultLayout />}>
            <Route path="/emailer" element={<Emailer />} />
          </Route>
          <Route path="/cookies-policy" element={<DefaultLayout />}>
            <Route path="/cookies-policy" element={<Cookies />} />
          </Route>
          <Route path="/refund_returns/" element={<DefaultLayout />}>
            <Route
              path="/refund_returns/"
              element={<CancellationAndRefund />}
            />
          </Route>
          <Route path="/thankyou" element={<DefaultLayout />}>
            <Route path="/thankyou" element={<ThankYou />} />
          </Route>
          <Route path="/faqs/" element={<DefaultLayout />}>
            <Route path="/faqs/" element={<Faq />} />
          </Route>
          <Route path="/blogs" element={<DefaultLayout />}>
            <Route path="/blogs" element={<Blogs />} />
          </Route>
          <Route path="/blog/:id" element={<DefaultLayout />}>
            <Route path="/blog/:id" element={<BlogDetails />} />
          </Route>
          <Route path="/lp/:id" element={<DefaultLayout />}>
            <Route path="/lp/:id" element={<EnquireSlug />} />
          </Route>
          <Route path="/fb/:id" element={<DefaultLayout />}>
            <Route path="/fb/:id" element={<FeedbackForm />} />
          </Route>
          <Route path="/enquire" element={<DefaultLayout />}>
            <Route path="/enquire" element={<Enquire />} />
          </Route>
          <Route path="/register" element={<DefaultLayout />}>
            <Route path="/register" element={<SignUp />} />
          </Route>
          <Route path="/login" element={<DefaultLayout />}>
            <Route path="/login" element={<Login />} />
          </Route>
          <Route path="/signup" element={<DefaultLayout />}>
            <Route path="/signup" element={<NewSignUp />} />
          </Route>

          <Route path="/not_found" element={<DefaultLayout />}>
            <Route path="/not_found" element={<NotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default CreateRoutes;
