// src/components/NotFound.js

import React from "react";
import { Link } from "react-router-dom";
// import "./NotFound.css"
const NotFound = () => {
  return (
    <div className="section">
      <div className="errorBox-wrap">
        <h1 className="error">404</h1>
        <div className="page">
          Ooops!!! The page you are looking for is not found
        </div>
        <Link to="/" className="back-home btn btn btn-dark mt-4">
          Back to home
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
