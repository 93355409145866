import Image from "../elements/Image";
import { Link, useNavigate } from "react-router-dom";
import { addtocart } from "../../store/cart";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { numberWithCommas, onlyUnique } from "../../helpers";
import { enqueryFormOpen, enqueryId } from "../../store/enqueryFormOpen";

type AppProps = {
  program_id: number;
  sku: string;
  category: string;
  title: string;
  text: string;
  link: string;
  image: string;
  duration: string;
  isCertificate: boolean;
  discountPercentage: number;
  price: number;
  enrollLink: string;
  isLive: boolean;
  viewType: string;
  setFormOpen: any;
  salePrice: any;
};

const ProgramSlide = ({
  program_id,
  sku,
  category,
  title,
  text,
  link,
  image,
  duration,
  isCertificate,
  discountPercentage,
  salePrice,
  price,
  enrollLink,
  isLive,
  viewType,
  setFormOpen,
}: AppProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const FromRToggleData = useSelector(
    (state: any) => state.enqueryToggle.formOpen
  );
  const cartData = useSelector((state: any) => state.counter.cartData);
  const addTocart = (
    program_id: any,
    sku: any,
    title: any,
    category: any,
    price: any,
    image: any,
    salePrice: any
  ) => {
    if (program_id) {
      const items = {
        program_id: program_id,
        sku: sku,
        title: title,
        category: category,
        program_fees: price,
        featured_image: image,
        sale_price: salePrice,
      };
      dispatch(addtocart(items));
    }
    //navigate("/checkout");
    // }
  };

  useEffect(() => {
    if (cartData.length > 0) {
      let unique = onlyUnique(cartData);
      localStorage.setItem("cartData", JSON.stringify(unique));
    }
  }, [cartData]);

  const openEnqueryForm = () => {
    dispatch(enqueryFormOpen(!FromRToggleData));
    dispatch(enqueryId({ enqId: link }));
  };

  return (
    <>
      <div className="col-sm-6 col-md-12 program-card">
        {viewType === "grid" && (
          <div className="item">
            <div className="card service-card">
              {discountPercentage > 0 && (
                <span className="offer-tag">
                  {discountPercentage + "%"} OFF
                </span>
              )}
              <Link to={"/program-detail/" + link}>
                <Image
                  src={image}
                  alt={title}
                  width={"100%"}
                  height={"100%"}
                  effect={"blur"}
                  classes={"card-img-top"}
                />
              </Link>
              <div className="card-body">
                <span className="tag">{category}</span>
                <h4 className="card-title">
                  <Link to={"/program-detail/" + link}>{title}</Link>
                </h4>
                <div className="duration-block">
                  <div className="row">
                    <div className="col-7 col-lg-7 pe-0">
                      {duration && (
                        <div className="duration">
                          <span className="rounded bg-red-paste">
                            <i className="fa-regular fa-clock"></i>
                          </span>
                          <span className="duration-txt flex">
                            <div className="lavel"> Duration </div>
                            <div className="data"> {duration}</div>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-5 col-lg-5 pe-0">
                      <div className="duration Certificate">
                        <span className="rounded bg-red-paste">
                          <i className="fa-solid fa-file-lines"></i>
                        </span>
                        <span className="duration-txt">
                          Certificate of Completion
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {price > 0 && (
                <div className="card-body program-price pt-0 pb-3 fs-6 fw600 d-flex align-items-center">
                  {salePrice ? (
                    <>
                      <span className="d-inline-block ">
                        INR {numberWithCommas(salePrice)}
                        <del
                          style={{
                            color: "gray",
                            textDecoration: "line-through",
                            marginLeft: "5px",
                          }}
                        >
                          INR {numberWithCommas(price)}
                        </del>
                        {discountPercentage > 0 && (
                          <del className="small fw400 text-muted ">
                            {numberWithCommas(price)}
                          </del>
                        )}
                      </span>
                      <span className="duration-txt">
                        (Exclusive of 18% GST)
                      </span>
                    </>
                  ) : (
                    <>
                      {" "}
                      <span className="d-inline-block ">
                        INR {numberWithCommas(price)}
                        {discountPercentage > 0 && (
                          <del className="small fw400 text-muted ">
                            {numberWithCommas(price)}
                          </del>
                        )}
                      </span>
                      <span className="duration-txt">
                        (Exclusive of 18% GST)
                      </span>
                    </>
                  )}
                  {/* <span className="d-inline-block ">
                    INR {numberWithCommas(price)}
                    {discountPercentage > 0 && (
                      <del className="small fw400 text-muted ">
                        {numberWithCommas(price)}
                      </del>
                    )}
                  </span>
                  <span className="duration-txt">(Exclusive of 18% GST)</span> */}
                </div>
              )}

              <div className="card-footer border-0 bg-transparent">
                <div className="row gx-0">
                  <div className="col">
                    <button
                      className="btn btn-dark Enroll"
                      onClick={() => {
                        addTocart(
                          program_id,
                          sku,
                          title,
                          category,
                          price,
                          image,
                          salePrice
                        );
                        navigate("/checkout");
                      }}
                      // data-bs-toggle="modal"
                      // data-bs-target="#staticBackdrop"
                    >
                      Enroll
                    </button>
                  </div>
                  <div className="col">
                    {link && (
                      <button
                        className="btn Program-Det"
                        onClick={openEnqueryForm}
                      >
                        Enquire
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {viewType === "list" && (
          <div className="listing-block p-2 rounded shadow-sm mb-3">
            <div className="row ">
              <Link
                to={`/program-detail/${link}`}
                className="col-xl-10 col-md-9"
              >
                <div className="row align-items-start">
                  <div className="col-md-4">
                    <figure className="mb-0">
                      <Image
                        src={image}
                        alt={title}
                        width={"100%"}
                        height={"100%"}
                        effect={"blur"}
                        classes={"card-img-top rounded"}
                      />
                    </figure>
                  </div>
                  <div className="col-md-7">
                    <div className="card-body">
                      <h4 className="card-title">{title}</h4>
                      <div
                        className="card-text"
                        dangerouslySetInnerHTML={{
                          __html: text || "",
                        }}
                      ></div>
                      <div className="duration-block">
                        <div className="row">
                          <div className="col-6 col-lg-6">
                            {duration && (
                              <div className="duration">
                                <span className="rounded bg-red-paste">
                                  <i className="fa-regular fa-clock"></i>
                                </span>
                                <span className="duration-txt">
                                  Duration : {duration}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-6 col-lg-6">
                            {isCertificate && (
                              <div className="duration Certificate">
                                <span className="rounded bg-red-paste">
                                  <i className="fa-solid fa-file-lines"></i>
                                </span>
                                <span className="duration-txt">
                                  Certificate of Completion
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {isLive && <div className="tag mt-3">Live ProGram</div>}
                    </div>
                  </div>
                </div>
              </Link>
              <div className="col-xl-2 col-md-3 button-sec mt-2">
                {salePrice ? (
                  <div className="program-fees">
                    <del
                      style={{ color: "gray", textDecoration: "line-through" }}
                    >
                      INR {""}
                      {numberWithCommas(price)}
                    </del>
                    <h5>
                      {" "}
                      INR {""}
                      {numberWithCommas(salePrice)}
                    </h5>

                    <small>(Exclusive of 18% GST)</small>
                  </div>
                ) : (
                  price && (
                    <div className="program-fees">
                      <h5>
                        INR {""}
                        {numberWithCommas(price)}
                      </h5>
                      <small>(Exclusive of 18% GST)</small>
                    </div>
                  )
                )}
                {/* {price && (
                  <div className="program-fees">
                    <h5 className="text-decoration-line-through">
                      INR {""}
                      {numberWithCommas(price)}
                    </h5>
                    <small>(Exclusive of 18% GST)</small>
                  </div>
                )} */}
                <div className="card-buttons">
                  <button
                    className="btn btn-dark Enroll w-100 mb-2"
                    onClick={() => {
                      addTocart(
                        program_id,
                        sku,
                        title,
                        category,
                        price,
                        image,
                        salePrice
                      );
                      navigate("/checkout");
                    }}
                    // data-bs-toggle="modal"
                    // data-bs-target="#staticBackdrop"
                  >
                    Enroll
                  </button>
                  <Link
                    className="btn Program-Det w-100"
                    to={`/program-detail/${link}`}
                  >
                    Programme Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProgramSlide;
