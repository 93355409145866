import React from "react";
import VideoItem from "../../items/VideoItem";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
type AppProps = {
  data: any[];
};

const Videos = ({ data }: AppProps) => {
  return (
    <>
      <section className="videos-section sec-spacing bg-light">
        <div className="container customwid">
          <div className="section-heading mb-3">
            <h3 className="text-center all_heading">LearninGT Video Gallery</h3>
          </div>

          <div className="row pb-4">
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              loop={true}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                575: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Pagination, Navigation]}
            >
              {data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <SwiperSlide key={index}>
                    <VideoItem
                      title={item.video_title}
                      text={item.video_description}
                      videoLink={item.video_url}
                      styleType={2}
                      videoThumb={item.video_thumbnail}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </section>
    </>
  );
};

export default Videos;
