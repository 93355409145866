import CreateRoutes from './routes';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  // window.addEventListener('beforeunload', function (e) {
  //   e.preventDefault();
  //   e.returnValue = '';
  //   localStorage.setItem("cartData", JSON.stringify([]));
  //   });

  return (
    <CreateRoutes />
  );


}

export default App;