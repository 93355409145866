import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export interface CounterState {
  value: number
  incrementAmount: number,
  cartData: any[],
}

const initialState: CounterState = {
  value: localStorage.getItem('countData')?Number(localStorage.getItem('countData')):0,
  incrementAmount : 1,
  cartData : localStorage.getItem('cartData')?(JSON.parse(localStorage.getItem('cartData') || '{}')):[],
}

export const counterSlice = createSlice({
  name: 'cartData',
  initialState,
  reducers: {
    increment: (state) => {
      state.value += state.incrementAmount
    },
    decrement: (state) => {
      state.value -= state.incrementAmount
    },
    changeIncrementAmount: (state, action: PayloadAction<number>) => {
      state.incrementAmount = action.payload
    },

    addtocart: (state, action: PayloadAction<any>) => {
       state.cartData.push(action.payload)
    },
    removetocart: (state, action: PayloadAction<any>) => {
      state.cartData = state.cartData.filter((item)=>{ return (item.program_id !== action.payload)});
   },
   removeallData: (state, action: PayloadAction<any>) => {
    state.cartData = [];
 }
  },
})

export const { increment, decrement, changeIncrementAmount,addtocart,removetocart,removeallData } = counterSlice.actions
