import React, { useState } from "react";

type AppProps = {
    title: string;
    content: string;
  };

const CurriculamItem = ({ title, content }:AppProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={"faq-block rounded " + (isOpen ? "isOpen" : "")}>
      <div className="faq-header" onClick={() => setIsOpen(!isOpen)}>
        {title}
        {/* <span className="topics fs11 text-muted">
                          <i className="fa fa-align-left me-2"></i>4 Topics
                        </span> */}
      </div>
      <div className="faq-content">
       
        <ul>
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          ></div>
        </ul>
      </div>
    </div>
  );
};

export default CurriculamItem;
