const Condition = ({ termData }: any) => {
  return (
    <main className='content-wrapper bg-light'>
      <div className='container' dangerouslySetInnerHTML={{
				__html: termData?.description || ''
			}}>
        {/* <p className='lead'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam sed
          dolor vel cum id elit duis. Euismod sed sit nunc donec feugiat nunc.
          Nisi ullamcorper quis adipiscing in cras proin venenatis nec. Vel at
          arcu eu tortor. Quis diam odio odio interdum. Aliquam potenti volutpat
          ac ultrices. Vel a imperdiet et tempus pulvinar aliquet semper
          eleifend morbi. Dictumst aliquam viverra morbi amet aliquet nibh ut
          maecenas viverra. In ut fermentum arcu, cursus nibh porta dignissim
          fermentum commodo.
        </p>
        <p className='lead'>
          Egestas sapien, et, maecenas eget in tempus, urna, quam sed. Ac
          tincidunt sit et mus duis at nulla fermentum. Et pellentesque et ac
          sed felis sit porttitor tortor. Sed nulla ullamcorper egestas ut. Ut
          penatibus magna dictum egestas lectus dui malesuada mi fermentum.
          Fringilla pulvinar tristique erat in enim lorem.
        </p>

        <h5>Introduction</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Arcu quisque
          mauris orci ultricies sit sit sed blandit dui. Facilisi sed morbi
          platea nullam tellus ut lectus. Velit venenatis aliquet tellus
          scelerisque in adipiscing elit. Facilisi elit vestibulum nec turpis
          dui faucibus at euismod sed. Etiam tincidunt ultricies augue lacinia
          nisl, phasellus ut. In vitae varius augue imperdiet. Amet, ultrices
          scelerisque cras auctor porta semper sit donec. Ut tempor velit magna
          sed in nisl imperdiet. Egestas tristique vel dis enim semper quis elit
          aliquet. Porttitor nibh lorem semper id.
        </p>

        <p>
          Mattis leo, orci auctor lectus orci quis consequat volutpat cursus.
          Euismod laoreet ac nisi amet ut pellentesque. Commodo nulla vulputate
          augue volutpat congue arcu eget. Faucibus et cras cras imperdiet
          risus, est. Eu risus nulla in porttitor.
        </p>

        <p>
          Odio consequat viverra elit laoreet diam condimentum ultrices. Mattis
          et congue augue maecenas vitae. Est, cursus arcu nunc sollicitudin
          platea mi. Netus ultrices condimentum sed cursus hendrerit velit,
          sodales. Tincidunt semper consectetur ultrices aliquam malesuada sed
          pellentesque. Etiam augue neque eget congue id porta. Amet amet
          vestibulum ipsum praesent eu sit facilisis. Feugiat vitae quis blandit
          pellentesque tristique. Arcu, pharetra urna consequat eleifend in
          consequat felis tellus augue. Platea viverra justo, dui venenatis sit
          euismod. Facilisis in ac phasellus bibendum luctus mi.
        </p>

        <p>
          Vitae gravida eu lectus eget ac laoreet posuere. Vel felis scelerisque
          eu id viverra feugiat rhoncus, commodo, id. Facilisis amet, non nunc
          malesuada fermentum morbi quis est. Nunc purus felis lacus,
          pellentesque vitae. Eu sit mattis odio lobortis ut arcu netus vitae
          sed. Euismod donec nulla dictum urna nam lobortis tristique. Donec
          montes, massa in id vel nunc, arcu. Sem ultrices elementum faucibus
          ullamcorper ac sem. Enim nec risus ac odio aliquam lectus id dolor. Et
          lacus, leo, massa iaculis tortor amet magna. Sit pellentesque
          vestibulum interdum viverra curabitur nisl pellentesque. Fames magna
          iaculis adipiscing nulla tempus, vulputate.
        </p>

        <h5>User Accounts, User Registration and Delivery of Services</h5>
        <p>
          Pharetra aliquam leo blandit neque nisl leo aliquam in. Egestas nisl,
          dolor lobortis ac aliquam facilisi. Amet proin duis nisi, est ac
          tincidunt. Sed nulla elementum, ullamcorper pellentesque odio donec.
          Amet amet, sed consectetur nisi lobortis odio porttitor ultrices. Sit
          et, nunc at orci. Eleifend blandit donec sed in. A tempus nisl arcu,
          mattis semper vulputate etiam congue. Urna quis phasellus gravida a
          convallis nisl pellentesque sed dictumst. Quisque adipiscing justo,
          enim, libero consectetur sit. In proin massa vulputate risus
          scelerisque dolor. Dignissim auctor phasellus at euismod ipsum turpis
          eget.
        </p>

        <p>
          Faucibus ultrices praesent sit lorem tristique. Risus elementum sed
          morbi volutpat. Ullamcorper vitae lectus tellus, faucibus aenean
          ullamcorper diam commodo. In sit nunc in tellus sit. Pharetra, dolor
          vestibulum nisl etiam diam iaculis non. Tempor, mauris et urna,
          pretium sapien metus. Platea tristique sed imperdiet urna, lectus
          ipsum. Eros neque donec enim elit nisi nisl orci, in massa. Diam ante
          lacus, et nisi curabitur vestibulum. Elit magna phasellus duis
          scelerisque porttitor orci, elementum. Tincidunt enim odio sed amet
          molestie ornare duis amet vestibulum. Rhoncus elementum mauris diam
          tellus pellentesque urna, faucibus dictumst.
        </p>

        <p>
          Ullamcorper ultrices orci dignissim aliquet. Sit elit ultrices neque
          est sed cursus senectus faucibus nunc. At nam eget aliquet proin
          mattis nam. Orci eget amet fringilla metus, dignissim. Pharetra eget
          aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Payment Terms and Refund Policy</h5>
        <p>
          Pharetra aliquam leo blandit neque nisl leo aliquam in. Egestas nisl,
          dolor lobortis ac aliquam facilisi. Amet proin duis nisi, est ac
          tincidunt. Sed nulla elementum, ullamcorper pellentesque odio donec.
          Amet amet, sed consectetur nisi lobortis odio porttitor ultrices. Sit
          et, nunc at orci. Eleifend blandit donec sed in. A tempus nisl arcu,
          mattis semper vulputate etiam congue. Urna quis phasellus gravida a
          convallis nisl pellentesque sed dictumst. Quisque adipiscing justo,
          enim, libero consectetur sit. In proin massa vulputate risus
          scelerisque dolor. Dignissim auctor phasellus at euismod ipsum turpis
          eget. Faucibus ultrices praesent sit lorem tristique. Risus elementum
          sed morbi volutpat. Ullamcorper vitae lectus tellus, faucibus aenean
          ullamcorper diam commodo. In sit nunc in tellus sit. Pharetra, dolor
          vestibulum nisl etiam diam iaculis non. Tempor, mauris et urna,
          pretium sapien metus. Platea tristique sed imperdiet urna, lectus
          ipsum. Eros neque donec enim elit nisi nisl orci, in massa. Diam ante
          lacus, et nisi curabitur vestibulum. Elit magna phasellus duis
          scelerisque porttitor orci, elementum. Tincidunt enim odio sed amet
          molestie ornare duis amet vestibulum. Rhoncus elementum mauris diam
          tellus pellentesque urna, faucibus dictumst. Ullamcorper ultrices orci
          dignissim aliquet. Sit elit ultrices neque est sed cursus senectus
          faucibus nunc. At nam eget aliquet proin mattis nam. Orci eget amet
          fringilla metus, dignissim. Pharetra eget aliquet sit vulputate sit
          volutpat sit vitae.
        </p>

        <h5>Use of Programs</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Educational Partners and Accreditation</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Grant Thornton’s Intellectual Property Rights</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Third-Party Intellectual Property</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Limited License</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>User Conduct Policy</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>User Content</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Platform Security</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Disclaimer</h5>

        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Program Related Disclaimers</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Limitation of Liability</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Site Linking</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Indemnity</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Termination of Rights</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h5>Miscellaneous Provisions</h5>
        <p>
          Elit magna phasellus duis scelerisque porttitor orci, elementum.
          Tincidunt enim odio sed amet molestie ornare duis amet vestibulum.
          Rhoncus elementum mauris diam tellus pellentesque urna, faucibus
          dictumst. Ullamcorper ultrices orci dignissim aliquet. Sit elit
          ultrices neque est sed cursus senectus faucibus nunc. At nam eget
          aliquet proin mattis nam. Orci eget amet fringilla metus, dignissim.
          Pharetra eget aliquet sit vulputate sit volutpat sit vitae.
        </p>

        <h6>Grievance Officer : Mr. John Doe</h6>
        <p>
          To raise any grievance with us, please click on the following link :{' '}
          <strong>Grievance Redressal Form</strong>
          <br />
          <strong>OR</strong>
          <br />
          Write to{' '}
          <strong>
            <a href='mailto:grievance.redressal@gtacademy.in'>
              grievance.redressal@gtacademy.in
            </a>
          </strong>
        </p>

        <p>
          Any notice or communication that may be required to be given to Grant
          Thornton under these Terms or any Program-specific terms shared with
          you may be sent by writing or emailing to the following addresses:
        </p>

        <p>
          National Office,
          <br /> Outer Circle, L 41,
          <br /> Connaught Circus,
          <br /> New Delhi - 110001
        </p>

        <p>
          Phone: +91 11 4278 7070
          <br />
          CIN - U74110DL2001PTC111284
        </p> */}
      </div>
    </main>
  );
};

export default Condition;
