import { combineReducers } from "redux";
import { counterSlice } from "./cart";
import { homeSlice } from "./home";
import { programSlice } from "./programe";
import { pageSlice } from "./page";
import { layoutSlice } from "./layout";
import { authSlice } from "./auth";
import { enquerySlice } from "./enqueryFormOpen";
export default combineReducers({
     counter: counterSlice.reducer,
     homeData: homeSlice.reducer,
     programData: programSlice.reducer,
     pageData: pageSlice.reducer,
     layoutData: layoutSlice.reducer,
     authData: authSlice.reducer,
     enqueryToggle:enquerySlice.reducer,
})