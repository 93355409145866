import Image from "../../elements/Image";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper";
import TestimonialItem from "../../items/TestimonialItem";
import { useState } from "react";
type AppProps = {
  data: any;
};

const Testimonial = ({ data }: AppProps) => {
  const [emb, setEmbed] = useState("");
  const clickDemo = (url: any) => {
    const spliteUrl = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    const embed =
      "https://www.youtube.com/embed/" + spliteUrl[2] + "?autoplay=1";

    setEmbed(embed);
  };

  return (
    <>
      <section className="From_Alumnus position-relative sec-spacing">
        <div className="container">
          <div className="section-heading mb-5">
            <h3 className="text-center all_heading">What people are saying</h3>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="from-al-hight">
                {/* <div className=" position-absolute alumnus-img-bt">
                  <Image src={'images/grant-thornton-alumnus1.png'} alt={''} width={'64'} height={'64'} effect={'blur'} classes={''}/>
                </div> */}
                <Swiper
                  slidesPerView={1}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  // autoplay={{
                  //   delay: 2500,
                  //   disableOnInteraction: false,
                  //   pauseOnMouseEnter: true
                  // }}
                  navigation={true}
                  // modules={[Pagination, Navigation, Autoplay]}
                  modules={[Pagination, Navigation]}
                >
                  {data.testimonial?.length > 0 &&
                    data.testimonial?.map((item: any, index: any) => (
                      <SwiperSlide key={index}>
                        <TestimonialItem
                          user={item.title}
                          company={item.company}
                          designation={item.designation}
                          comments={item.description}
                          styleType={1}
                          image={item?.featured_image}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>

            <div className="col-lg-6 col-12">
              <div className="from-al-hight from-al-hight1">
                <div className="have_que">
                  {emb === "" && (
                    <figure className="mb-0 image-hover position-relative">
                      <div className="video-btn">
                        <div
                          className="popup-video"
                          style={{ cursor: "pointer" }}
                          onClick={() => clickDemo(data.video_link)}
                        >
                          <i className="fas fa-play"></i>
                          <span>Demo Video</span>
                        </div>
                      </div>
                      <Image
                        src={data.video_image}
                        alt={""}
                        width={"100%"}
                        height={"100%"}
                        effect={"blur"}
                        classes={"w-100"}
                      />
                    </figure>
                  )}
                  {emb && (
                    <iframe
                      id="videoUrl"
                      className="w-100"
                      width="560"
                      height="460"
                      src={emb}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="autoplay"
                      allowFullScreen
                    ></iframe>
                  )}
                  <div className="articles_caption">
                    <h4>{data.video_heading1}</h4>
                    <Link
                      className="btn-exp btn-lg btn mt-3"
                      to="/share-feedback"
                    >
                      Share your experience
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
