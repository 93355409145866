import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTermData } from "../store/page";
import NewsLetterCTA from "../components/pagesections/Shared/NewsLetterCTA";
import Banner from "../components/pagesections/Terms/Banner";
import Condition from "../components/pagesections/Terms/Condition";
import HomeLoader from "../shared/HomeLoader";

const Terms = () => {
  const dispatch = useDispatch();
  const termData = useSelector<any>(
    (state) => state.pageData.termPageData.data
  );

  const loading = useSelector(
    (state: any) => state.pageData.termPageData.loading
  );

  useEffect(() => {
    dispatch(fetchTermData());
  }, [dispatch]);

  return (
    <>
      {loading && (
        <section className="inner-banner-top">
          <HomeLoader />
        </section>
      )}
      {!loading && (
        <>
          <Banner termData={termData} />
          <Condition termData={termData} />
          {/* <NewsLetterCTA /> */}
        </>
      )}
    </>
  );
};

export default Terms;
