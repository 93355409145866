import React from 'react'
import NewsLetterCTA from '../components/pagesections/Shared/NewsLetterCTA'
import ThankYouBody from '../components/pagesections/Thankyou/ThankYouBody'

const ThankYou = () => {
    return (
        <>
            <ThankYouBody/>
            {/* <NewsLetterCTA/> */}
        </>
    )
}

export default ThankYou