import React from 'react'

interface Props {
    companies_title:any;
    companies:any[];
}

function HireCPA({companies_title,companies}: Props) {
    
    return (
        <>
        <section className="Companies__sec py-3 section-content section-bt ">
  <div className="container customwid ">
    <div className="text-center section-heading-lg mb-4">
      <h4 className="mb-1">{companies_title}</h4>
    </div>
    <div className="row pt-2">
   {
    Array.isArray(companies) && companies.map((item,index)=>{

        function createMarkup() {
            return {__html: item.description }
          }
    
       return (<>
        <div className="col-md-6">
        <div className="card card-style bg-light-purple-1 skill-block p-4 mb-4 shadow-sm bg-white">
          <div className="d-flex">
            <div className="flex-shrink-0 me-4"> <img src={item.icon} alt=""/> </div>
            <div className="flex-frow-1">
              <h5 className="fs17 fw600">{item.title}</h5>
              <div dangerouslySetInnerHTML={createMarkup()}></div>
             </div>
          </div>
        </div>
      </div>
       </>);
    })
   }
     
     
    </div>
  </div>
</section>
        </>
    )
}

export default HireCPA
