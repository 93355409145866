// import ContentLoader from 'react-content-loader'

// const ImageGrid = (props:any) => (
//   <ContentLoader
//     width={800}
//     height={575}
//     viewBox="0 0 800 575"
//     backgroundColor="#f3f3f3"
//     foregroundColor="#ecebeb"
//     {...props}
//   >

//     <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
//     <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
//     <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
   
 
//   </ContentLoader>
// )

// ImageGrid.metadata = {
//   name: 'Hassan Tijani.A',
//   github: 'surepeps',
//   description: 'Image Grid with Pagination',
//   filename: 'ImageGrid',
// }

// export default ImageGrid

import ContentLoader from "react-content-loader"

const ImageGrid = (props:any) => (
  <ContentLoader 
    speed={2}
    width={1000}
    height={706.667}
    viewBox="0 0 1000 706.667"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <path d="M 34.623 24.44 h 339.104 v 407.332 H 34.623 z M 396.13 24.44 h 357.434 v 409.369 H 396.13 z M 775.967 25.458 h 337.067 v 406.314 H 775.967 z" />
  </ContentLoader>
)

export default ImageGrid