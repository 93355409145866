export const handleScrollLoadMore = (
  e: any,
  hasMore: boolean,
  setOffset: any
) => {
  const scrollDiff = e.target.scrollHeight - e.target.scrollTop;

  const bottom =
    Math.round(scrollDiff) === e.target.clientHeight ||
    Math.floor(scrollDiff) === e.target.clientHeight ||
    scrollDiff - e.target.clientHeight === 1 ||
    scrollDiff - e.target.clientHeight === -1;

  if (bottom && hasMore) {
    setOffset((offset: number) => offset + 10);
  }
};

export const onlyUnique = (array = []) => {
  const newArray: any = [];

  array.forEach((arrayItem: any) => {
    if (
      !newArray.find(
        (newArrayItem: any) => newArrayItem.program_id === arrayItem.program_id
      )
    ) {
      newArray.push(arrayItem);
    }
  });

  return newArray;
};

export const numberWithCommas = (x: any) => {
  x = x && x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
  return x;
};

export const clickToScroll = (position: any) => {
  const element = document.getElementById(position);
  if (element) {
    // 👇 Will scroll smoothly to the top of the next section
    element.scrollIntoView({ behavior: "smooth" });
  }
};
