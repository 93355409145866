import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchRefundData } from "../store/page";
import HomeLoader from "../shared/HomeLoader";
import Banner from "../components/pagesections/CancellationAndRefund/Banner";
import Content from "../components/pagesections/CancellationAndRefund/Content";
import SeoComponent from "../components/elements/SeoComponent";

const CancellationAndRefund = () => {
  const dispatch = useDispatch();

  const refundData:any = useSelector<any>(
    (state) => state.pageData.refundPageData.data
  );

  const loading = useSelector(
    (state: any) => state.pageData.refundPageData.loading
  );

  useEffect(() => {
    dispatch(fetchRefundData());
  }, [dispatch]);

  return (
    <>
      {loading && <section className="inner-banner-top"><HomeLoader/></section>}

      {!loading && (
        <>
         {refundData && refundData?.seo_data && (
            <SeoComponent
              title={refundData?.seo_data?.meta_title}
              description={refundData?.seo_data?.meta_description}
              keyword={refundData?.seo_data?.meta_keyword}
            />
          )}
          <Banner refundData={refundData} />
          <Content refundData={refundData} />
        </>
      )}
    </>
  );
};

export default CancellationAndRefund;
