import AwardItem from "../../items/AwardItem";

const Award = ({ aboutData }: any) => {
  return (
    <>
      <section className="Awards-sec dark-bg sec-spacing">
        <div className="container">
          <h3 className="all_heading text-center"> Awards & Achievements</h3>
          <div className="awards-logos pt-3">
            <div className="row">
              {Array.isArray(aboutData) &&
                aboutData.map((item, index) => {
                  return (
                    <>
                      {
                        <AwardItem
                          key={index}
                          image={item.image}
                          details={item.awards_name}
                        />
                      }
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Award;
