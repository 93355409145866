import axios from "axios";
import LMS_URL from "../config/lms";
import BASE_URL from "../config/host";
export const loginuser = async (body: any) => {
  try {
    const details = await axios({
      method: "post",
      url: LMS_URL + "/webservice/rest/server.php",
      // url: BASE_URL + "/webservice/rest/server.php",
      // url: "https://rwtpl.com/demov/gt/index.php/wp-json/users/login",
      data: body,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return details.data;
  } catch (error: any) {
    return error?.response?.data;
  }
};
