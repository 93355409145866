import React from 'react'

const AboutFeedback = (data:any) => {
    return (
        <>
            <div className="feedback-right-side h-100 rounded border">
                 <div
              dangerouslySetInnerHTML={{__html: data?.data}}
                 />
            </div>
        </>
    )
}

export default AboutFeedback