import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";

export const programSlice = createSlice({
  name: "programData",
  initialState: {
    loading: false,
    programData: [],
    programDetails: {},
    total_records: 0,
    couponlist: [],
  },
  reducers: {
    Requested: (state, action) => {
      state.loading = true;
    },
    Failed: (state, action) => {
      state.loading = false;
    },
    Received: (state, action) => {
      state.programData = action.payload.data;
      state.total_records = action.payload.total_records;
      state.loading = false;
    },
    EnqueryLandingPageRequested: (state, action) => {
      state.loading = true;
    },
    EnqueryLandingPageFailed: (state, action) => {
      state.loading = false;
    },
    DetailsRequested: (state, action) => {
      state.loading = true;
    },
    DetailsFailed: (state, action) => {
      state.loading = false;
    },
    DetailsReceived: (state, action) => {
      state.loading = false;
      state.programDetails = action.payload.data;
    },
    CouponRequested: (state, action) => {
      state.loading = true;
      state.couponlist = [];
    },
    CouponFailed: (state, action) => {
      state.loading = false;
    },
    CouponReceived: (state, action) => {
      state.loading = false;
      state.couponlist = action.payload.data;
    },
  },
});

export const {
  Received,
  Requested,
  Failed,
  DetailsReceived,
  DetailsRequested,
  DetailsFailed,
  CouponReceived,
  CouponRequested,
  CouponFailed,
} = programSlice.actions;
//console.log(body);

export const fetchProgrameData = (limit = 10, page = 1, type = 0, cat_id = 0) =>
  apiCallBegan({
    url:
      type !== 0
        ? cat_id === 0
          ? `/index.php/wp-json/programs/list?per_page=${limit}&page=${page}&type=${type}`
          : `/index.php/wp-json/category/programs?per_page=${limit}&page=${page}&type=${type}&cat_id=${cat_id}`
        : cat_id === 0
        ? `/index.php/wp-json/programs/list?per_page=${limit}&page=${page}`
        : `/index.php/wp-json/category/programs?per_page=${limit}&page=${page}&cat_id=${cat_id}`,
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: Received,
    onStart: Requested,
    onError: Failed,
  });

export const fetchProgrameDetails = (body: any) =>
  apiCallBegan({
    url: "/index.php/wp-json/program/details_by_slug?slug=" + body.id,
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: DetailsReceived.type,
    onStart: DetailsRequested.type,
    onError: DetailsFailed.type,
  });


export const fetchCoupon = (body: any) =>
  apiCallBegan({
    url: "/index.php/wp-json/verify/coupon_code",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: CouponReceived.type,
    onStart: CouponRequested.type,
    onError: CouponFailed.type,
  });
