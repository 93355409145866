import React, { useEffect, useState } from "react";
import { clickToScroll } from "../helpers";

const ProgramMenuSec = () => {
  const [scroll, setScroll] = useState(false);
  const [activeNav, setActiveNav] = useState(undefined);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 500);
    });
  }, []);

  const menuList = [
    {
      _id: 0,
      name: "Overview",
      label: "overview",
    },
    {
      _id: 1,
      name: "Deliverables",
      label: "Deliverables",
    },
    {
      _id: 2,
      name: "Why do you need to Enroll",
      label: "Enrollnow",
    },
    {
      _id: 3,
      name: "Skills Covered",
      label: "skills",
    },
    {
      _id: 4,
      name: "Course Curriculum",
      label: "curriculum",
    },
    {
      _id: 5,
      name: "Students Testimonials",
      label: "testimonials",
    },
  ];

  const scrollHandler = (params: any) => {
    clickToScroll(params);
    setActiveNav(params);
  };

  return (
    <section
      className={
        "Program-menu-sec border-bottom bg-white" +
        (scroll ? " sticky-menu" : "")
      }
    >
      <div className="container customwid position-relative program-content-menu">
        <a className="logo-symbol" href="/">
          <img
            src={window.location.origin + "/images/icon.png"}
            alt="GTAcademy"
          />
        </a>
        <div
          id="Program-menu"
          className="owl-carousel owl-theme Program-menu owl-loaded owl-drag"
        >
          <div className="owl-stage-outer">
            <div
              className="owl-stage"
              style={{
                transform: "translate3d(0px, 0px, 0px)",
                transition: "all 0s ease 0s",
                width: "1034px",
              }}
            >
              {menuList.map((menu) => (
                <div
                  key={menu._id}
                  className={`owl-item ${
                    activeNav === menu?.label ? "active" : ""
                  }`}
                  style={{ width: " auto" }}
                  onClick={() => scrollHandler(menu?.label)}
                >
                  <div className={"item " + menu?.label}>
                    <div className="bar-nav">
                      {" "}
                      <a href={`#${menu?.label}`}>{menu?.name}</a>
                    </div>
                  </div>
                </div>
              ))}
              {/* <div className="owl-item active" style={{ width: " auto" }}>
                <div className="item">
                  <div className="bar-nav">
                    {" "}
                    <a href="#Deliverables">Deliverables</a>
                  </div>
                </div>
              </div>
              <div className="owl-item active" style={{ width: " auto" }}>
                <div className="item">
                  <div className="bar-nav">
                    {" "}
                    <a href="#Enrollnow"> Why you need to Enroll </a>
                  </div>
                </div>
              </div>
              <div className="owl-item active" style={{ width: " auto" }}>
                <div className="item">
                  <div className="bar-nav">
                    {" "}
                    <a href="#skills">Skills Covered </a>
                  </div>
                </div>
              </div>
              <div className="owl-item active" style={{ width: " auto" }}>
                <div className="item">
                  <div className="bar-nav">
                    {" "}
                    <a href="#curriculum">Course Curriculum</a>
                  </div>
                </div>
              </div>
              <div className="owl-item active" style={{ width: " auto" }}>
                <div className="item">
                  <div className="bar-nav">
                    {" "}
                    <a href="#testimonials">Students Testimonials</a>
                  </div>
                </div>
              </div>
              <div className="owl-item active" style={{ width: " auto" }}>
                <div className="item">
                  <div className="bar-nav">
                    {" "}
                    <a href="#videos"> GT Academy Videos </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="owl-nav disabled">
            <button type="button" role="presentation" className="owl-prev">
              <i className="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
            <button type="button" role="presentation" className="owl-next">
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </div>
          <div className="owl-dots disabled"></div>
        </div>
      </div>
    </section>
  );
};

export default ProgramMenuSec;
