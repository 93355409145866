import React, { useEffect, useState } from "react";
import { apicall } from "../api/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import HomeLoader from "../shared/HomeLoader";
import EnquireModal from "../components/pagesections/Shared/EnquireModal";
import SeoComponent from "../components/elements/SeoComponent";

const BlogDetails = () => {
  const navigate = useNavigate();
  const [blogData, setblogData] = useState({
    title: "",
    featured_image: "",
    date: "",
    description: "",
    seo_data: { meta_title: "", meta_description: "", meta_keyword: "" },
  });
  const [Loading, setLoading] = useState(false);
  const { id } = useParams();
  const [Blogs, setBlogs] = useState([]);
  const [formOpen, setFormOpen] = useState(false);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    setLoading(true);
    apicall("get", "/index.php/wp-json/blogs/list?per_page=100&page=1").then(
      (res) => {
        setBlogs(res?.data);
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    apicall("get", `/index.php/wp-json/blog/details?slug=${id}`).then((res) => {
      setblogData(res?.data);
      setLoading(false);
    });
  }, [id]);

  // useEffect(() => {
  //   setLoading(true);
  //   apicall("get", `/index.php/wp-json/blog/details?slug=${id}`).then((res) => {
  //     console.log(res,"res")
  //     if(res.code=== 404){
  //       navigate('/not_found')
  //     }else{
  //       setblogData(res?.data);
  //       setLoading(false);
  //     }

  //   });
  // }, [id]);
  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;

  return (
    <>
      {blogData && blogData?.seo_data && (
        <SeoComponent
          title={blogData?.seo_data?.meta_title}
          description={blogData?.seo_data?.meta_description}
          keyword={blogData?.seo_data?.meta_keyword}
        />
      )}

      {Loading ? (
        <section className="hero-main">
          <HomeLoader />
        </section>
      ) : (
        <div className="blog-area sec-spacing light-bg">
          <div className="container customwid">
            <div className="row">
              {}{" "}
              <div className="col-lg-8">
                <div className="article_main_caption">
                  <div className="card-item p-4 rounded">
                    {/* <!-- Title --> */}
                    <div className="article_mn_title">
                      <h1>{blogData?.title}</h1>
                      <div className="article_main_thumb">
                        {" "}
                        <img
                          src={blogData?.featured_image}
                          className="mt-2 img-fluid w-100"
                          alt=""
                        />{" "}
                      </div>
                    </div>
                    {/* <!-- Writer Info --> */}
                    <div className="article_writer_info align-items-center">
                      <div className="article_writer_info_first">
                        <ul>
                          <li>
                            <i className="fa fa-calendar "></i> {blogData?.date}
                          </li>
                        </ul>
                      </div>
                    </div>

                    {/* <!-- Description --> */}
                    <div className="article_secs">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: blogData?.description?.replace(/\n/g, "<br>"),
                        }}
                      />

                      <hr />
                      {/* <div className="article_secs_foot">
                    {" "}
                    <strong>You May Also Know </strong>
                    <ul className="articles_cats">
                      <li>
                        <a href="#">Financial Modelling and Valuation</a>
                      </li>
                      <li>
                        <a href="#">Finance for Non Finance Professionals </a>
                      </li>
                      <li>
                        <a href="#">Certificate – GST in India</a>
                      </li>
                      <li>
                        <a href="#">Certificate in Advance Excel </a>
                      </li>
                    </ul>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- end col-lg-8 --> */}
              <div className="col-lg-4">
                <div className="sidebar mb-0">
                  <div className="sidebar-widget widget-category">
                    {" "}
                    <Link to={`/enquire`} className="btn btn-dark w-100 mt-2">
                      Enquire Now
                    </Link>{" "}
                  </div>

                  {/* <!-- end sidebar-widget --> */}
                  <div className="sidebar-widget">
                    <h3 className="widget-title mb-3 fw600 text-prime">
                      Recent Post
                    </h3>

                    {Array.isArray(Blogs) &&
                      Blogs.length > 0 &&
                      Blogs.map((elm: any, index) => (
                        <div className="mini-list-card mt-3" key={index}>
                          <div className="mini-list-img">
                            {" "}
                            <Link to={`/blog/${elm?.slug}`} className="d-block">
                              {" "}
                              <img
                                src={elm?.featured_image}
                                alt="similar listing image"
                              />{" "}
                            </Link>{" "}
                          </div>
                          {/* <!-- end mini-list-img --> */}
                          <div className="mini-list-body">
                            <h4 className="mini-list-title">
                              <Link to={`/blog/${elm?.slug}`}>
                                {elm?.title}
                              </Link>
                            </h4>
                          </div>
                          {/* <!-- end mini-list-body -->  */}
                        </div>
                      ))}
                  </div>
                </div>
                {/* <!-- end sidebar -->  */}
              </div>
              {/* <!-- end col-lg-4 -->  */}
            </div>
          </div>
        </div>
      )}

      <EnquireModal
        setFormOpen={setFormOpen}
        formOpen={formOpen}
        title="yes"
        initial={flag}
      />
    </>
  );
};

export default BlogDetails;
