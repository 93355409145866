import React from 'react'

interface Props {
    roles_responsibilities_title:any,
    roles_responsibilities_content:any,
    left_image:any,
    all_roles:any
}

function RolesResponsibilities({roles_responsibilities_title,roles_responsibilities_content,left_image,all_roles}: Props) {


    return (
        <>
        <section className="roles-section py-3 section-content section-bt bg-light-purple-1" id="Deliverables">
  <div className="container customwid ">
    <div className="text-center section-heading-lg mb-4">
      <h4>{roles_responsibilities_title}</h4>
      <p className="fs18"> {roles_responsibilities_content} </p>
    </div>
    <div className="row pt-2 d-flex justify-content-between align-items-center">
      <div className="col-md-6 col-12">
        <figure>
            <img src={left_image} className="w-100"/> </figure>
      </div>
      <div className="col-md-5 col-12 should_attend-Ul Roles">
        <ul>
            {
                Array.isArray(all_roles) && all_roles.map((item:any)=>{
                    return (<>
                    <li>{item.role_name}</li>
                    </>);
                })
            }
         
          
        </ul>
      </div>
    </div>
  </div>
</section>
        </>
    )
}

export default RolesResponsibilities
