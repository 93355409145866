import React, { useEffect, useState } from "react";
import { apicall } from "../api/api";
import { Link, useNavigate } from "react-router-dom";
import HomeLoader from "../shared/HomeLoader";
import Pagination from "../components/elements/Pagination";
import SeoComponent from "../components/elements/SeoComponent";

const Blogs = () => {
  const [Loading, setLoading] = useState(false);
  const [Blogs, setBlogs] = useState({
    seo_data: { meta_title: "", meta_description: "", meta_keyword: "" },
  });
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    apicall(
      "get",
      `/index.php/wp-json/blogs/list?per_page=${limit}&page=${page}`
    ).then((res) => {
      if (res.code === "rest_no_route") {
        navigate("/not_found");
      } else {
        setBlogs(res?.data);
        setLoading(false);
        setCount(res?.total_records);
      }
    });
  }, [page]);

  return (
    <>
      {Loading && (
        <section className="hero-main">
          <HomeLoader />
        </section>
      )}

      <SeoComponent title="Blogs list" description="" keyword="" />

      <div className="blog-area sec-spacing light-bg">
        <div className="container customwid">
          <div className="row">
            {Array.isArray(Blogs) &&
              Blogs.length > 0 &&
              Blogs.map((elm: any, index) => (
                <Link
                  to={`/blog/${elm?.slug}`}
                  className="col-md-4 col-12 mb-3"
                  key={index}
                >
                  {" "}
                  {/* <div
                  className="col-md-4 col-12 mb-3"
                  key={index}
                > */}
                  <div className="blog-listing p-3 bg-white shadow-sm rounded">
                    <div className="card-image">
                      {" "}
                      <img
                        src={elm?.featured_image}
                        className="card__img w-100"
                        alt="blog image"
                      />
                    </div>
                    <div className="tag">
                      <a href="#">{elm?.author}</a>
                    </div>
                    <h2 className="card_title">
                      <a dangerouslySetInnerHTML={{ __html: elm?.title }} />
                      {/* <a href="#">{}</a> */}
                    </h2>
                    <div className="entry-meta fs13 fw600 mb-2 mt-2">
                      {" "}
                      <span>
                        <a href="#" className="me-2">
                          <i className="fa fa-calendar pe-1"></i> {elm?.date}{" "}
                        </a>
                      </span>{" "}
                    </div>
                    <div
                      className="entry-content multiline-ellipsis"
                      dangerouslySetInnerHTML={{
                        __html: elm?.short_description,
                      }}
                    />
                  </div>
                  {/* </div> */}
                </Link>
              ))}
          </div>
          <Pagination
            offset={page}
            limit={limit}
            count={count}
            onSubmit={(val: any) => setPage(val.selected + 1)}
          />
        </div>
      </div>
    </>
  );
};

export default Blogs;
