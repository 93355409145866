import React from 'react'

interface Props {
    exam_structure_title: any;
    exam_structure: any[]
}

function ExamStructure({ exam_structure_title, exam_structure }: Props) {


    return (
        <>
            <section className="Course_Structure-sec Exm-strou py-3 section-content section-bt  bg-light-purple-1">
                <div className="container customwid ">
                    <div className="text-center section-heading-lg mb-4">
                        <h4>{exam_structure_title}</h4>
                    </div>
                    <div className="row">
                        {
                            Array.isArray(exam_structure) && exam_structure.map((item, index) => {
                                function createMarkup() {
                                    return { __html: item.description }
                                }
                                return (<>
                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="Course__box">
                                            <h3 className="title purple-color">{item.title}</h3>
                                            <div className="should_attend-Ul mt-2 d-block">
                                                <div dangerouslySetInnerHTML={createMarkup()}></div>
                                            </div>
                                        </div>
                                    </div>
                                </>);
                            })
                        }


                    </div>
                </div>
            </section>
        </>
    )
}

export default ExamStructure
