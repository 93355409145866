import React from 'react'

interface Props {
    programData:any;
}

function CPAJourney({programData}: Props) {
    return (
        <>
        <section className="cpa-journey py-3 section-content section-bt bg-light-purple-1" id=" ">
  <div className="container customwid">
     <div className="text-center section-heading-lg mb-4">
    <h4>{programData?.cpa_journey_title} </h4>
  </div>
    <figure> <img src={programData?.cpa_journey_image} className="w-100"/></figure>
  </div>
  
  
</section>
        </>
    )
}

export default CPAJourney
