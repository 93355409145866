import React, { useEffect, useState } from "react";
import { submitContactUs, submitFeedback } from "../../../actions/feedback";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ContactForm = () => {
  const [loadData, setLoadData] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [course, setcourse] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [company, setcompany] = useState("");
  const [designation, setdesignation] = useState("");
  const [qualification, setqualification] = useState("");
  const [location, setlocation] = useState("");
  const [feedback, setfeedback] = useState("");

  const [firstnameErr, setFirstnameErMsg] = useState("");
  const [lastnameErr, setLastnameErMsg] = useState("");
  const [emailErr, setEmailErMsg] = useState("");
  const [phErr, setPhErr] = useState("");
  const [msgEr, setErMsg] = useState("");
  const [companyErr, setCompanyErr] = useState("");
  const [designationErr, setdesignationErr] = useState("");
  const [qualificationErr, setqualificationErr] = useState("");
  const [locationErr, setLocationErr] = useState("");
  const [feedbackErr, setFeedbackErr] = useState("");

  const [formData, setFormData] = useState({});
  const [formData2, setFormData2] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    setFormData2((pre) => ({
      "first-name": firstName,
      "last-name": lastName,
      "your-email": email,
      "your-mobile": phone,
      "your-message": feedback,
      "company-name": company,
      designation: designation,
      qualification: qualification,
      location: location,
    }));

    setFormData((pre) => ({
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      MobileNumber: phone,
      Feedback: feedback,
      Textb1: company,
      Textb2: designation,
      Textb3: qualification,
      Field5: location,
    }));
  }, [
    firstName,
    lastName,
    email,
    phone,
    feedback,
    company,
    designation,
    qualification,
    location,
  ]);

  const feedbackForm = async () => {
    // setIsSubmit(true);
    setLoadData(true);

    console.log(formData, "formData");
    console.log(formData2, "formData2");

    if (!firstName) {
      setFirstnameErMsg("First name is required!");
      // toast.error("First name is required!");
    } else {
      setFirstnameErMsg("");
    }

    if (!lastName) {
      setLastnameErMsg("Last name is required!");
      // toast.error("Last name is required!");
    } else {
      setLastnameErMsg("");
    }

    if (!email) {
      setEmailErMsg("Email is required!");
      // toast.error("Email is required!");
    } else {
      setEmailErMsg("");
    }

    if (!company) {
      setCompanyErr("Company name is required!");
      // toast.error("Company name is required!");
    } else {
      setCompanyErr("");
    }

    if (!phone) {
      setPhErr("Mobile number is required!");
      // toast.error("Mobile number is required!");
    } else {
      setPhErr("");
    }

    if (!feedback) {
      setErMsg("Enquiry message is required!");
      // toast.error("Enquery message is required!");
    } else {
      setErMsg("");
    }
    if (!designation) {
      setdesignationErr("Designation name is required!");
      // toast.error("Designation name is required!");
    } else {
      setdesignationErr("");
    }

    if (!qualification) {
      setqualificationErr("Your qualification is required!");
      // toast.error("Your qualification is required!");
    } else {
      setqualificationErr("");
    }
    if (!location) {
      setLocationErr("Your location is required!");
      // toast.error("Your location is required!");
    } else {
      setLocationErr("");
    }
    if (!feedback) {
      setFeedbackErr("Feedback is required!");
    } else {
      setfeedback("");
    }

    if (
      firstName &&
      lastName &&
      email &&
      feedback &&
      company &&
      designation &&
      qualification &&
      location &&
      phone
    ) {
      const submitfeedback = await submitContactUs(formData2).then((res) => {
        setLoadData(false);

        toast.success("Your enquiry is submitted successfully!");
        setfirstName("");
        setlastName("");
        setcompany("");
        setemail("");
        setphone("");
        setdesignation("");
        setqualification("");
        setlocation("");
        setfeedback("");

        setFirstnameErMsg("");
        setLastnameErMsg("");
        setPhErr("");
        setEmailErMsg("");
        setErMsg("");
        setdesignationErr("");
        setCompanyErr("");
        setLocationErr("");
        setFeedbackErr("");

        //   navigate("/thankyou");
        // initial ? navigate("/thankyou") : setFormOpen(false);
      });
    }

    setLoadData(false);
  };

  return (
    <>
      <div className="left-side-form bg-white h-100 border">
        <div className="contact-icon-prt">
          <span className="contact__icon">
            <i className="fa-solid fa-location-arrow"></i>
          </span>
          <span className="contact__icon-txt">
            <h4 className="mb-0">Write to us </h4>
            <p className="mb-0">We would love to hear from you</p>
          </span>
        </div>
        <form method="post" action="#" id="email-form" className="pt-3">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12 form-group">
              <label>First Name*</label>
              <input
                type="text"
                name="firstname"
                className="form-control"
                placeholder="Enter First Name"
                required
                onChange={(e) => setfirstName(e.target.value)}
              />
              {firstnameErr && <p style={{ color: "red" }}>{firstnameErr}</p>}
            </div>
            <div className="col-lg-6 col-md-12 col-12 form-group">
              <label>Last Name*</label>
              <input
                type="text"
                name="lastname"
                className="form-control"
                placeholder="Enter Last Name"
                required
                onChange={(e) => setlastName(e.target.value)}
              />
              {lastnameErr && <p style={{ color: "red" }}>{lastnameErr}</p>}
            </div>
            <div className="col-lg-6 col-md-12 col-12 form-group">
              <label>Email ID*</label>
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Enter your email"
                required
                onChange={(e) => setemail(e.target.value)}
              />
              {emailErr && <p style={{ color: "red" }}>{emailErr}</p>}
            </div>
            <div className="col-lg-6 col-md-12 col-12 form-group">
              <label>Phone No.*</label>
              <input
                type="number"
                className="form-control"
                id="mobile"
                placeholder="Enter your phone no."
                onChange={(e) => setphone(e.target.value)}
              />
              {phErr && <p style={{ color: "red" }}>{phErr}</p>}
            </div>
            <div className="col-lg-6 col-md-12 col-12 form-group">
              <label>Company</label>
              <input
                type="text"
                name="subject"
                className="form-control"
                placeholder="Enter your company"
                onChange={(e) => setcompany(e.target.value)}
              />
              {companyErr && <p style={{ color: "red" }}>{companyErr}</p>}
            </div>
            <div className="col-lg-6 col-md-12 col-12 form-group">
              <label>Designation</label>
              <input
                type="text"
                name="subject"
                className="form-control"
                placeholder="Enter your designation"
                onChange={(e) => setdesignation(e.target.value)}
              />
              {designationErr && (
                <p style={{ color: "red" }}>{designationErr}</p>
              )}
            </div>
            <div className="col-lg-6 col-md-12 col-12 form-group">
              <label>Qualification</label>
              <input
                type="text"
                name="subject"
                className="form-control"
                placeholder="Enter your qualification"
                onChange={(e) => setqualification(e.target.value)}
              />
              {qualificationErr && (
                <p style={{ color: "red" }}>{qualificationErr}</p>
              )}
            </div>
            <div className="col-lg-6 col-md-12 col-12 form-group">
              <label>Location</label>
              <input
                type="text"
                name="subject"
                className="form-control"
                placeholder="Enter your Location"
                onChange={(e) => setlocation(e.target.value)}
              />
              {locationErr && <p style={{ color: "red" }}>{locationErr}</p>}
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>Your Message</label>
              <textarea
                name="message"
                className="form-control"
                placeholder="Write your message..."
                onChange={(e) => setfeedback(e.target.value)}
              ></textarea>
              {feedbackErr && <p style={{ color: "red" }}>{feedbackErr}</p>}
            </div>
            <div className="col-lg-6 col-md-12 col-12 contact-info-footer align-items-center d-flex">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  {" "}
                  <i className="fa-solid fa-circle-info"></i>{" "}
                </div>
                <div className="flex-grow-1 ms-2">
                  <p>
                    Your details are safe with us, so feel free to contact us
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 text-center">
              <button
                onClick={() => feedbackForm()}
                className="btn btn-dark btn-lg py-3 px-5 w-100"
                type="button"
                id="submit"
                name="submit-form"
              >
                SUBMIT DETAILS
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
