import { useEffect } from "react";
import Banner from "../components/pagesections/LeaderShipOne/Banner";
import NewsLetterCTA from "../components/pagesections/Shared/NewsLetterCTA";
import LeadershipNews from "../components/pagesections/LeaderShipOne/LeadershipNews";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeaderData } from "../store/page";
import HomeLoader from "../shared/HomeLoader";
import SeoComponent from "../components/elements/SeoComponent";

const LeaderShipOne = () => {
  const dispatch = useDispatch();
  const leaderData: any = useSelector(
    (state: any) => state.pageData.leaderPageData.data
  );
  const loading = useSelector(
    (state: any) => state.pageData.leaderPageData.loading
  );

  useEffect(() => {
    dispatch(fetchLeaderData());
  }, [dispatch]);
  console.log(leaderData, "leaderData");
  return (
    <>
      {loading && (
        <section className="inner-banner-top">
          <HomeLoader />
        </section>
      )}
      {!loading && (
        <>
          {leaderData && leaderData?.seo_data && (
            <SeoComponent
              title={leaderData?.seo_data?.meta_title}
              description={leaderData?.seo_data?.meta_description}
              keyword={leaderData?.seo_data?.meta_keyword}
            />
          )}
          <Banner leadershipNewsData={leaderData} />

          <LeadershipNews leadershipNewsData={leaderData} />

          {/* <NewsLetterCTA /> */}
        </>
      )}
    </>
  );
};

export default LeaderShipOne;
