import React from "react";
import FaqItem from "../../items/FaqItem";

type AppProps = {
  faqData: any[];
  programData: any;
  segment: any;
};

const Overview = ({ faqData, programData, segment }: AppProps) => {
  // function createMarkup() {
  //   return {__html: programData?.overview }
  // }
  return (
    <>
      {
        <section
          className="overview-section section-spacing-tb50 section-content section-bt"
          id="overview"
        >
          <div className="container customwid ">
            <div className="text-center section-heading-lg mb-3 mt-4">
              <h4>{programData?.overview_title}</h4>
            </div>
            {/* <div dangerouslySetInnerHTML={createMarkup()}></div>  */}
            <div dangerouslySetInnerHTML={{ __html: programData?.overview }} />

            {segment !== "cma" &&
              Array.isArray(faqData) &&
              faqData.length > 0 && (
                <div className="faq-container mx-0 content-collapse">
                  {faqData &&
                    faqData.length > 0 &&
                    faqData.map((item, index) => (
                      <FaqItem
                        key={index}
                        title={item.heading}
                        text={item.content}
                      />
                    ))}
                </div>
              )}
          </div>
        </section>
      }
    </>
  );
};

export default Overview;
