import React from "react";
import { useSelector } from "react-redux";

const Deliverables = () => {
  const deliverablesData = useSelector(
    (state: any) => state?.programData?.programDetails?.deliverables
  );

  return (
    <>
      {deliverablesData &&
        deliverablesData.length > 0 &&
        deliverablesData[0].title && (
          <section
            className="enroll-feat-section py-3 section-content section-bt bg-light-purple-1"
            id="Deliverables"
          >
            <div className="container customwid ">
              <div className="text-center section-heading-lg mb-4">
                <h4>Deliverables</h4>
              </div>

              <div className="row pt-2">
                {deliverablesData.map((item: any) => (
                  <div className="col-md-6 col-sm-6 mb-4">
                    <div className="card card-style bg-white skill-block p-4 h-100 shadow-sm">
                      <div className="d-flex">
                        <div className="flex-shrink-0 me-4">
                          <img
                            src={
                              window.location.origin +
                              "/images/deliverables.png"
                            }
                            alt=""
                            style={{ width: "40px", height: "40px" }}
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h5>{item?.title}</h5>
                          <div
                            dangerouslySetInnerHTML={{__html: item?.content}}
                          />
                          {/* <p>{item?.content}</p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* <div className="col-md-6 col-sm-6 mb-4">
            <div className="card card-style bg-white skill-block p-4 h-100 shadow-sm">
              <div className="d-flex">
                <div className="flex-shrink-0 me-4">
                  <img
                    src="images/deliverables.png"
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
                <div className="flex-grow-1">
                  <h5>Pre recorded Lectures</h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 mb-4">
            <div className="card card-style bg-white skill-block p-4 h-100 shadow-sm">
              <div className="d-flex">
                <div className="flex-shrink-0 me-4">
                  <img
                    src="images/deliverables.png"
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
                <div className="flex-grow-1">
                  <h5>Other content and assessments</h5>
                  <p>
                    {" "}
                    Two-way communication and query resolution from an expert
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 mb-4">
            <div className="card card-style bg-white skill-block p-4 h-100 shadow-sm">
              <div className="d-flex">
                <div className="flex-shrink-0 me-4">
                  <img
                    src="images/deliverables.png"
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
                <div className="flex-grow-1">
                  <h5>Course material</h5>
                  <p className="mb-0"> BPP Books</p>
                  <p className="mb-0"> GT Course Handouts</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 mb-4">
            <div className="card card-style bg-white skill-block p-4 h-1menu?.label00 shadow-sm">
              <div className="d-flex">
                <div className="flex-shrink-0 me-4">
                  <img
                    src="images/deliverables.png"
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
                <div className="flex-grow-1">
                  <h5>
                    Quiz and final assessment of all accounting standards
                    covered
                  </h5>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 mb-4">
            <div className="card card-style bg-white skill-block p-4 h-100 shadow-sm">
              <div className="d-flex">
                <div className="flex-shrink-0 me-4">
                  <img
                    src="images/deliverables.png"
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                </div>
                <div className="flex-grow-1">
                  <h5>
                    LMS access of one year Certificate of completion passing the
                    final assessment
                  </h5>
                </div>
              </div>
            </div>
          </div> */}
              </div>
            </div>
          </section>
        )}
    </>
  );
};

export default Deliverables;
