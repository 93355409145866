import React from 'react'

interface Props {
    exam_information:any
}

function ExamInfo({exam_information}: Props) {
    function createMarkup() {
        return {__html: exam_information }
      }
   return (
        <>
        <section className="exam-information-section py-3 section-content section-bt">
  <div className="container customwid">
  <div dangerouslySetInnerHTML={createMarkup()}></div>
  </div>
</section>
        </>
    )
}

export default ExamInfo
