import React, { ReactNode } from "react";
import { Link } from "react-router-dom";

type AppProps = {
  data: {
    reach_out_listing: any;
    reach_out_title: ReactNode;
    reach_out_background_image: any;
    backgroundImage: string;
    title: string;
    contact: {
      text: string;
      link: string;
    };
    contactPerson: {
      name: string;
      designation: string;
      mobile: string;
      phone: string;
      email: string;
    };
  };
};

const ReachOut = ({ data }: AppProps) => {
  console.log(data?.reach_out_listing, "reach_out_listing");
  return (
    <>
      <section
        className="reachout-section sec-spacing"
        style={{ backgroundImage: `url(${data?.reach_out_background_image})` }}
      >
        <div className="container customwid">
          <div className="row justify-content-lg-start justify-content-center">
            {data?.reach_out_listing &&
              data?.reach_out_listing.map((elm: any, index: any) => (
                // console.log(elm?.content, "content")
                <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9">
                  <div className="reachout-card card card-body border-0 shadow-sm">
                    <div
                      key={index}
                      dangerouslySetInnerHTML={{ __html: elm?.content }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
      {/* <section
        className="reachout-section sec-spacing"
        style={{ backgroundImage: `url(${data?.reach_out_background_image})` }}
      >
        <div className="container customwid">
          <div className="row justify-content-lg-start justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9">
              <div className="reachout-card card card-body border-0 shadow-sm">
                <h3 className="mb-3 fw-bold">Reach out for more information</h3>
                <h6 className="text-dark mb-1 fw-bold">Pradeep Pandey</h6>
                <small className="purple-color fw-bold mb-2">DIRECTOR</small>
                <p className="mb-1">
                  <a className="text-dark-50" href="tel:+917838000185">
                    <span className="text-dark me-1 fw-bold">M:</span>+91 78380
                    00185
                  </a>
                </p>
                <p className="mb-1">
                  <a className="text-dark-50" href="+91124462 8000">
                    <span className="text-dark me-1 fw-bold">T:</span>+91 12
                    4462 8000
                  </a>
                </p>
                <p className="mb-0">
                  <a
                    className="text-dark-50"
                    href="mailto:Pradeep.Pandey@IN.GT.COM"
                  >
                    <span className="text-dark me-1 fw-bold">E:</span>
                    Pradeep.Pandey@IN.GT.COM
                  </a>
                </p>
                <span className="or">OR</span>
                <a className="btn btn-prime shadow-sm" href="#">
                  Contact Us
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9">
              <div className="reachout-card card card-body border-0 shadow-sm">
                <h3 className="mb-3 fw-bold">Reach out for more information</h3>
                <h6 className="text-dark mb-1 fw-bold">Kapil Arora</h6>
                <small className="purple-color fw-bold mb-2">DIRECTOR</small>
                <p className="mb-1">
                  <a className="text-dark-50" href="tel:+917838000185">
                    <span className="text-dark me-1 fw-bold">M:</span>+91 78380
                    00185
                  </a>
                </p>
                <p className="mb-1">
                  <a className="text-dark-50" href="+91124462 8000">
                    <span className="text-dark me-1 fw-bold">T:</span>+91 12
                    4462 8000
                  </a>
                </p>
                <p className="mb-0">
                  <a
                    className="text-dark-50"
                    href="mailto:Pradeep.Pandey@IN.GT.COM"
                  >
                    <span className="text-dark me-1 fw-bold">E:</span>
                    Pradeep.Pandey@IN.GT.COM
                  </a>
                </p>
                <span className="or">OR</span>
                <a className="btn btn-prime shadow-sm" href="#">
                  Contact Us
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9">
              <div className="reachout-card card card-body border-0 shadow-sm">
                <h3 className="mb-3 fw-bold">Reach out for more information</h3>
                <h6 className="text-dark mb-1 fw-bold">Rohit Gupta</h6>
                <small className="purple-color fw-bold mb-2">DIRECTOR</small>
                <p className="mb-1">
                  <a className="text-dark-50" href="tel:+917838000185">
                    <span className="text-dark me-1 fw-bold">M:</span>+91 78380
                    00185
                  </a>
                </p>
                <p className="mb-1">
                  <a className="text-dark-50" href="+91124462 8000">
                    <span className="text-dark me-1 fw-bold">T:</span>+91 12
                    4462 8000
                  </a>
                </p>
                <p className="mb-0">
                  <a
                    className="text-dark-50"
                    href="mailto:Pradeep.Pandey@IN.GT.COM"
                  >
                    <span className="text-dark me-1 fw-bold">E:</span>
                    Pradeep.Pandey@IN.GT.COM
                  </a>
                </p>
                <span className="or">OR</span>
                <a className="btn btn-prime shadow-sm" href="#">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default ReachOut;
