import React from 'react'

interface Props { 
    fees_title:any,
    fees_details:any
}

function FreeSchedule({fees_title,fees_details}: Props) {
    function createMarkup() {
        return {__html: fees_details }
      }
    return (
        <>
            <section className="fees-details-sec py-3 section-content section-bt  ">
                <div className="container customwid ">
                    <div className="text-center section-heading-lg mb-4">
                        <h4>{fees_title}</h4>
                    </div>
                    <div className="row pt-2">
                    <div dangerouslySetInnerHTML={createMarkup()}></div>
                        </div>
                </div>
            </section>
        </>
    )
}

export default FreeSchedule
