import EnrollItem from "../../items/EnrollItem";
import { useEffect, useState } from "react";
import EnquireModal from "../Shared/EnquireModal";
import { enroll } from "../../../actions/payment";
import { useDispatch, useSelector } from "react-redux";
import { enqueryFormOpen, enqueryId } from "../../../store/enqueryFormOpen";

type AppProps = {
  enrollData: any[];
  title: any;
};

const Enroll = ({ enrollData }: AppProps, { title }: AppProps) => {
  const dispatch = useDispatch();
  const [formOpen, setFormOpen] = useState(false);
  const FromRToggleData = useSelector(
    (state: any) => state.enqueryToggle.formOpen
  );
  const [flag, setFlag] = useState(false);

  const openEnqueryForm = () => {
    window.scrollTo(0, 500);
    dispatch(enqueryFormOpen(!FromRToggleData));
  };

  return (
    <>
      <section
        id={"Enrollnow"}
        className="enroll-feat-section section-spacing-tb50 section-content bg-light-purple-1 section-bt"
      >
        <div className="container customwid mb-4">
          <div className="text-center section-heading-lg mt-4">
            <h4>Why do you need to Enroll</h4>
          </div>
          <div className="row mb-4">
            {enrollData &&
              enrollData.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <EnrollItem
                    title={item.heading}
                    text={item.content}
                    image={item?.image?.sizes?.thumbnail}
                  />
                </div>
              ))}

            {/* <div className="col-md-4">
              <div className="card p-5 card-style shadow-sm text-center h-100">
                <img className="img-fluid mx-auto d-block mb-4" src="images/enroll-2.png" alt="" />
                <h5 className="mb-2">Some Heading Here</h5>
                <p>Lorem ipsum dolor sit amet consectetur. Augue id cursus sem lectus iaculis nunc. Scelerisque leo ut mattis pellentesque</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card p-5 card-style shadow-sm text-center h-100">
                <img className="img-fluid mx-auto d-block mb-4" src="images/enroll-3.png" alt="" />
                <h5 className="mb-2">Some Heading Here</h5>
                <p>Lorem ipsum dolor sit amet consectetur. Augue id cursus sem lectus iaculis nunc. Scelerisque leo ut mattis pellentesque</p>
              </div>
            </div> */}
          </div>
          <div className="text-center pt-3">
            <h5 className="fw600 mb-3">Want to know more about this course?</h5>
            <div className="d-flex justify-content-center gap-2">
              <button
                className="btn btn-dark btn-lg"
                onClick={() => {
                  setFormOpen(!formOpen);
                  setFlag(true);
                }}
              >
                ENQUIRE NOW
              </button>
              {/* <button className="btn btn-dark btn-lg">Cart</button> */}
            </div>
          </div>
        </div>
      </section>
      <EnquireModal
        setFormOpen={setFormOpen}
        formOpen={formOpen}
        title={title}
        initial={flag}
      />
    </>
  );
};

export default Enroll;
