import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Navigation, Autoplay } from "swiper";
//import SwiperCore, { Autoplay } from 'swiper'
import { Link } from "react-router-dom";
import ProgramSlide from "../../items/ProgramSlide";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ImageGrid from "../../../shared/ImageGrid";
import EnquireModal from "../Shared/EnquireModal";
import AddToCartModal from "../ProgramDetails/AddToCartModal";
import CartAdded from "../ProgramDetails/CartAdded";
import { fetchProgrameData } from "../../../store/programe";
import { apicall } from "../../../api/api";

type AppProps = {
  data: any[];
  programmeType: any[];
};

const Programes = ({ data, programmeType }: AppProps) => {
  const [loading, setLoading] = useState(false);

  const [formOpen, setFormOpen] = useState(false);
  const [programmes, setProgrames] = useState([]);
  const [type, setType] = useState(68);
  const [flag, setFlag] = useState(false);

  //SwiperCore.use([Autoplay])

  const selectType = (type: any) => {
    setType(type);
    setLoading(true);
    apicall(
      "get",
      "/index.php/wp-json/programs/list?per_page=100&page=1&type=" + type
    ).then((res) => {
      setProgrames(res?.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    setLoading(true);
    apicall(
      "get",
      "/index.php/wp-json/programs/list?per_page=100&page=1&type=68"
    ).then((res) => {
      setProgrames(res?.data);
      setLoading(false);
    });
  }, []);

  const sortProgrammeType = programmeType.sort(function (a, b) {
    return a["ID"] - b["ID"];
  });

  return (
    <>
      <section className="Programs_Offered light-bg pt-4 pb-5">
        <div className="container">
          <div className="section-heading d-none">
            <h3 className="text-center all_heading">Programmes Offered</h3>
          </div>
          <div className="services-tab mt-0">
            <div className="service-tab-links">
              <ul className="nav nav-tabs" id="service-tab" role="tablist">
                {Array.isArray(sortProgrammeType) &&
                  sortProgrammeType.map((item: any, index: any) => (
                    <li className="nav-item" role="presentation" key={index}>
                      <button
                        className={index === 0 ? "nav-link active" : "nav-link"}
                        id="online-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#online"
                        type="button"
                        role="tab"
                        aria-controls="healthcare-pro"
                        aria-selected="true"
                        onClick={() => {
                          selectType(item.ID);
                        }}
                      >
                        {item.title}
                      </button>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="tab-content" id="service-tab-content">
              <div
                className="tab-pane show active"
                id="online"
                role="tabpanel"
                aria-labelledby="online-tab"
              >
                <Swiper
                  slidesPerView={3}
                  spaceBetween={10}
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  speed={1200}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    575: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={false}
                  modules={[Pagination, Navigation, Autoplay]}
                >
                  {loading && <ImageGrid />}

                  {!loading &&
                    Array.isArray(programmes) &&
                    programmes.map((item: any, index) => (
                      <SwiperSlide key={item.program_id}>
                        <ProgramSlide
                          program_id={item?.program_id}
                          sku={item?.sku}
                          category={item?.category}
                          title={item?.title}
                          text={item?.text}
                          link={item?.slug}
                          image={item?.featured_image}
                          duration={item?.duration}
                          isCertificate={item?.certificate}
                          discountPercentage={0}
                          price={item?.program_fees}
                          salePrice={item?.sale_price}
                          enrollLink={item?.enrollLink}
                          isLive={item?.isLive}
                          viewType={"grid"}
                          setFormOpen={setFormOpen}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
              <div
                className="tab-pane"
                id="e-learning"
                role="tabpanel"
                aria-labelledby="e-learning-tab"
              >
                <Swiper
                  slidesPerView={3}
                  spaceBetween={10}
                  loop={true}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                >
                  {data.map((item) => (
                    <SwiperSlide key={item.program_id}>
                      <ProgramSlide
                        program_id={item?.program_id}
                        sku={item?.sku}
                        category={item?.category}
                        title={item.title}
                        text={item.text}
                        link={item.slug}
                        image={item.image}
                        duration={item.duration}
                        isCertificate={item.isCertificate}
                        discountPercentage={item.discountPercentage}
                        price={item.price}
                        salePrice={item?.sale_price}
                        enrollLink={item.enrollLink}
                        isLive={item.isLive}
                        viewType={item.viewType}
                        setFormOpen={setFormOpen}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="button-mdl text-center mt-4">
              <Link
                className="btn btn-outline-dark btn-lg"
                to={"/listing?type=" + type}
              >
                ALL PROGRAMMES
              </Link>
            </div>
          </div>
        </div>
        <EnquireModal
          setFormOpen={setFormOpen}
          formOpen={formOpen}
          title={"ff"}
          initial={flag}
        />
        {/* <AddToCartModal title={""} position={"centered"} size={"lg"}>
          <CartAdded />
        </AddToCartModal> */}
      </section>
    </>
  );
};

export default Programes;
