import { useSelector } from "react-redux";
import { onlyUnique } from "../../../helpers";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const CartAdded = () => {
  const cart = useSelector((state: any) => state.counter.cartData);
  const cartData = onlyUnique(cart);
  useEffect(() => {
    if (cartData.length > 0) {
      localStorage.setItem("cartData", JSON.stringify(cartData));
    } else {
      localStorage.setItem("cartData", JSON.stringify([]));
    }
  }, [cartData]);
  const navigator = useNavigate();

  return (
    <div className="Cart_added">
      {Array.isArray(cartData) &&
        cartData.map((item: any, index: any) => (
          <div className="d-flex border-bottom mb-3 pb-2" key={index}>
            <div className="flex-shrink-0">
              {/* <img
                  src={require("../../../assets/icon/green-check.png")}
                  alt=""
                  style={{ height: "25px", width: "25px" }}
                  className="me-2"
                /> */}
              <img src={item?.featured_image} alt="..." width="50" height="40" />
            </div>
            <div className="flex-grow-1 ms-2">
              <h5 className="mb-0 fs16 fw600">{item?.title}</h5>
              <p className="fs11 mb-1">{item?.category}</p>
            </div>
          </div>
        ))}
      <div className="row justify-content-start mt-3">
        <span style={{ width: "fit-content", paddingRight: "5px" }}>
          <Link to="/listing" className="btn btn-dark btn-sm fs11 p-2 " onClick={() => navigator("/listing")} data-bs-dismiss="modal" aria-label="Close">
            Add Another Course
          </Link>
        </span>
        <span className="p-0" style={{ width: "fit-content" }}>
          <Link to="/checkout" className="btn btn-success btn-sm fs11 p-2 " data-bs-dismiss="modal" onClick={() => navigator("/checkout")} aria-label="Close">
            Proceed to Payment
          </Link>
        </span>
      </div>
    </div>
  );
};

export default CartAdded;
