import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ThankYouBody = () => {
  const [count, setcount] = useState(5);

  const courseUrl = useSelector(
    (state: any) => state.pageData.landingPageData.courseUrl
  );

  useEffect(() => {
    setTimeout(() => {
      if (count === 0 && courseUrl !== null && courseUrl) {
        window.location.href = courseUrl;
      }
      if (count > 0) {
        setcount(count - 1);
      }
    }, 1000);
  }, [count]);

  return (
    <>
      <div className="thanks_page sec-spacing light-bg">
        <div className="container">
          <div className="thanks_block">
            <div className="row">
              <div className="col-md-6 col-12 mx-auto text-center">
                <figure className="thanks_img">
                  <img src="images/thanks-img.png" className="img-fluid" />
                </figure>
                <h1>Thank You!</h1>
                <p className="fs17">
                  Thanks for contacting us, our expert from{" "}
                  <strong>Grant Thornton</strong> India will contact to you soon
                  to resolve your query
                </p>
                {courseUrl ? (
                  <>Redirecting in {count} Secounds...</>
                ) : (
                  <Link
                    id=""
                    to="/"
                    className="btn btn-danger btn-hover ps-5 pe-5 p-3"
                    role=""
                  >
                    {" "}
                    GO TO HOMEPAGE
                  </Link>
                )}

                <div className="dividers d-none">
                  <div className="or">or </div>
                </div>
                <div className="thanks-social-block mt-4 d-none">
                  <h4 className="fs14">FOLLOW US ON</h4>
                  <ul>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa-brands fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa-brands fa-youtube"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="fa-brands fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="clearfix"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYouBody;
