import React from 'react'

interface Props {
    programData:any
    
}

function CareerCPA({programData}: Props) {
    function createMarkup1() {
        return {__html: programData?.career_path_left_section }
      }

      function createMarkup2() {
        return {__html: programData?.career_path_right_section }
      }

      function createMarkup3() {
        return {__html: programData?.career_path_middle_section }
      }

    return (
        <>
        <section className="Path-CMA-section py-3 section-content section-bt bg-light-purple-1 ">
  <div className="container customwid">
    <div className="text-center section-heading-lg mb-md-4 mb-5">
      <h4>{programData?.career_path_title} </h4>
      <p> {programData?.career_path_sub_title}</p>
    </div>
    <div className="row">
      <div className="col-md-4 col-12">
      <div dangerouslySetInnerHTML={createMarkup1()}></div> 
      </div>
      <div className="col-lg-4 col-md-12 col-sm-12 text-center">
      <div dangerouslySetInnerHTML={createMarkup3()}></div> 
      </div>
      <div className="col-md-4 col-12">
      <div dangerouslySetInnerHTML={createMarkup2()}></div> 
      </div>
    </div>
  </div>
</section>
        </>
    )
}

export default CareerCPA
