const Content = ({ privacyData }: any) => {
 
  return (
    <section className='content-wrapper bg-light'>
      <div className='container'
        dangerouslySetInnerHTML={{ __html: privacyData?.description }}
      />
    </section>
  );
};

export default Content;
