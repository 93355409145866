import LeaderShipItem from "../../items/LeaderShipItem";

const LeadershipTeam = ({ aboutData }: any) => {
  return (
    <>
      <section className="Leadership_Team sec-spacing dark-bg">
        <div className="container">
          <h4 className="all_heading text-center"> Our Team</h4>

          <div className="Leadership_Team_sec">
            <div className="row">
              <div className="col-12 mx-auto">
                <div className="row">
                  {Array.isArray(aboutData) &&
                    aboutData.map((item, index) => {
                      return <>{<LeaderShipItem key={index} image={item.image} name={item.name} position={item.position} icon={item.linkedin_profile} />}</>;
                    })}
                </div>
              </div>
            </div>
            {/* <div className="button-mdl text-center"> <a className="btn btn-outline-dark btn-lg mt-3" href="#"> VIEW ALL TEAM MEMBERS</a> </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default LeadershipTeam;
