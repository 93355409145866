import React from 'react'

interface Props {
    loadData:any
}

function PreLoad(loadData: Props) {
    return (
        <>
            <div className={`modal fade ${loadData ? "show d-block" : "d-none"}`} id="pre-loding" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="cart_popup" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
            <div className="modal-body">


            <div className="pre-loding d-flex align-items-center justify-content-center">
            <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>

            </div>  <span className="ps-3"><strong>Please wait.</strong></span></div>

            </div>

            </div>
            </div>
            </div>
      </>
    )
}

export default PreLoad
