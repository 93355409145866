import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const CeoDeskSec = ({ aboutData }: any) => {
  const image = window.location.origin+'/images/ceo-image.png';

  Fancybox.bind('[data-fancybox="ceo-video"]', {
    // Your custom options
  });

  return (
    <>
      <section className="ceo-sec light-bg">
        <div className="container customwid">
          <div className="row gx-0">
            <div className="col-lg-6">
              <div
                className="ceo-image-sec h-100 position-relative"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "top center",
                }}
              >
                <div className="video-btn">
                  <a className="popup-video" data-fancybox="ceo-video" href="https://www.youtube.com/watch?v=20Zg_6mFX8g">
                    <i className="fas fa-play"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="ceo-text-sec h-100 bg-white border border-start-0 position-relative">
                <figure>
                  <img src={window.location.origin + "/images/double-quotes-about.png"} alt="quotation" />
                </figure>
                <div className="ceo-desk">
                  <h2 className="fs15 fw600 text-uppercase">
                    {aboutData?.ceo_title}
                  </h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: aboutData?.ceo_content }}
                  ></div>
                </div>
                <div className="posi_block position-absolute rounded-3 text-center text-white">
                  <p className="fs15 fw500 mb-0">{aboutData?.ceo_name}</p>
                  <p className="fs13 mb-0 opacity-75">{aboutData?.ceo_place}</p>
                  <small className="fs11 opacity-75">
                    {aboutData?.ceo_designation}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CeoDeskSec;
