import { useEffect, useState } from "react";
import Overview from "../components/pagesections/ProgramDetails/Overview";
import Banner from "../components/pagesections/ProgramDetails/Banner";
import Batches from "../components/pagesections/ProgramDetails/Batches";
import Curriculum from "../components/pagesections/ProgramDetails/Curriculum";
import Enroll from "../components/pagesections/ProgramDetails/Enroll";
import Skills from "../components/pagesections/ProgramDetails/Skills";
import Testimonials from "../components/pagesections/ProgramDetails/Testimonials";
import { useLocation, useParams } from "react-router-dom";
// import { programDetails } from "../actions/program";
import { useDispatch, useSelector } from "react-redux";
import { fetchProgrameDetails } from "../store/programe";
import ProgrameDetailLoader from "../shared/ProgrameDetailLoader";
import ContentLoader from "react-content-loader";
import ProgramMenuSec from "../shared/ProgramMenuSec";
import Deliverables from "../components/pagesections/ProgramDetails/Deliverables";
import Coursestructure from "../components/pagesections/ProgramDetails/Coursestructure";
import Exemption from "../components/pagesections/ProgramDetails/Exemption";
import Scope from "../components/pagesections/ProgramDetails/Scope";
import AccaWithGTB from "../components/pagesections/ProgramDetails/AccaWithGTB";
// import StudentSupport from "../components/pagesections/ProgramDetails/StudentSupport";
import FreeSchedule from "../components/pagesections/ProgramDetails/FreeSchedule";
import PursueCma from "../components/pagesections/ProgramDetails/PursueCma";
import RolesResponsibilities from "../components/pagesections/ProgramDetails/RolesResponsibilities";
import ExamInfo from "../components/pagesections/ProgramDetails/ExamInfo";
import CertificationProcess from "../components/pagesections/ProgramDetails/CertificationProcess";
import ContentPartner from "../components/pagesections/ProgramDetails/ContentPartner";
import JobOpportunity from "../components/pagesections/ProgramDetails/JobOpportunity";
import CareerCPA from "../components/pagesections/ProgramDetails/CareerCPA";
import CPAJourney from "../components/pagesections/ProgramDetails/CPAJourney";
import LicensedCPA from "../components/pagesections/ProgramDetails/LicensedCPA";
import HireCPA from "../components/pagesections/ProgramDetails/HireCPA";
import ExamStructure from "../components/pagesections/ProgramDetails/ExamStructure";
import SeoComponent from "../components/elements/SeoComponent";
import { enqueryId } from "../store/enqueryFormOpen";

const ProgramDetails = () => {
  // const [productDetails,setProductDetails]=useState()
  const location = useLocation();
  const segment = location.pathname.split("/")[2];
  const { id } = useParams();
  const dispatch = useDispatch();

  const loading = useSelector((state: any) => state.programData.loading);
  useEffect(() => {
    dispatch(fetchProgrameDetails({ id: id }));
  }, [dispatch, id]);

  const image = window.location.origin + "/images/bg-program.jpg";
  useEffect(() => {
    dispatch(enqueryId({ enqId: id }));
  }, [id]);

  const programData = useSelector(
    (state: any) => state.programData.programDetails
  );
  // console.log(programData?.seo_data?.meta_title,'programData?.seo_data')
  return (
    <>
      {programData && programData?.seo_data && (
        <SeoComponent
          title={programData?.seo_data?.meta_title}
          description={programData?.seo_data?.meta_description}
          keyword={programData?.seo_data?.meta_keyword}
        />
      )}

      {loading && (
        <section
          className="Program-banner-top program-banner-new"
          style={{ backgroundImage: `url(${image})` }}
        >
          {/* SEO */}

          <div className="container customwid">
            <div className="row">
              <div className="col-md-8">
                <div className="course-detalis-topleft rounded">
                  <ProgrameDetailLoader />
                </div>
              </div>
              <div className="col-md-4">
                <div className="righr_video-pr rounded bg-white">
                  <ContentLoader
                    viewBox="0 0 500 280"
                    height={280}
                    width={"100%"}
                  >
                    <rect
                      x="3"
                      y="3"
                      rx="10"
                      ry="10"
                      width="100%"
                      height="100%"
                    />
                    <rect x="4" y="230" rx="0" ry="0" width="350" height="20" />
                  </ContentLoader>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {programData && !loading ? (
        <Banner segment={segment} programData={programData} />
      ) : (
        ""
      )}
      <ProgramMenuSec />
      {programData && !loading ? (
        <Batches
          batchData={programData?.flexible_batch}
          programData={programData}
        />
      ) : (
        ""
      )}

      {programData && programData?.overview && !loading ? (
        <Overview
          faqData={programData?.overview_faqs}
          programData={programData}
          segment={segment}
        />
      ) : (
        ""
      )}
      {programData?.child_programs?.length > 0 && (
        <>
          <Coursestructure
            course_structure={programData?.course_structure}
            course_structure_title={programData?.course_structure_title}
            course_structure_content={programData?.course_structure_content}
          />
          <Exemption
            check_your_exemption_title={programData?.check_your_exemption_title}
            check_your_exemption_description={
              programData?.check_your_exemption_description
            }
          />
          {Array.isArray(programData?.scope_of_acca) &&
            programData?.scope_of_acca.length > 0 && (
              <Scope
                scope_of_acca_image={programData?.scope_of_acca_image}
                scope_of_acca={programData?.scope_of_acca}
              />
            )}
          <AccaWithGTB
            acca_with_title={programData?.acca_with_title}
            acca_with_image={programData?.acca_with_image}
            acca_with_content={programData?.acca_with_content}
          />
          {/* <StudentSupport
            student_support_title={programData?.student_support_title}
            student_support={programData?.student_support}
          /> */}
          <FreeSchedule
            fees_title={programData?.fees_title}
            fees_details={programData?.fees_details}
          />
        </>
      )}

      {segment === "cma" && (
        <>
          <PursueCma
            who_can_pursue_title={programData?.who_can_pursue_title}
            who_can_pursue_content={programData?.who_can_pursue_content}
            who_can_pursue={programData?.who_can_pursue}
          />
          {programData && programData?.skills_covered_heading && !loading ? (
            <Skills skillData={programData} programData={programData} />
          ) : (
            ""
          )}
          <RolesResponsibilities
            roles_responsibilities_title={
              programData?.roles_responsibilities_title
            }
            roles_responsibilities_content={
              programData?.roles_responsibilities_content
            }
            left_image={programData?.left_image}
            all_roles={programData?.all_roles}
          />
          <ExamInfo exam_information={programData?.exam_information} />
          <CertificationProcess
            certification_process_title={
              programData?.certification_process_title
            }
            certification_process_content={
              programData?.certification_process_content
            }
            certification_process={programData?.certification_process}
            certification_process_image={
              programData?.certification_process_image
            }
          />
          {/* <StudentSupport
            student_support_title={programData?.types_of_membership_title}
            student_support={programData?.types_of_membership}
          /> */}
          <ContentPartner
            gleim_title={programData?.gleim_title}
            gleim_description={programData?.gleim_description}
            gleim_image={programData?.gleim_image}
          />
          <FreeSchedule
            fees_title={programData?.fees_title}
            fees_details={programData?.fees_details}
          />
          <JobOpportunity
            job_opportunities_title={programData?.job_opportunities_title}
            company_logos={programData?.company_logos}
          />
        </>
      )}

      {segment === "certified-public-accountant-us-cpa" && (
        <>
          <CareerCPA programData={programData} />
          <CPAJourney programData={programData} />
          <LicensedCPA programData={programData} />
          <ContentPartner
            gleim_title={programData?.beckers_title}
            gleim_description={programData?.beckers_content}
            gleim_image={programData?.beckers_image}
          />
          <HireCPA
            companies_title={programData?.companies_title}
            companies={programData?.companies}
          />
          <ExamStructure
            exam_structure_title={programData?.exam_structure_title}
            exam_structure={programData?.exam_structure}
          />
          {/* <StudentSupport
            student_support_title={programData?.student_support_title}
            student_support={programData?.student_support}
          /> */}
          <FreeSchedule
            fees_title={programData?.fees_title}
            fees_details={programData?.fees_details}
          />
        </>
      )}

      <Deliverables />
      {programData &&
      Array.isArray(programData?.why_enroll) &&
      programData?.why_enroll.length !== 0 &&
      !loading ? (
        <Enroll
          enrollData={programData?.why_enroll}
          title={programData.title}
        />
      ) : (
        ""
      )}

      {segment !== "cma" &&
      programData &&
      programData?.skills_covered_heading &&
      programData?.skills_covered.length > 0 &&
      !loading ? (
        <Skills skillData={programData} programData={programData} />
      ) : (
        ""
      )}

      {/* <Finance tagsData={tagsData}/> */}
      {/* {programData && programData?.coverage && !loading ? ( */}
      {programData?.child_programs?.length === 0 && (
        <Curriculum
          curriculumData={programData?.coverage}
          heading_about_this_course={programData?.heading_about_this_course}
        />
      )}
      {/* ) : (
        ""
      )} */}
      {programData &&
      Array.isArray(programData?.student_testimonial) &&
      programData?.student_testimonial.length !== 0 &&
      !loading ? (
        <Testimonials testimonialData={programData?.student_testimonial} />
      ) : (
        ""
      )}
      {/* <Videos videoData={videoData}/> */}
    </>
  );
};

export default ProgramDetails;
