import { useEffect, useState } from "react";
import AboutUs from "../components/pagesections/Home/AboutUs";
import Banner from "../components/pagesections/Home/Banner";
import Programes from "../components/pagesections/Home/Programes";
import Testimonial from "../components/pagesections/Home/Testimonial";
import Videos from "../components/pagesections/Home/Videos";
import Vision from "../components/pagesections/Home/Vision";
import NewsLetterCTA from "../components/pagesections/Shared/NewsLetterCTA";
import { get_test_data } from "../actions/testimonials";

import { loadProgram } from "../actions/program";
import { apicall } from "../api/api";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeData } from "../store/home";
import { fetchProgrameData } from "../store/programe";
import Companies from "../components/pagesections/CorporateTraining/Companies";
import Spinner from "../shared/Spinner";
import HomeLoader from "../shared/HomeLoader";
import SeoComponent from "../components/elements/SeoComponent";

const Home = () => {
  const homeData = useSelector((state: any) => state.homeData.homeData);
  const programData = useSelector(
    (state: any) => state.programData.programData
  );
  const loading = useSelector((state: any) => state.homeData.loading);
  const loading1 = useSelector((state: any) => state.programData.loading);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [programmeType, setProgrameType] = useState([]);

  useEffect(() => {
    apicall("get", "/index.php/wp-json/testimonials/list").then((res) =>
      setData(res.data)
    );
    apicall("get", "/index.php/wp-json/program/type").then((res) =>
      setProgrameType(res.data)
    );
    dispatch(fetchHomeData());
  }, []);

  const [companyList, setCompanyList] = useState<Array<any>>([]);
  useEffect(() => {
    get_test_data("/clients/list", "get").then((res) =>
      setCompanyList(res.data)
    );
  }, []);

  return (
    <>
      {homeData && homeData?.seo_data && (
        <SeoComponent
          title={homeData?.seo_data?.meta_title}
          description={homeData?.seo_data?.meta_description}
          keyword={homeData?.seo_data?.meta_keyword}
        />
      )}
      {loading && (
        <section className="hero-main">
          <HomeLoader />
        </section>
      )}
      {homeData && !loading && homeData.banners && (
        <Banner data={homeData.banners} />
      )}
      <Programes data={programData} programmeType={programmeType} />
      {homeData && <AboutUs data={homeData} />}
      {homeData && homeData.logos && <Companies companiesData={homeData} />}
      {homeData && homeData?.our_experts && (
        <Videos data={homeData?.our_experts} />
      )}
      {homeData && <Vision data={homeData} />}

      <div
        className="modal fade"
        id="modalVideo"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="modalVideoLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body p-0 position-relative">
              <button
                type="button"
                className="btn-close modal-close-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <iframe
                id="videoUrl"
                className="w-100"
                width="560"
                height="460"
                src="https://www.youtube.com/embed/oPS5ol_ljmg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      {homeData && homeData.testimonial && <Testimonial data={homeData} />}
      {/* <NewsLetterCTA /> */}
    </>
  );
};

export default Home;
