import React, { useEffect, useState } from "react";
import { enroll, getResponce, moodleRegister } from "../../../actions/payment";
import { AES, enc } from "crypto-js";
import LMS_URL from "../../../config/lms";
import { useDispatch } from "react-redux";
import { removeallData, removetocart } from "../../../store/cart";
import { loginuser } from "../../../actions/login";
const PaymentBody = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorMsg, setIsErrorMsg] = useState("");
  const [amout, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search).get(
    "ORDER_ID"
  );
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getResponce({ order_id: searchParams });

        setOrderStatus(res?.data?.order_details?.status);
        setAmount(res?.data?.order_details?.total);
        if (
          res.code === 200 &&
          res?.data?.order_details?.status === "completed"
        ) {
          setIsSuccess(true);
          dispatch(removeallData({}));
          localStorage.removeItem("cartData");
          setEmail(res?.data?.user_details?.email);
          let sessionUsername = localStorage.getItem("username") || "";
          let sessionPassword = localStorage.getItem("password") || "";
          let formData = new FormData();
          formData.append("wstoken", "2232f0e117ee6c6d14e8da454fa30dfb");
          formData.append("wsfunction", "local_participant_create_users");
          formData.append("moodlewsrestformat", "json");
          formData.append("password", sessionPassword);
          formData.append(
            "firstname",
            res?.data?.user_details?.name.split(" ")[0]
          );
          formData.append("email", res?.data?.user_details?.email);
          formData.append("auth", "manual");
          if (res?.data?.user_details?.name.split(" ")[1] !== undefined) {
            formData.append(
              "lastname",
              res?.data?.user_details?.name.split(" ")[1]
            );
          } else {
            formData.append("lastname", "");
          }
          formData.append("username", res?.data?.user_details?.email);
          formData.append("roleid", "5");

          const call = await moodleRegister(formData);

          if (call.code === 200) {
            const program_data = res?.data?.order_details?.program_data;
            const total = res?.data?.order_details?.total;
            const round1 = Math.round(total);
            let enrollData = new FormData();
            enrollData.append("wstoken", "f8ff1ba39f88ba1af628a0396ba76948");
            enrollData.append("wsfunction", "local_manual_enrollment");
            enrollData.append("moodlewsrestformat", "json");
            enrollData.append("email", res?.data?.user_details?.email);
            enrollData.append("roleid", "5");
            program_data.map((item: any, i: any) => {
              enrollData.append("idnumbers[" + i + "]", item?.sku);
            });
            enrollData.append("amount", round1.toString());
            const enrollRes = await enroll(enrollData);
            let loginData = new FormData();
            loginData.append("wstoken", "bb2abfaaaed78967785951e5f17f0826");
            loginData.append("wsfunction", "local_user_login");
            loginData.append("moodlewsrestformat", "json");
            loginData.append("username", sessionUsername);
            loginData.append("password", sessionPassword);
            const loginOneData = await loginuser(formData);
            localStorage.setItem(
              "loginData",
              JSON.stringify(loginOneData.data)
            );
          }
        }
      } catch (error: any) {
        // localStorage.removeItem("loginData");
        // localStorage.removeItem('username')
        // localStorage.removeItem('password')
        setIsError(true);

        setIsErrorMsg(error.response.data.message);
      }
    };
    getData();
  }, [searchParams]);

  const username = localStorage.getItem("username") || "";
  const password = localStorage.getItem("password") || "";
  const url = `${LMS_URL}/local/participant/ws/autologin3.php?username=${username}&password=${password}`;

  return (
    <>
      <div className="thanks_page sec-spacing light-bg">
        <div className="container">
          <div className="thanks_block">
            <div className="row">
              <div className="col-md-6 col-12 mx-auto text-center">
                <figure className="thanks_img">
                  {orderStatus === "completed" && (
                    <img
                      width={"120px"}
                      src="images/payment-thanks.png"
                      className="img-fluid"
                    />
                  )}
                  {orderStatus === "pending" && (
                    <img
                      width={"120px"}
                      src="images/payment-failed.png"
                      className="img-fluid"
                    />
                  )}
                </figure>
                {orderStatus === "completed" && (
                  <h1>Your Payment is successfull!</h1>
                )}
                {orderStatus === "pending" && <h1>Your Payment failed!</h1>}
                {orderStatus === "completed" && (
                  <p className="fs17">
                    You can access from your dashboard.click on the link below
                  </p>
                )}
                {orderStatus === "pending" && (
                  <p className="fs17">Something went wrong!</p>
                )}
                {orderStatus === "completed" && (
                  <a
                    href={url}
                    className="btn btn-danger btn-hover ps-5 pe-5 p-3"
                  >
                    {" "}
                    GO TO DASHBOARD
                  </a>
                )}
                <div className="clearfix"></div>
              </div>
            </div>
            {isError && (
              <div className="row">
                <div className="col-md-6 col-12 mx-auto text-center">
                  <figure className="thanks_img">
                    <img
                      width={"120px"}
                      src="images/payment-failed.png"
                      className="img-fluid"
                    />
                  </figure>
                  <h1>Payment Failed!</h1>
                  <p className="fs17">{errorMsg}</p>
                  {orderStatus === "completed" && (
                    <a
                      href={url}
                      className="btn btn-danger btn-hover ps-5 pe-5 p-3"
                    >
                      {" "}
                      GO TO DASHBOARD
                    </a>
                  )}

                  <div className="clearfix"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentBody;
