import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { loginuser } from "../actions/login";
import { useNavigate } from "react-router-dom";
import hmacSHA512 from "crypto-js/hmac-sha512";
import { SHA256 } from "crypto-js";
import LMS_URL from "../config/lms";

type AppProps = {
  setFormOpen: any;
  formOpen: any;
};

function LoginModal({ setFormOpen, formOpen }: AppProps) {
  const [loading, setLoading] = useState(false);
  const [isLogin, setLogin] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const login = async () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("wstoken", "bb2abfaaaed78967785951e5f17f0826");
    formData.append("wsfunction", "local_user_login");
    formData.append("moodlewsrestformat", "json");
    formData.append("email", username);
    // formData.append("username", username);
    formData.append("password", password);
    const loginData = await loginuser(formData);

    if (loginData.data.userfound) {
      window.location.href = "/checkout";
      setLoading(false);
      localStorage.setItem("loginData", JSON.stringify(loginData.data));
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
      setLogin(true);
      setFormOpen(false);
      toast.success("Successfully logged in");
    } else {
      toast.error(loginData.message);
      setLoading(false);
      setLogin(false);
    }
  };

  return (
    <>
      <div
        className={`modal fade ${formOpen ? "show d-block" : "d-none"}`}
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs20" id="staticBackdropLabel">
                Log In
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => setFormOpen(!setFormOpen)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="left-side-form cor_popup">
                <form method="post" action="#" id="email-form" className="">
                  <div className="lgoin" id="div1">
                    <div className="form-group">
                      <label> Username </label>
                      <input
                        type="text"
                        onChange={(e) => setUsername(e.target.value)}
                        name="username"
                        className="form-control"
                        value={username}
                        placeholder="Enter Username"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Password </label>
                      <input
                        type="password"
                        onChange={(e) => setPassword(e.target.value)}
                        name="password"
                        id="password"
                        value={password}
                        className="form-control"
                        placeholder="Enter Password"
                      />
                    </div>
                    <div className="mt-4 mb-3 text-center">
                      <button
                        className="btn btn-dark btn-lg py-3 px-5 w-100"
                        type="button"
                        id="submit"
                        name="submit-form"
                        onClick={() => login()}
                      >
                        Log in
                      </button>
                    </div>
                    <div className="link-group text-center">
                      <a
                        href={LMS_URL + "login/forgot_password.php"}
                        className="btn-link text-decoration-none small text-muted pe-2"
                      >
                        Forgot Password?
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default LoginModal;
