import React, { useCallback, useEffect, useState } from "react";
import { loginuser } from "../actions/login";
import { toast } from "react-toastify";
import { LoginSocialGoogle, IResolveParams } from "reactjs-social-login";
import { Link } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState<any>();
  const [username, setusername] = useState<any>("");
  const [password, setpassword] = useState<any>("");
  const [decodedData, setDecodedData] = useState<any>(null);

  const login = async () => {
    // setLoading(true);
    let formData = new FormData();
    formData.append("wstoken", "bb2abfaaaed78967785951e5f17f0826");
    formData.append("wsfunction", "local_user_login");
    formData.append("moodlewsrestformat", "json");
    formData.append("username", username);
    // formData.append("username", username);
    formData.append("password", password);
    const loginData = await loginuser(formData);

    // if (loginData.data.userfound) {
    if (loginData.status == "success") {
      window.location.href = "/checkout";
      //   setLoading(false);
      localStorage.setItem("loginData", JSON.stringify(loginData.data));
      localStorage.setItem("username", username);
      localStorage.setItem("password", password);
      //   setLogin(true);
      //   setFormOpen(false);
      toast.success("Successfully logged in");
    } else {
      toast.error(loginData.message);
      //   setLoading(false);
      //   setLogin(false);
    }
  };

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  //Access token decode
  useEffect(() => {
    profile?.access_token &&
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${profile?.access_token}`
        )
        .then(function (response) {
          // handle success
          setDecodedData(response?.data);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
  }, [profile]);

  // for google login
  useEffect(() => {
    if (decodedData) {
      const formData = new FormData();
      formData.append("wstoken", "5bfb4ca0218d42740673b4f6f8aa0db7");
      formData.append("moodlewsrestformat", "json");
      formData.append("wsfunction", "local_user_login_and_registration");
      formData.append("email", decodedData?.email);
      formData.append("firstname", decodedData?.given_name);
      formData.append("lastname", decodedData?.family_name);

      axios
        .post("http://rwtlms.in/acca/webservice/rest/server.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res, "response login");
          // window.location.href = "/checkout";
          localStorage.setItem("loginData", JSON.stringify(res?.data?.data));
          localStorage.setItem("username", res?.data?.data?.username);
          localStorage.setItem("password", res?.data?.data?.password);
          toast.success(res?.data?.message);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [decodedData]);

  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div
        className="bg-white p-8 rounded-lg w-full max-w-md"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div
          style={{
            fontSize: "20px",
            fontWeight: "bolder",
            textAlign: "center",
            marginTop: "40px",
          }}
        >
          Login Now
        </div> */}

        <div
          style={{
            width: "420px",
            // maxWidth: "450px",
            margin: "auto",
            border: "1px solid gray",
            padding: "40px",
            marginTop: "20px",
            marginLeft: "10px",
            marginRight: "10px",
            marginBottom: "40px",
            borderRadius: "10px 0px 0px 10px",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: "bolder",
              textAlign: "center",
              // marginTop: "40px",
              marginBottom: "20px",
            }}
          >
            Login Now
          </div>

          {/* <div>
            <LoginSocialGoogle
              // isOnlyGetToken
              scope={"https://www.googleapis.com/auth/userinfo.email"}
              client_id={
                "978577839819-2pgqe46d7b2sngptjp8lv5onophe1qs1.apps.googleusercontent.com" ||
                ""
              }
              onLoginStart={onLoginStart}
              onResolve={({ provider, data }: IResolveParams) => {
                setProvider(provider);
                setProfile(data);
              }}
              onReject={(err) => {
                console.log(err, "err");
              }}
            >
              <button className="btn btn-outline-secondary w-100">
                <img
                  src="/google-logo.png"
                  height={"25px"}
                  width={"25px"}
                  style={{ marginRight: "5px" }}
                />
                Continue with Google
              </button>
            </LoginSocialGoogle>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              padding: "10px",
            }}
          >
            <span
              style={{
                width: "150px",
                height: "1.5px",
                backgroundColor: "#999",
              }}
            ></span>
            <span
              style={{ fontSize: "17px", fontWeight: "500", color: "#777" }}
            >
              OR
            </span>
            <span
              style={{
                width: "150px",
                height: "1.5px",
                backgroundColor: "#999",
              }}
            ></span>
          </div> */}
          <form action="#" className="">
            <div style={{}}>
              <div>
                <div className="form-floating mb-3">
                  <input
                    onChange={(e) => setusername(e.target.value)}
                    name="username"
                    id="username"
                    // value={password}
                    className="form-control"
                    placeholder="Username"
                  />
                  <label htmlFor="password">
                    Username/Password <span>*</span>
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    onChange={(e) => setpassword(e.target.value)}
                    name="password"
                    id="password"
                    // value={password}
                    className="form-control"
                    placeholder="Enter Password"
                  />
                  <label htmlFor="password">
                    Password <span>*</span>
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div>
                    <input type="checkbox" /> Remember Me
                  </div> */}
                  <div style={{ color: "#4f2d7f" }}>
                    <Link
                      to={"https://lms.learningt.com/login/forgot_password.php"}
                    >
                      Forgot password?
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-12 pt-3 text-end">
                {/* {loadData === true ? (
                        <button
                          type="button"
                          className="btn btn-dark btn-lg py-3 px-5 w-100"
                          onClick={() => feedbackForm()}
                          disabled
                        >
                          Submit Enquiry
                        </button>
                      ) : ( */}
                <button
                  type="button"
                  className="btn btn-dark btn-lg py-3 px-5 w-100"
                  onClick={() => login()}
                >
                  LOGIN
                </button>
                {/* )} */}
              </div>
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <span>
                  Don't have an account?{" "}
                  <Link to={"/signup"}>
                    {" "}
                    <span
                      style={{
                        fontSize: "17px",
                        color: "#4f2d7f",
                        cursor: "pointer",
                        fontWeight: "bold",
                      }}
                    >
                      Sign Up
                    </span>
                  </Link>
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
