import { Link, useNavigate } from "react-router-dom";
import ProgramSlide from "../../items/ProgramSlide";
import Pagination from "../../elements/Pagination";
import ProgrameListLoader from "../../../shared/ProgrameListLoader";
import { useEffect, useState } from "react";
import AddToCartModal from "../ProgramDetails/AddToCartModal";
import CartAdded from "../ProgramDetails/CartAdded";
import { useDispatch, useSelector } from "react-redux";
import { apicall } from "../../../api/api";
import { fetchProgrameData } from "../../../store/programe";

type AppProps = {
  loading: any;
  data: any[];
  count: number;
  setLimit: any;
  setPage: any;
  page: any;
  limit: any;
};

const Programs = ({
  loading,
  data,
  count,
  setLimit = () => {},
  setPage = () => {},
  page,
  limit,
}: AppProps) => {
  const headerData = useSelector(
    (state: any) => state.layoutData.headerData.data
  );
  const [formOpen, setFormOpen] = useState(false);
  const [isRemoved, setRemoved] = useState(true);
  const [programe, setPrograme] = useState({
    title: "",
  });
  const dispatch = useDispatch<any>();

  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search)?.get("type");
  useEffect(() => {
    const id = Number(params);

    apicall("get", "/index.php/wp-json/program/type").then((res) => {
      setPrograme(
        Array.isArray(res.data) &&
          res.data.length > 0 &&
          res.data.find((item: any) => {
            return item.ID !== id;
          })
      );
    });
  }, [params]);

  const getPrograme = async (value: any) => {
    const id = Number(params);
    const cat_id = parseInt(value.target.value);
    if (Number.isNaN(cat_id)) {
      dispatch(fetchProgrameData(10, 1, 0, 0));
    } else {
      if (id === 0) {
        dispatch(fetchProgrameData(10, 1, 0, cat_id));
      } else {
        dispatch(fetchProgrameData(10, 1, id, cat_id));
      }
    }
  };

  const selectType = () => {
    dispatch(fetchProgrameData(10, 1, 0));
    navigate("/listing");
    setRemoved(false);
  };

  return (
    <section className="main-listing-section sec-spacing light-bg border-bottom">
      <div className="container customwid">
        <div className="row justify-content-between main-list-top mb-3 align-items-center">
          <div className="col-auto course__name_all">
            <small className="fw-normal" style={{ fontSize: "12px" }}>
              <span className="fw-bold">
                Filter by{" "}
                <i
                  className="fa fa-arrow-right px-1"
                  style={{ fontSize: "10px" }}
                ></i>
              </span>
              <span className="px-1 small"></span>
              <select
                name=""
                id=""
                className="form-select form-select-sm d-inline-block w-auto rounded-pill"
                style={{
                  paddingLeft: " 1rem",
                  backgroundColor: "#f1edf8",
                  color: "#482973",
                  borderColor: "#c2b2d8",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
                onChange={(e) => getPrograme(e)}
              >
                <option defaultValue="0">All Programmes</option>
                {Array.isArray(headerData) &&
                  headerData.map((item: any, index: any) => {
                    return (
                      <>
                        <option value={item.ID}>{item.title}</option>
                      </>
                    );
                  })}
              </select>
            </small>
          </div>
          {/* <div className="col-auto course__view">
            <h4 className="d-flex align-items-center mb-0">
             {isRemoved && <div className="btn btn-dark btn-sm rounded-pill px-3 py-1"
                onClick={()=>selectType()}
              >
                Also View {programe?.title}
              </div>}
            </h4>
          </div> */}
        </div>
        <div className="row">
          {/* <div className="col-12"> */}
          {loading && (
            <>
              <ProgrameListLoader />
              <ProgrameListLoader />
            </>
          )}
          {!loading &&
            Array.isArray(data) &&
            data.map((item) => (
              <ProgramSlide
                program_id={item.program_id}
                sku={item.sku}
                category={item?.category}
                title={item?.title}
                text={item?.description}
                link={item?.slug}
                image={item?.featured_image}
                duration={item?.duration}
                isCertificate={item?.isCertificate}
                discountPercentage={item?.discountPercentage}
                price={item?.program_fees}
                salePrice={item?.sale_price}
                enrollLink={item?.enrollLink}
                isLive={item?.isLive}
                viewType={"list"}
                setFormOpen={setFormOpen}
              />
            ))}
          {/* </div> */}
        </div>
        <Pagination
          offset={page}
          limit={limit}
          count={count}
          onSubmit={(val: any) => setPage(val.selected + 1)}
        />
      </div>
      <AddToCartModal title={""} position={"centered"} size={"lg"}>
        <CartAdded />
      </AddToCartModal>
    </section>
  );
};

export default Programs;
