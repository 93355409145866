import React from 'react'
import ContentLoader from 'react-content-loader'

const HomeLoader = (props:any) => (
    <div className="banner-loader">
      <ContentLoader
      className="loader-1"
      width={"100%"}
      height={"100%"}
      viewBox="20 50 1300 400"
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      {...props}
    >
    
      <rect x="42" y="77" rx="10" ry="10" width="100%" height="100%" />
    </ContentLoader>
    <ContentLoader
      className="loader-2"
      width={"100%"}
      height={"100%"}
      viewBox="20 50 400 400"
      backgroundColor="#f0f0f0"
      foregroundColor="#dedede"
      {...props}
    >
    
      <rect x="42" y="77" rx="10" ry="10" width="100%" height="100%" />
    </ContentLoader>
  </div>
)

HomeLoader.metadata = {
  name: 'Nic Bovee', // My name
  github: 'ghettifish', // Github username
  description: 'A simple favorite from the DoorDash local favorites.', // Little tagline
  filename: 'DoorDashFavorite', // filename of your loader
}

export default HomeLoader