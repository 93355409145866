import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addtocart, removeallData } from "../../../store/cart";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {
  programData: any;
  childData: any;
  selected: any;
  selectedOption: any;
  selectedData: any;
}

function CheckoutButton(props: Props) {
  const [isvalidate, setvalidate] = useState(false);

  const { programData, childData, selected, selectedOption, selectedData } =
    props;
  const unique = (array: any) => {
    const result: any = [];
    for (const item of array) {
      if (!result.includes(item)) {
        result.push(item);
      }
    }
    return result;
  };
  const uniqueArray = unique(selected);
  let totPrice = 0;
  let levelData: any = [];
  Array.isArray(childData) &&
    childData.map((tm) => {
      totPrice += parseInt(tm.program_price);
      levelData.push({ ...tm, selected: uniqueArray, selectedOption });
    });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addTocart = (programData: any) => {
    let totkLevel = selectedOption.filter((m: any) => {
      return m.program_type === "Knowledge Level";
    });
    let totsLevel = selectedOption.filter((m: any) => {
      return m.program_type === "Skill Level";
    });
    let totpfLevel = selectedOption.filter((m: any) => {
      return m.program_type === "Professional Level";
    });

    const tn = selectedData.map((j: any) => {
      if (j.sku !== totkLevel.length && j.mainTitle === "Knowledge Level") {
        // toast.error('Knowledge Level : please select ' + j.sku + ' program');
        return "Knowledge Level : Please Select " + j.sku + " Program";
        setvalidate(true);
      } else if (j.sku !== totsLevel.length && j.mainTitle === "Skill Level") {
        // toast.error('Skill Level : please select ' + j.sku + ' program');
        return "Skill Level : Please Select " + j.sku + " Program";
        setvalidate(true);
      } else if (
        j.sku !== totpfLevel.length &&
        j.mainTitle === "Professional Level"
      ) {
        //toast.error('Professional Level : please select ' + j.sku + ' program');
        return "Professional Level : Please Select " + j.sku + " Program";
        setvalidate(true);
      } else {
        return null;
      }
    });
    // toast.error(tn.join('\n\n'));

    if (tn.every((element: any) => element === null)) {
      dispatch(removeallData({}));
      if (programData) {
        const items = {
          program_id: programData?.program_id,
          sku: programData?.sku,
          title: programData?.title,
          category: programData?.category,
          program_fees: totPrice,
          featured_image: programData?.featured_image,
          level: levelData,
        };
        // const cartData = JSON.parse(localStorage.getItem("cartData") || "");

        dispatch(addtocart(items));
        //  localStorage.setItem("cartData", JSON.stringify(cartData));
        navigate("/checkout");
      }
    }
  };

  return (
    <>
      {" "}
      <button
        type="button"
        className="next btn btn-dark btn-lg"
        onClick={() => addTocart(programData)}
      >
        Proceed for Registration{" "}
      </button>
    </>
  );
}

export default CheckoutButton;
