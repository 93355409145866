import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { submitFeedback } from "../../../actions/feedback";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
type AppProps = {
  setFormOpen: any;
  formOpen: any;
  title: any;
  initial: any;
};

const EnquireModal = ({ setFormOpen, formOpen, title, initial }: AppProps) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [feedback, setFeedback] = useState("");

  const [firstnameErr, setFirstnameErMsg] = useState("");
  const [lastnameErr, setLastnameErMsg] = useState("");
  const [emailErr, setEmailErMsg] = useState("");
  const [phErr, setPhErr] = useState("");
  const [msgEr, setErMsg] = useState("");
  const [companyErr, setCompanyErr] = useState("");
  const [designationErr, setdesignationErr] = useState("");
  const [qualificationErr, setqualificationErr] = useState("");
  const [locationErr, setLocationErr] = useState("");

  const [loadData, setLoadData] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [qualification, setQualification] = useState("");
  const [location, setLocation] = useState("");
  const [hireFrom, setHireFrom] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({});
  const [formData2, setFormData2] = useState({});

  useEffect(() => {
    setFormData2((pre) => ({
      "first-name": firstname,
      "last-name": lastname,
      "your-email": email,
      "your-mobile": phone,
      "your-message": feedback,
      "course-name": courseName,
      "company-name": company,
      designation: designation,
      qualification: qualification,
      location: location,
      "hear-from": hireFrom,
    }));

    setFormData((pre) => ({
      FirstName: firstname,
      LastName: lastname,
      Email: email,
      MobileNumber: phone,
      Feedback: feedback,
      Course: courseName,
      Textb1: company,
      Textb2: designation,
      Textb3: qualification,
      Field5: location,
      Field4: hireFrom,
    }));
  }, [
    firstname,
    lastname,
    email,
    phone,
    feedback,
    courseName,
    company,
    designation,
    qualification,
    location,
    hireFrom,
  ]);

  const feedbackForm = async () => {
    setIsSubmit(true);
    setLoadData(true);
    // const formData = new FormData();
    // const formData2 = new FormData();
    // formData2.append("first-name", firstname);
    // formData2.append("last-name", lastname);
    // formData2.append("your-email", email);
    // formData2.append("your-mobile", phone);
    // formData2.append("your-message", feedback);
    // formData2.append("course-name", courseName);
    // formData2.append("company-name", company);
    // formData2.append("designation", designation);
    // formData2.append("qualification", qualification);
    // formData2.append("location", location);
    // formData2.append("hear-from", hireFrom);

    // formData.append("AuthToken", "GTACADEMY-30-11-2022");
    // formData.append("Source", "gtacademy");
    // formData.append("FirstName", firstname);
    // formData.append("LastName", lastname);
    // formData.append("Email", email);
    // formData.append("MobileNumber", phone);
    // formData.append("Feedback", feedback);
    // formData.append("Course", courseName);
    // formData.append("Textb1", company);
    // formData.append("Textb2", designation);
    // formData.append("Textb3", qualification);
    // formData.append("Field5", location);
    // formData.append("Field4", hireFrom);

    // "first-name":firstname,
    // "last-name":lastname,
    // "course-name":courseName,
    // "your-email":email,
    // "company-name":company,
    // "your-mobile":phone,
    // "designation":designation,
    // "qualification":qualification,
    // "location":location,
    // "hear-from":hireFrom,
    // "your-message":feedback

    console.log(formData, "formData");
    console.log(formData2, "formData2");

    if (!firstname) {
      setFirstnameErMsg("First name is required!");
      // toast.error("First name is required!");
    } else if (!lastname) {
      setLastnameErMsg("Last name is required!");
      // toast.error("Last name is required!");
    } else if (!email) {
      setEmailErMsg("Email is required!");
      // toast.error("Email is required!");
    } else if (!company) {
      setCompanyErr("Company name is required!");
      // toast.error("Company name is required!");
    } else if (!phone) {
      setPhErr("Mobile number is required!");
      // toast.error("Mobile number is required!");
    } else if (initial && !feedback) {
      setErMsg("Enquiry message is required!");
      // toast.error("Enquery message is required!");
    } else if (!designation) {
      setdesignationErr("Designation name is required!");
      // toast.error("Designation name is required!");
    } else if (!qualification) {
      setqualificationErr("Your qualification is required!");
      // toast.error("Your qualification is required!");
    } else if (!location) {
      setLocationErr("Your location is required!");
      // toast.error("Your location is required!");
    } else {
      const submitfeedback = await submitFeedback({ formData2, formData }).then(
        (res) => {
          setLoadData(false);
          setFormOpen(!formOpen);
          toast.success("Your enquiry is submitted successfully!");
          setFirstname("");
          setLastname("");
          setCourseName("");
          setCompany("");
          setEmail("");
          setPhone("");
          setDesignation("");
          setQualification("");
          setLocation("");
          setHireFrom("");
          setFeedback("");

          setFirstnameErMsg("");
          setLastnameErMsg("");
          setPhErr("");
          setEmailErMsg("");
          setErMsg("");
          setdesignationErr("");
          setCompanyErr("");
          setLocationErr("");

          // initial ? navigate("/thankyou") : setFormOpen(false);
          setFormOpen(false);
        }
      );
    }

    //

    // if (submitfeedback.status === "validation_failed") {
    //   // toast.error(submitfeedback.message);

    //   if (Array.isArray(submitfeedback.invalid_fields)) {
    //     const firstName = submitfeedback.invalid_fields.find((item: any) => {
    //       return item.field === "first-name";
    //     });
    //     const lastName = submitfeedback.invalid_fields.find((item: any) => {
    //       return item.field === "last-name";
    //     });
    //     const email = submitfeedback.invalid_fields.find((item: any) => {
    //       return item.field === "your-email";
    //     });
    //     const phone = submitfeedback.invalid_fields.find((item: any) => {
    //       return item.field === "your-mobile";
    //     });
    //     const message = submitfeedback.invalid_fields.find((item: any) => {
    //       return item.field === "your-message";
    //     });
    //     if (firstName !== undefined) {
    //       setFirstnameErMsg(firstName.message);
    //     } else {
    //       setFirstnameErMsg("");
    //     }
    //     if (lastName !== undefined) {
    //       setLastnameErMsg(lastName.message);
    //     } else {
    //       setLastnameErMsg("");
    //     }
    //     if (email !== undefined) {
    //       setEmailErMsg(email.message);
    //     } else {
    //       setEmailErMsg("");
    //     }
    //     if (phone !== undefined) {
    //       setPhErr(phone.message);
    //     } else {
    //       setPhErr("");
    //     }
    //     if (message !== undefined) {
    //       setErMsg(message.message);
    //     } else {
    //       setErMsg("");
    //     }
    //   }
    // } else if (initial === false) {
    //   setFormOpen(!formOpen);
    //   toast.success(submitfeedback.message);
    //   setLoadData(false);
    //   setFirstname("");
    //   setLastname("");
    //   setEmail("");
    //   setPhone("");
    //   setFeedback("");
    //   setFirstnameErMsg("");
    //   setLastnameErMsg("");
    //   setPhErr("");
    //   setEmailErMsg("");
    //   setErMsg("");
    // } else {
    //   setFormOpen(!formOpen);
    //   toast.success(submitfeedback.message);
    //   setLoadData(false);
    //   setFirstname("");
    //   setLastname("");
    //   setEmail("");
    //   setPhone("");
    //   setFeedback("");
    //   setFirstnameErMsg("");
    //   setLastnameErMsg("");
    //   setPhErr("");
    //   setEmailErMsg("");
    //   setErMsg("");
    //   navigate("/thankyou");
    // }
    setLoadData(false);
  };

  function capitalize(str: any) {
    return str.replace(/\b\w/g, function (char: string) {
      return char.toUpperCase();
    });
  }

  const programData = useSelector(
    (state: any) => state.programData.programData
  );

  const programDetails = useSelector(
    (state: any) => state.programData.programDetails
  );

  //console.log(,'programData')

  useEffect(() => {
    setCourseName(id && capitalize(id?.replace(/-/g, " ")));
    setHireFrom("");
  }, [id, initial]);

  return (
    <>
      <div className={`modal fade ${formOpen ? "show d-block" : "d-none"}`}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">
                Make a enquiry
              </h1>
              {initial === true && (
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setFormOpen(!setFormOpen)}
                />
              )}
            </div>
            <div className="modal-body">
              <div className="left-side-form cor_popup">
                <form method="post" action="#" id="email-form" className="">
                  {/* <div className="form-group">
                    <label> First Name*</label>
                    <input
                      type="text"
                      name="first-name"
                      className="form-control"
                      placeholder="Enter First Name"
                      id="first-name"
                      value={firstname}
                      onChange={(e) => setFirstname(e.target.value)}
                      required
                    />
                    {firstnameErr && (
                      <p style={{ color: "red" }}>{firstnameErr}</p>
                    )}
                  </div> */}
                  <div className="enquire-now-sideform">
                    <div style={{ display: "flex", gap: "5px" }}>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="first-name"
                          id="first-name"
                          placeholder="First Name*"
                          value={firstname}
                          onChange={(e) => setFirstname(e.target.value)}
                          required
                        />
                        {firstnameErr && (
                          <p style={{ color: "red" }}>{firstnameErr}</p>
                        )}
                        <label htmlFor="first-name">
                          First Name <span>*</span>
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="last-name"
                          id="last-name"
                          placeholder="Last Name*"
                          value={lastname}
                          onChange={(e) => setLastname(e.target.value)}
                          required
                        />
                        {lastnameErr && (
                          <p style={{ color: "red" }}>{lastnameErr}</p>
                        )}
                        <label htmlFor="last-name">
                          Last Name <span>*</span>
                        </label>
                      </div>
                    </div>

                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="course-name"
                        aria-label="How Did You Hear About Us?"
                        onChange={(e) => setCourseName(e.target.value)}
                        defaultValue={id && id}
                        required
                        disabled={
                          programDetails?.show_popup === 1 ? true : false
                        }
                      >
                        {/* <option>Select one source</option> */}
                        {/* <option>{title ? title : id}</option> */}

                        {programData &&
                          Array.isArray(programData) &&
                          programData.length > 0 &&
                          programData.map((m: any, r: any) =>
                            //  <option value={m.program_id} selected={segment === m.slug?true:false} key={r}>{m.title}</option>
                            // <option value={m.program_id} key={r}>{m.title}</option>
                            programDetails?.show_popup === 1 ? (
                              <>
                                <option
                                  style={{ fontWeight: "600", color: "black" }}
                                  selected={true}
                                  value={
                                    id && capitalize(id?.replace(/-/g, " "))
                                  }
                                  key={r}
                                >
                                  {id && capitalize(id?.replace(/-/g, " "))}
                                </option>
                              </>
                            ) : (
                              <option value={m?.title} key={r}>
                                {m?.title}
                              </option>
                            )
                          )}
                      </select>
                      <label htmlFor="your-mobile">
                        Course<span></span>
                      </label>
                    </div>

                    <div style={{ display: "flex", gap: "5px" }}>
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          name="your-email"
                          id="your-email"
                          placeholder="Email ID*"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        {emailErr && <p style={{ color: "red" }}>{emailErr}</p>}
                        <label htmlFor="your-email">
                          Email ID <span>*</span>
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="your-company"
                          id="your-company"
                          placeholder="Company*"
                          value={company}
                          onChange={(e) => setCompany(e.target.value)}
                          required
                        />
                        {companyErr && (
                          <p style={{ color: "red" }}>{companyErr}</p>
                        )}
                        <label htmlFor="your-company">
                          Company <span>*</span>
                        </label>
                      </div>
                    </div>

                    <div style={{ display: "flex", gap: "5px" }}>
                      <div className="form-floating mb-3">
                        <input
                          type="number"
                          className="form-control"
                          id="your-mobile"
                          name="your-mobile"
                          placeholder="Mobile No.*"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          required
                        />
                        {/* {isSubmit && phone && (
                          <p style={{ color: "red" }}>{phErr}</p>
                        )} */}
                        {phErr && <p style={{ color: "red" }}>{phErr}</p>}
                        <label htmlFor="your-mobile">
                          Mobile No* <span></span>
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="your-designation"
                          name="your-designation"
                          placeholder="Designation*"
                          value={designation}
                          onChange={(e) => setDesignation(e.target.value)}
                          required
                        />
                        {/* {isSubmit && designation && (
                          <p style={{ color: "red" }}>
                            Please fill out this field
                          </p>
                        )} */}
                        {designationErr && (
                          <p style={{ color: "red" }}>{designationErr}</p>
                        )}
                        <label htmlFor="your-designation">
                          Designation. <span>*</span>
                        </label>
                      </div>
                    </div>

                    <div style={{ display: "flex", gap: "5px" }}>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="your-qualification"
                          name="your-qualification"
                          placeholder="Qualification*"
                          value={qualification}
                          onChange={(e) => setQualification(e.target.value)}
                          required
                        />
                        {qualificationErr && (
                          <p style={{ color: "red" }}>{qualificationErr}</p>
                        )}
                        <label htmlFor="your-designation">
                          Qualification. <span>*</span>
                        </label>
                      </div>
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="your-location"
                          name="your-location"
                          placeholder="Location*"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          required
                        />
                        {locationErr && (
                          <p style={{ color: "red" }}>{locationErr}</p>
                        )}
                        <label htmlFor="your-designation">
                          Location. <span>*</span>
                        </label>
                      </div>
                    </div>

                    <div className="form-floating mb-3">
                      <select
                        className="form-select"
                        id="hearfrom"
                        aria-label="How Did You Hear About Us?"
                        onChange={(e) => setHireFrom(e.target.value)}
                        required
                        defaultValue={""}
                      >
                        <option selected value="">
                          Select one source
                        </option>
                        <option value="CA club">CA Club</option>
                        <option value="Google">Google</option>
                        <option value="Facebook">Facebook</option>
                        <option value="Linkedin">Linkedin</option>
                        <option value="Email">Email</option>
                        <option value="IIM">IIM</option>
                        <option value="Naukri">Naukri</option>
                        <option value="Study at home">Study at home</option>
                        <option value="Others">Others</option>
                      </select>
                      <label htmlFor="your-mobile">
                        How Did You Hear About Us?<span></span>
                      </label>
                    </div>

                    {initial === true && (
                      <div className="form-floating">
                        <textarea
                          className="form-control"
                          placeholder="Enquiry Message"
                          id="your-message"
                          name="your-message"
                          value={feedback}
                          onChange={(e) => setFeedback(e.target.value)}
                          required
                        ></textarea>
                        {msgEr && <p style={{ color: "red" }}>{msgEr}</p>}
                        <label htmlFor="your-message">Enquiry Message</label>
                      </div>
                    )}

                    <div className="col-lg-12 pt-3 text-end">
                      {loadData === true ? (
                        <button
                          type="button"
                          className="btn btn-dark btn-lg py-3 px-5 w-100"
                          onClick={() => feedbackForm()}
                          disabled
                        >
                          Submit Enquiry
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-dark btn-lg py-3 px-5 w-100"
                          onClick={() => feedbackForm()}
                        >
                          Submit Enquiry
                        </button>
                      )}
                    </div>
                  </div>
                  {/* <div className="form-group">
                    <label> Last Name*</label>
                    <input
                      type="text"
                      name="last-name"
                      className="form-control"
                      placeholder="Enter Last Name"
                      id="last-name"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      required
                    />
                    {lastnameErr && (
                      <p style={{ color: "red" }}>{lastnameErr}</p>
                    )}
                  </div>
                  <div className=" form-group">
                    <label>Email ID*</label>
                    <input
                      type="email"
                      name="your-email"
                      id="your-email"
                      className="form-control"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {emailErr && <p style={{ color: "red" }}>{emailErr}</p>}
                  </div>
                  <div className=" form-group">
                    <label>Phone No.*</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="your-mobile"
                      name="your-mobile"
                      placeholder="Enter your phone no."
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {phErr && <p style={{ color: "red" }}>{phErr}</p>}
                  </div>
                  <div className=" form-group">
                    <label>Enquiry</label>
                    <input
                      type="text"
                      id="your-message"
                      name="your-message"
                      className="form-control"
                      placeholder="Enter your Enquiry"
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                    />
                    {msgEr && <p style={{ color: "red" }}>{msgEr}</p>}
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      className="btn btn-dark btn-lg py-3 px-5 w-100"
                      type="button"
                      id="submit"
                      name="submit-form"
                      data-bs-dismiss="modal"
                      onClick={() => feedbackForm()}
                    >
                      {loadData && <i className="fa fa-sync fa-spin"></i>}&nbsp;
                      SUBMIT DETAILS
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal-backdrop fade ${
          formOpen ? "show d-block" : "d-none"
        }`}
      ></div>
    </>
  );
};

export default EnquireModal;
