
const OurMission = ({aboutData}: any) => {
    return (
        <>
            <section className="mission-vision-section light-bg">
                <div className="alternate-content"> <span className="image-bg" style={{ backgroundImage: `url(${aboutData?.mission_image || ''})` }}></span>
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-lg-6 col-12">
                                <div className="alternate-content-block">
                                    <h3 className="fs28 fw500">{aboutData?.mission_title}</h3>
                                    <div dangerouslySetInnerHTML={{__html: aboutData?.mission_content}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="alternate-content"> <span className="image-bg" style={{ backgroundImage: `url(${aboutData?.vision_imagae || ''})` }}></span>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <div className="alternate-content-block">
                                    <h3 className="fs28 fw500">{aboutData?.vision_title}</h3>
                                    <div dangerouslySetInnerHTML={{__html: aboutData?.vision_content}}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OurMission