import { createSlice } from '@reduxjs/toolkit';
import { apiCallBegan } from './api';
import BASE_URL from '../config/host';

export const layoutSlice = createSlice({
  name: 'programData',
  initialState: {
    headerData: {
      loading: false,
      data: {}
    },
    footerData: {
      loading: false,
      data: {}
    },
    feedbackData: {
      loading: false,
      data: {}
    }
  },
  reducers: {
    headerDataRequested: (state, action) => {
      state.headerData.loading = true;
    },
    headerDataFailed: (state, action) => {
      state.headerData.loading = false;
    },
    headerDataReceived: (state, action) => {
      state.headerData.loading = false;
      state.headerData.data = action.payload.data;
    },
    footerDataRequested: (state, action) => {
      state.footerData.loading = true;
    },
    footerDataFailed: (state, action) => {
      state.footerData.loading = false;
    },
    footerDataReceived: (state, action) => {
      state.footerData.loading = false;
      state.footerData.data = action.payload.data;
    },
    feedbackDataRequested: (state, action) => {
      state.feedbackData.loading = true;
    },
    feedbackDataFailed: (state, action) => {
      state.feedbackData.loading = false;
    },
    feedbackDataReceived: (state, action) => {
      // console.log(action.payload)
      state.feedbackData.loading = false;
      state.feedbackData.data = action.payload;
    },
  },
});

export const {
  headerDataReceived,
  headerDataRequested,
  headerDataFailed,
  footerDataFailed,
  footerDataReceived,
  footerDataRequested,
  feedbackDataReceived,
  feedbackDataRequested,
  feedbackDataFailed
} = layoutSlice.actions;

export const fetchHeaderData = () =>
  apiCallBegan({
    url: '/index.php/wp-json/menu/header',
    method: 'get',
    data: {},
    baseUrl: BASE_URL,
    onSuccess: headerDataReceived.type,
    onStart: headerDataRequested.type,
    onError: headerDataFailed.type,
  });

export const fetchFooterData = () =>
  apiCallBegan({
    url: '/index.php/wp-json/menu/footer',
    method: 'get',
    data: {},
    baseUrl: BASE_URL,
    onSuccess: footerDataReceived.type,
    onStart: footerDataRequested.type,
    onError: footerDataFailed.type,
  });

  export const fetchFeedbackData = (body:any) =>
  apiCallBegan({
    url: '/index.php/wp-json/contact-form-7/v1/contact-forms/135/feedback',
    method: 'post',
    data: body,
    baseUrl: BASE_URL,
    onSuccess: feedbackDataReceived.type,
    onStart: feedbackDataRequested.type,
    onError: feedbackDataFailed.type,
  });
