import React from 'react'

interface Props {scope_of_acca:any[],scope_of_acca_image:any}

function Scope({scope_of_acca,scope_of_acca_image}: Props) {

    function sliceIntoChunks(arr:any[], chunkSize:any) {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }
    
  const getData = sliceIntoChunks(scope_of_acca, 4);

    return (
        <><section className="Scope-ACCA-sec py-3 section-content section-bt bg-light-purple-1 "  >
        <div className="container customwid ">
          <div className="text-center section-heading-lg mb-4">
            <h4 className="mb-1">Scope of ACCA</h4>
          </div>
          <div className="row align-items-center pt-2">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="Scope-content">
                <ul className="sr-tw-ul">
                  {Array.isArray(getData[0]) && getData[0].map((item,index)=>{
                    return (<><li>
                        <div className="text">
                          <div className="icon-right pe-2"><img src={item?.icon} alt=" "/></div>
                          <h4> {item?.title}</h4>
                        </div>
                      </li></>)
                  })}
                 
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 text-center">
              <div className="sd-img"> <img src={scope_of_acca_image} alt="img"/> </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <div className="Scope-content">
                <ul className="sr-tw-ul">
                {Array.isArray(getData[1]) && getData[1].map((item,index)=>{
                    return (<><li>
                        <div className="text">
                          <div className="icon-right pe-2"><img src={item?.icon} alt=" "/></div>
                          <h4> {item?.title}</h4>
                        </div>
                      </li></>)
                  })}
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section></>
    )
}

export default Scope
