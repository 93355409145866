import Image from "../../elements/Image";
import { useState, useEffect } from "react";

import { addtocart } from "../../../store/cart";
import { useDispatch, useSelector } from "react-redux";

import EnquireModal from "../Shared/EnquireModal";
import CalculatorModal from "../Shared/CalculatorModal";
import { enqueryFormOpen } from "../../../store/enqueryFormOpen";

import { numberWithCommas, onlyUnique } from "../../../helpers";
import AddToCartModal from "./AddToCartModal";
import CartAdded from "./CartAdded";
import BrochureModal from "../Shared/BrochureModal";
import { useNavigate } from "react-router-dom";

type AppProps = {
  segment: any;
  programData: any;
};

const Banner = ({ segment, programData }: AppProps) => {
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);
  const [formOpen, setFormOpen] = useState(false);
  const [calculatorOpen, setCalculatorOpen] = useState(false);
  const [brochureForm, setBrochureForm] = useState(false);
  const cartData = useSelector((state: any) => state.counter.cartData);
  const FromRToggleData = useSelector(
    (state: any) => state.enqueryToggle.formOpen
  );
  const [toggle, setToggle] = useState(FromRToggleData);
  const [flag, setFlag] = useState(false);
  const navigate = useNavigate();

  const addTocart = (programData: any) => {
    if (programData) {
      const items = {
        program_id: programData?.program_id,
        sku: programData?.sku,
        title: programData?.title,
        category: programData?.category,
        program_fees: programData?.program_fees,
        featured_image: programData?.featured_image,
        sale_price: programData?.sale_price,
      };
      dispatch(addtocart(items));
      // localStorage.setItem("cartData", JSON.stringify(cartData));
      //navigate("/checkout");
    }
  };
  useEffect(() => {
    if (cartData.length > 0) {
      let unique = onlyUnique(cartData);
      localStorage.setItem("cartData", JSON.stringify(unique));
    }
  }, [cartData]);

  function createMarkup() {
    return { __html: programData?.description };
  }

  const openEnqueryForm = () => {
    window.scrollTo(0, 500);
    dispatch(enqueryFormOpen(!FromRToggleData));
  };

  const openCheckOutModal = (data: any) => {
    setCalculatorOpen(true);
  };

  useEffect(() => {
    programData &&
      programData?.show_popup &&
      programData?.show_popup === 1 &&
      setFormOpen(true);
    // setFlag(true);
  }, [programData?.show_popup]);

  return (
    <>
      <section
        className="Program-banner-top program-banner-new pb-4"
        style={{ backgroundImage: `url(${programData?.banner})` }}
      >
        <div className="container customwid">
          <div className="row">
            <div className="col-md-8 pt-3 pb-2">
              <div className="course-detalis-topleft rounded">
                <div className="course-heading_prt">
                  <h2 className="course_heading1">
                    {programData && programData?.category}
                  </h2>
                  <h1 className="course_heading2">
                    {programData && programData?.title}
                  </h1>
                  <div className="lead">
                    <div dangerouslySetInnerHTML={createMarkup()}></div>
                  </div>
                  <div className="row">
                    <div className="col-sm-8 Course-block pe-3">
                      <div className="flex">
                        {programData.starting_date && (
                          <div className="course-schedule">
                            <div className="course-Fees">
                              <div className="content">
                                <p>Starting Date</p>
                                <span>{programData?.starting_date}</span>
                              </div>
                            </div>
                          </div>
                        )}
                        {programData.duration && (
                          <div className="course-schedule">
                            <div className="course-Fees">
                              <div className="content">
                                <p>Duration</p>
                                <span>{programData.duration}</span>
                              </div>
                            </div>
                          </div>
                        )}
                        {programData?.sub_category && (
                          <div className="course-schedule">
                            <div className="course-Fees">
                              <div className="content">
                                <p>Mode of Learning</p>
                                <span>
                                  {programData && programData?.sub_category}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                        {programData?.sale_price ? (
                          <div className="course-schedule">
                            <div className="course-Fees">
                              <div className="content">
                                <p className="text-capitalize">Course fee</p>
                                <span>
                                  <del
                                    style={{
                                      color: "gray",
                                      textDecoration: "line-through",
                                    }}
                                  >
                                    INR{" "}
                                    {programData &&
                                      programData?.program_fees &&
                                      numberWithCommas(
                                        programData?.program_fees
                                      )}
                                  </del>{" "}
                                  <br />
                                  INR{" "}
                                  {programData &&
                                    programData?.sale_price &&
                                    numberWithCommas(programData?.sale_price)}
                                </span>
                                <small
                                  style={{
                                    fontSize: "10px",
                                    fontFamily: "Inter, sans-serif",
                                  }}
                                >
                                  (Exclusive of 18% GST)
                                </small>
                              </div>
                            </div>
                          </div>
                        ) : !programData?.sale_price &&
                          !programData?.program_fees ? (
                          ""
                        ) : (
                          <div className="course-schedule">
                            <div className="course-Fees">
                              <div className="content">
                                <p className="text-capitalize">Course fee</p>
                                <span>
                                  INR{" "}
                                  {programData &&
                                    programData?.program_fees &&
                                    numberWithCommas(programData?.program_fees)}
                                </span>
                                <small
                                  style={{
                                    fontSize: "10px",
                                    fontFamily: "Inter, sans-serif",
                                  }}
                                >
                                  (Exclusive of 18% GST)
                                </small>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-4 pe-4">
                      <div className="course-EnrollNow">
                        {programData?.child_programs?.length === 0 ? (
                          <>
                            {programData?.program_fees && (
                              <button
                                className="btn btn btn-dark px-5 w-100"
                                onClick={() => {
                                  addTocart(programData);
                                  navigate("/checkout");
                                }}
                                type="button"
                                // data-bs-toggle="modal"
                                // data-bs-target="#staticBackdrop"
                              >
                                Enroll
                              </button>
                            )}
                          </>
                        ) : (
                          <button
                            className="btn btn btn-dark px-5 w-100"
                            onClick={() => openCheckOutModal(programData)}
                          >
                            Enroll
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {programData &&
                  Array.isArray(programData?.managers) &&
                  programData.managers.length !== 0 && (
                    <div className="Course-footer border-top program-manager position-relative">
                      <div
                        className=""
                        style={{
                          position: "absolute",
                          top: "-13px",
                          left: "34px",
                        }}
                      >
                        {/* <Image
                          src={
                            window.location.origin + "/images/pm-tag-head.png"
                          }
                          alt={""}
                          width={"100%"}
                          height={"100%"}
                          effect={"blur"}
                        /> */}
                        <div className="Course-programme-title">
                          Programme Manager
                        </div>
                      </div>

                      <div className="row">
                        {programData &&
                          programData.managers &&
                          programData.managers.map((item: any, index: any) => (
                            <>
                              <div
                                className="col-md-4 col-12 d-flex pt-2 align-items-start"
                                key={index}
                              >
                                <div className="icon flex-shrink-0 ">
                                  <i className={"fa fa-light fa-user me-2"}></i>
                                </div>
                                <div className="content flex-grow-1">
                                  <h6 className="fs14 fw500 mb-0">
                                    {item.name}
                                  </h6>
                                  <span className="fs12">
                                    {item.designation}
                                  </span>
                                </div>
                              </div>
                              <div
                                className="col-md-4 col-12 d-flex pt-2 align-items-start"
                                key={index}
                              >
                                <div className="icon flex-shrink-0 d-flex align-items-start">
                                  <i
                                    className={"fa fa-light fa-envelope me-2"}
                                  ></i>
                                </div>
                                <div className="content flex-grow-1">
                                  <h6 className="fs14 fw500 mb-0">
                                    Email Address
                                  </h6>
                                  <span className="fs12">{item.email}</span>
                                </div>
                              </div>

                              <div
                                className="col-md-4 col-12 d-flex pt-2 align-items-start"
                                key={index}
                              >
                                <div className="icon flex-shrink-0 ">
                                  <i
                                    className={"fa fa-light fa-phone me-2"}
                                  ></i>
                                </div>
                                <div className="content flex-grow-1">
                                  <h6 className="fs14 fw500 mb-0">
                                    Contact No
                                  </h6>
                                  <a
                                    href={`tel:${item.mobile_no}`}
                                    className="fs12"
                                  >
                                    {item.mobile_no}
                                  </a>
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="col-md-4 pt-3 pb-2">
              <div className="righr_video-pr rounded bg-white">
                <figure className="mb-0 position-relative">
                  {programData.video_url && (
                    <div className="video-btn">
                      <a
                        className="popup-video"
                        target={"_blank"}
                        href={programData.video_url}
                      >
                        <i className="fas fa-play"></i>
                        <span>Demo Video</span>
                      </a>
                    </div>
                  )}
                  {programData.video_image && (
                    <Image
                      src={programData.video_image}
                      alt={""}
                      width={"100%"}
                      height={"100%"}
                      effect={"blur"}
                      classes={"w-100 rounded"}
                    />
                  )}
                </figure>

                {programData.brochure && (
                  <div className="ps-2 pe-2 py-2">
                    {/* {programData?.show_popup !== 1 && ( */}
                    <a
                      className="btn btn-outline-dark w-100"
                      // target={"_blank"}
                      // href={programData.brochure}
                      onClick={() => setBrochureForm(!brochureForm)}
                    >
                      Download Brochure
                    </a>
                    {/* )} */}
                    <button
                      type="button"
                      onClick={() => {
                        setFormOpen(true);
                        setFlag(true);
                      }}
                      className="btn btn-dark w-100 mt-2"
                    >
                      Enquire Now
                    </button>
                    {/* <button type="button"  onClick={openEnqueryForm} className="btn btn-dark w-100 mt-2">Enquire Now</button> */}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="top-stats-section">
          <div className="container customwid">
            <div className="top-stats-inner overflow-hidden">
              <div className="row">
                <div className="col-md-8">
                  <div className="row align-items-center py-2">
                    {programData.overallRating && programData.totalRating && (
                      <div className="col-md-4">
                        <div className="mid-prt-course">
                          <div className="d-flex">
                            <div className="flex-shrink-0 star">
                              <i className="fa-solid fa-star text-yellow"></i>
                            </div>

                            <div className="flex-grow-1 ms-3 star__ratings">
                              <h5 className="mb-0">
                                <span className="star-number fs18 fw600">
                                  {programData.overallRating
                                    ? programData.overallRating
                                    : "4.8"}
                                </span>
                                <small className="fs14 text-muted">/5</small>
                              </h5>
                              <span className="sm-t sm-t3 fs12 text-muted">
                                {programData.totalRating
                                  ? programData.totalRating
                                  : "570"}{" "}
                                ratings
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {programData.already_enrolled && (
                      <div className="col-md-4">
                        <div className="mid-prt-course">
                          <div className="d-flex">
                            <div className="flex-shrink-0 enrolled-color">
                              <i className="fa-solid fa-user-check text-green"></i>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h5 className="mb-0 sm-t sm-t2 fs15">
                                {programData.already_enrolled}
                              </h5>
                              <span className="sm-t sm-t3 fs12 text-muted">
                                already enrolled
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-md-4">
                      <div className="mid-prt-course">
                        <div className="d-flex">
                          <div className="flex-shrink-0 enrolled-color">
                            <i className="fa-solid fa-file-lines text-red"></i>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <h5 className="mb-0 sm-t sm-t2 fs15">
                              Sharable Certificate
                            </h5>
                            <span className="sm-t sm-t3 fs12 text-muted">
                              on Program Completion
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="cta-btn-wrap text-center py-2">
                    <h5 className="fs16 mb-1 mt-1">Need More Information?</h5>
                    <button
                      className="btn btn-outline-dark py-2 w-75 mx-auto mt-1 mb-1"
                      onClick={() => setFormOpen(true)}
                    >
                      Enquire Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <AddToCartModal title={""} position={"centered"} size={"md"}>
          <CartAdded />
        </AddToCartModal> */}
        <CalculatorModal
          calculatorOpen={calculatorOpen}
          setCalculatorOpen={setCalculatorOpen}
          programData={programData}
        />
        <EnquireModal
          setFormOpen={setFormOpen}
          formOpen={formOpen}
          title={programData?.title}
          initial={flag}
        />
        <BrochureModal
          setFormOpen={setBrochureForm}
          formOpen={brochureForm}
          title={programData?.title}
          // initial={flag}
          brochureLink={programData?.brochure}
        />
      </section>
    </>
  );
};

export default Banner;
