import { url } from "inspector";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { submitFeedbackLandingPage } from "../actions/feedback";
import { toast } from "react-toastify";
import axios from "axios";
import BASE_URL from "../config/host";
import ProgrameListLoader from "../shared/ProgrameListLoader";
import ProgrameDetailLoader from "../shared/ProgrameDetailLoader";
import Spinner from "../shared/Spinner";
import HomeLoader from "../shared/HomeLoader";
import { fetchLandingFDDetails, fetchLandingPageDetails } from "../store/page";
import { useDeviceType } from "../config/device";

const EnquireSlug = () => {
  const [loadData, setloadData] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [course, setcourse] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [company, setcompany] = useState("");
  const [designation, setdesignation] = useState("");
  const [qualification, setqualification] = useState("");
  const [location, setlocation] = useState("");
  const [hearFrom, sethearFrom] = useState("");

  const [firstnameErr, setFirstnameErMsg] = useState("");
  const [lastnameErr, setLastnameErMsg] = useState("");
  const [emailErr, setEmailErMsg] = useState("");
  const [phErr, setPhErr] = useState("");
  const [companyErr, setCompanyErr] = useState("");
  const [designationErr, setdesignationErr] = useState("");
  const [qualificationErr, setqualificationErr] = useState("");
  const [locationErr, setLocationErr] = useState("");

  const navigate = useNavigate();
  const [programData, setprogramData] = useState([]);
  const [slugs, setSlugs] = useState<string[]>([]);
  const [flag, setflag] = useState(true);
  const [isDisabled, setDisabled] = useState(false);
  const [bannerData, setbannerData] = useState<any>(null);
  const [loading, setloading] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const dispatch = useDispatch<any>();
  const device = useDeviceType();
  const { id } = useParams();

  const params =
    id &&
    id.replace(/-/g, " ").replace(/\b\w/g, (match) => match.toUpperCase());

  // const backgroundImageStyle = {
  //   backgroundImage:
  //     "url(https://rwtpl.com/demov/gt/wp-content/uploads/2023/01/bg-program.jpg)",
  // };
  // const backgroundImageStyle = {
  //   backgroundImage: bannerData
  //     ? `url(${bannerData?.featured_image})`
  //     : "url(https://rwtpl.com/demov/gt/wp-content/uploads/2023/01/bg-program.jpg)",
  // };

  useEffect(() => {
    dispatch(fetchLandingPageDetails(id));
  }, [id]);

  const backgroundImageStyle = {
    backgroundImage: bannerData && `url(${bannerData?.featured_image})`,
  };

  // useEffect(() => {
  //   axios
  //     .get(BASE_URL + "/index.php/wp-json/page/id?page_id=2401")
  //     .then((response) => {
  //       setbannerData(response.data.data);
  //     })
  //     .catch((err) => console.log(err, "err"));
  // }, []);
  useEffect(() => {
    axios
      .get(BASE_URL + "/index.php/wp-json/landing_page/detail?slug=" + id)
      .then((response) => {
        setbannerData(response.data.data.data);
        setloading(false); // Set loading to false when data is fetched
      })
      .catch((err) => {
        setloading(false); // Set loading to false in case of an error
      });
  }, []);

  useEffect(() => {
    axios
      .get(BASE_URL + "/index.php/wp-json/landing/pages")
      .then((response) => {
        setprogramData(response.data.data.data);
      });
  }, []);

  const handleSubmit = async () => {
    setIsSubmit(true);
    setloadData(true);
    const formData2 = {
      "first-name": firstName,
      "last-name": lastName,
      "course-name": course,
      "your-email": email,
      "company-name": company,
      "your-mobile": phone,
      designation: designation,
      qualification: qualification,
      location: location,
      "hear-from": hearFrom,
    };
    const formData = {
      AuthToken: "GTACADEMY-30-11-2022",
      Source: "gtacademy",
      FirstName: firstName,
      LastName: lastName,
      Course: course,
      Email: email,
      Textb1: company,
      MobileNumber: phone,
      Textb2: designation,
      Textb3: qualification,
      Field5: location,
      Field4: hearFrom,
    };

    try {
      if (!firstName) {
        setFirstnameErMsg("First name is required!");
        // toast.error("First name is required!");
      }
      if (!lastName) {
        setLastnameErMsg("Last name is required!");
        // toast.error("Last name is required!");
      }
      if (!email) {
        setEmailErMsg("Email is required!");
        // toast.error("Email is required!");
      }
      if (!company) {
        setCompanyErr("Company name is required!");
        // toast.error("Company name is required!");
      }
      if (!phone) {
        setPhErr("Mobile number is required!");
        // toast.error("Mobile number is required!");
      }

      if (!designation) {
        setdesignationErr("Designation name is required!");
        // toast.error("Designation name is required!");
      }
      if (!qualification) {
        setqualificationErr("Your qualification is required!");
        // toast.error("Your qualification is required!");
      }
      if (!location) {
        setLocationErr("Your location is required!");
        // toast.error("Your location is required!");
      }

      if (
        firstName &&
        lastName &&
        email &&
        company &&
        phone &&
        designation &&
        qualification &&
        location
      ) {
        const submitfeedback = await submitFeedbackLandingPage({
          formData,
          formData2,
        })
          .then((res) => {
            setloadData(false);

            toast.success("Your enquiry is submitted successfully!");
            setfirstName("");
            setlastName("");
            setcourse("");
            setcompany("");
            setemail("");
            setphone("");
            setdesignation("");
            setqualification("");
            setlocation("");
            sethearFrom("");

            setFirstnameErMsg("");
            setLastnameErMsg("");
            setPhErr("");
            setEmailErMsg("");

            setdesignationErr("");
            setCompanyErr("");
            setLocationErr("");

            navigate("/thankyou");
          })
          .catch((err) => {
            toast.error("Something went wrong, try later !");
          });

        // if (submitfeedback === "Success") {
        //   setloadData(false);

        //   toast.success("Your enquiry is submitted successfully!");
        //   setfirstName("");
        //   setlastName("");
        //   setcourse("");
        //   setcompany("");
        //   setemail("");
        //   setphone("");
        //   setdesignation("");
        //   setqualification("");
        //   setlocation("");
        //   sethearFrom("");

        //   setFirstnameErMsg("");
        //   setLastnameErMsg("");
        //   setPhErr("");
        //   setEmailErMsg("");

        //   setdesignationErr("");
        //   setCompanyErr("");
        //   setLocationErr("");

        //   navigate("/thankyou");
        // } else {
        //   toast.error("Something went wrong, try later !");
        // }
      } else {
        toast.error(
          "One or more field have an error. Please check and try again.!"
        );
      }

      // const submitfeedback = await submitFeedbackLandingPage(formData);

      // if (submitfeedback.status === "validation_failed") {
      //   toast.error(submitfeedback.message);
      //   if (Array.isArray(submitfeedback.invalid_fields)) {
      //     const firstName = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "first-name";
      //     });
      //     const lastName = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "last-name";
      //     });
      //     const email = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "your-email";
      //     });
      //     // const phone = submitfeedback.invalid_fields.find((item: any) => {
      //     //   return item.field === "your-mobile";
      //     // });
      //     const message = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "your-message";
      //     });
      //     if (firstName !== undefined) {
      //       setFirstnameErMsg(firstName.message);
      //     } else {
      //       setFirstnameErMsg("");
      //     }
      //     if (lastName !== undefined) {
      //       setLastnameErMsg(lastName.message);
      //     } else {
      //       setLastnameErMsg("");
      //     }
      //     if (email !== undefined) {
      //       setEmailErMsg(email.message);
      //     } else {
      //       setEmailErMsg("");
      //     }
      //     // if (phone !== undefined) {
      //     //   setPhErr(phone.message);
      //     // } else {
      //     //   setPhErr("");
      //     // }
      //   }
      // } else if (submitfeedback.status === "mail_failed") {
      //   toast.error(submitfeedback.message);
      // } else {
      //   setloadData(false);
      //   toast.success(submitfeedback.message);
      //   setfirstName("");
      //   setlastName("");
      //   setcourse("");
      //   setcompany("");
      //   setemail("");
      //   setphone("");
      //   setdesignation("");
      //   setqualification("");
      //   setlocation("");
      //   sethearFrom("");
      //   setFirstnameErMsg("");
      //   setLastnameErMsg("");
      //   setPhErr("");
      //   setEmailErMsg("");
      //   navigate("/thankyou");
      // }
      setloadData(false);
    } catch (error: any) {}
  };

  // useEffect(() => {
  //   if (Array.isArray(programData) && programData.length > 0) {
  //     const newSlugs = programData.map((elm: any) => elm.slug);
  //     setSlugs((prevSlugs) => [...prevSlugs, ...newSlugs]);
  //   }
  // }, [programData]);

  // console.log(slugs,"slugs")
  // useEffect(()=>{
  //   const x:any= id && slugs.length>0 && slugs.includes(id)
  //   console.log(x,"xx")
  //   // if(x===true){navigate("/not_found")}
  // },[id])

  // useEffect(()=>{
  //   flag === false && navigate("/not_found")
  // },[flag])
  //console.log(id,"id")

  useEffect(() => {
    // let Slugdata:any = [];
    let xxx: any =
      Array.isArray(programData) &&
      programData.length > 0 &&
      programData.map((elm: any, index) => {
        return elm.slug;
      });

    //   //console.log(id,'id');
    if (Array.isArray(xxx) && !xxx.includes(id)) {
      navigate("/not_found");
      setDisabled(false);
    } else {
      id && setcourse(id);
      setDisabled(true);
    }
  }, [programData.length > 0, id]);

  return (
    <div>
      {/* <!-- INNER BANNER --> */}

      <section
        className="inner-banner-top Enquire"
        // style={backgroundImageStyle}
      >
        {/* backgroundImage: bannerData && `url(${bannerData?.featured_image})`, */}

        <img
          src={
            bannerData && device === "Mobile"
              ? // ? bannerData?.mobile_banner
                bannerData?.featured_image
              : bannerData?.featured_image
          }
          alt=""
        />
        {/* <div className="container customwid h-100">
          <div className="row h-100">
            <div className="col-xl-12">
              <div className="gt-breadcrumb mt-3">
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      Enquire Now
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="main-content">
              <h1 className="banner__title ">Enquire for</h1>
              <p className="mt-2">ACCA Qualification</p>
              {course && <p className="mt-2">{course}</p>}
              {!course && id && params && <p className="mt-2">{params}</p>}
            </div>
          </div>
        </div> */}
      </section>

      <div className="Contact_page  sec-spacing light-bg">
        <div className="container customwid">
          <h2 className="text-center all_heading mb-4">Enquire Now</h2>
          <div className="form-contact-pg-container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="left-side-form cor_popup">
                  <form method="post" action="#" id="email-form" className="">
                    <div className="enquire-now-sideform">
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="first-name"
                            id="first-name"
                            placeholder="First Name*"
                            value={firstName}
                            onChange={(e) => setfirstName(e.target.value)}
                            required
                          />
                          {firstnameErr && (
                            <p style={{ color: "red" }}>{firstnameErr}</p>
                          )}
                          <label htmlFor="first-name">
                            First Name <span>*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="last-name"
                            id="last-name"
                            value={lastName}
                            onChange={(e) => setlastName(e.target.value)}
                            placeholder="Last Name*"
                            required
                          />
                          {lastnameErr && (
                            <p style={{ color: "red" }}>{lastnameErr}</p>
                          )}
                          <label htmlFor="last-name">
                            Last Name <span>*</span>
                          </label>
                        </div>
                      </div>

                      <div className="form-floating mb-3">
                        <select
                          className="form-select"
                          id="course-name"
                          aria-label="How Did You Hear About Us?"
                          onChange={(e) => setcourse(e.target.value)}
                          defaultValue={id && id}
                          disabled={isDisabled}
                          required
                        >
                          <option value="">Select one course</option>
                          {Array.isArray(programData) &&
                            programData.map((m: any, r: any) => (
                              <option
                                value={m.slug}
                                selected={id && id === m.slug ? true : false}
                                disabled={id && id === m.slug ? true : false}
                                key={r}
                              >
                                {m.title}
                              </option>
                            ))}
                        </select>
                        <label htmlFor="your-mobile">
                          Course<span></span>
                        </label>
                      </div>

                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            name="your-email"
                            id="your-email"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            placeholder="Email ID*"
                            required
                          />
                          {emailErr && (
                            <p style={{ color: "red" }}>{emailErr}</p>
                          )}
                          <label htmlFor="your-email">
                            Email ID <span>*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="your-company"
                            id="your-company"
                            value={company}
                            onChange={(e) => setcompany(e.target.value)}
                            placeholder="Company*"
                            required
                          />
                          {companyErr && (
                            <p style={{ color: "red" }}>{companyErr}</p>
                          )}
                          <label htmlFor="your-company">
                            Company <span>*</span>
                          </label>
                        </div>
                      </div>

                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="your-mobile"
                            name="your-mobile"
                            value={phone}
                            onChange={(e) => setphone(e.target.value)}
                            placeholder="Mobile No*"
                            required
                          />
                          {phErr && <p style={{ color: "red" }}>{phErr}</p>}
                          <label htmlFor="your-mobile">Mobile No. *</label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="your-designation"
                            name="your-designation"
                            value={designation}
                            onChange={(e) => setdesignation(e.target.value)}
                            placeholder="Designation*"
                            required
                          />
                          {designationErr && (
                            <p style={{ color: "red" }}>{designationErr}</p>
                          )}
                          <label htmlFor="your-designation">
                            Designation* <span></span>
                          </label>
                        </div>
                      </div>

                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="your-qualification"
                            name="your-qualification"
                            value={qualification}
                            onChange={(e) => setqualification(e.target.value)}
                            placeholder="Qualification*"
                            required
                          />
                          {qualificationErr && (
                            <p style={{ color: "red" }}>{qualificationErr}</p>
                          )}
                          <label htmlFor="your-designation">
                            Qualification*<span></span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="your-location"
                            name="your-location"
                            value={location}
                            onChange={(e) => setlocation(e.target.value)}
                            placeholder="Location*"
                            required
                          />
                          {locationErr && (
                            <p style={{ color: "red" }}>{locationErr}</p>
                          )}
                          <label htmlFor="your-designation">
                            Location* <span></span>
                          </label>
                        </div>
                      </div>

                      <div className="form-floating mb-3">
                        <select
                          className="form-select"
                          id="hearfrom"
                          aria-label="How Did You Hear About Us?"
                          onChange={(e) => sethearFrom(e.target.value)}
                          required
                        >
                          <option value="">How Did You Hear About Us?</option>
                          <option value="CA club">CA Club</option>
                          <option value="Google">Google</option>
                          <option value="Linkedin">Linkedin</option>
                          <option value="Email">Email</option>
                          <option value="IIM">IIM</option>
                          <option value="Naukri">Naukri</option>
                          <option value="Study at home">Study at home</option>
                          <option value="Others">Others</option>
                        </select>
                        <label htmlFor="your-mobile">
                          How Did You Hear About Us?<span></span>
                        </label>
                      </div>

                      <div className="col-lg-12 pt-3 text-end">
                        <button
                          className="btn btn-dark btn-lg py-3 px-5 w-100"
                          type="button"
                          id="submit"
                          name="submit-form"
                          onClick={handleSubmit}
                          disabled={loadData ? true : false}
                        >
                          Submit Form
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnquireSlug;
