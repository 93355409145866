import NewsLetterCTA from "../components/pagesections/Shared/NewsLetterCTA";
import AboutFeedback from "../components/pagesections/ShareFeedback/AboutFeedback";
import Banner from "../components/pagesections/ShareFeedback/Banner";
import Form from "../components/pagesections/ShareFeedback/Form";
import { useDispatch, useSelector } from "react-redux";
import HomeLoader from "../shared/HomeLoader";
import { useEffect } from "react";
import { fetchShareFeedbackData } from "../store/page";

const ShareFeedback = () => {
  const loading = useSelector(
    (state: any) => state.pageData.feedbackPageData.loading
  );
  const dispatch = useDispatch();
  const feedbackData = useSelector(
    (state: any) => state.pageData.feedbackPageData.data
  );
  useEffect(() => {
    dispatch(fetchShareFeedbackData());
  }, [dispatch]);

  return (
    <>
      {loading && (
        <section className="inner-banner-top">
          <HomeLoader />
        </section>
      )}
      {!loading && (
        <>
          <Banner bannerImg={feedbackData?.featured_image} title={feedbackData?.title}/>
          <div className="share-feedback_page sec-spacing light-bg">
            <div className="container customwid">
              <div className="form-contact-pg-container">
                <div className="row">
                  <div className="col-lg-8">
                    <Form/>
                  </div>
                  <div className="col-lg-4">
                    <AboutFeedback data={feedbackData?.description}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <NewsLetterCTA /> */}
        </>
      )}
    </>
  );
};

export default ShareFeedback;
