import React from "react";
import Image from "../../elements/Image";

type AppProps = {
  data: {
    our_approach_list: any;
    our_approach_title: any;
    title: string;
    approach: any[];
  };
};

const OurApproach = ({ data }: AppProps) => {
  return (
    <>
      <section className="approach-section sec-spacing border-bottom">
        <div className="container customwid py-3">
          {data?.our_approach_title && <h2 className="fs32 fw700 text-center mb-3">{data?.our_approach_title}</h2>}
          {data?.our_approach_list?.length > 0 && (
            <div className="approach-wrap pt-3">
              <div className="row">
                {data?.our_approach_list?.map((item: any) => (
                  <div className="col-md-3" key={item._id}>
                    <div className="approach-block">
                      {item?.image && (
                        <figure>
                          <Image src={item?.image} alt={item?.title} width={"100%"} height={"100%"} effect={"blur"} classes={"img-fluid"} />
                        </figure>
                      )}
                      {item?.content && <div className="approach-content" dangerouslySetInnerHTML={{ __html: item?.content }}></div>}
                      <div className="d-block d-md-none py-3 red-light fs24">
                        <Image src={window.location.origin + "/images/drop-icon.png"} alt={""} width={"40"} height={"auto"} effect={"blur"} classes={"img-fluid"} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default OurApproach;
