import { url } from "inspector";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { submitFeedbackLandingPage } from "../actions/feedback";
import { toast } from "react-toastify";
import axios from "axios";
import BASE_URL from "../config/host";

const Enquire = () => {
  const [loadData, setloadData] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [course, setcourse] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [company, setcompany] = useState("");
  const [designation, setdesignation] = useState("");
  const [qualification, setqualification] = useState("");
  const [location, setlocation] = useState("");
  const [hearFrom, sethearFrom] = useState("");

  const [firstnameErr, setFirstnameErMsg] = useState("");
  const [lastnameErr, setLastnameErMsg] = useState("");
  const [emailErr, setEmailErMsg] = useState("");
  const [phErr, setPhErr] = useState("");
  const navigate = useNavigate();
  const [bannerData, setbannerData] = useState<any>(null);
  const { id } = useParams();

  const params =
    id &&
    id.replace(/-/g, " ").replace(/\b\w/g, (match) => match.toUpperCase());

  // const backgroundImageStyle = {
  //   backgroundImage: bannerData ? `url(${bannerData?.featured_image})` :
  //     "url(https://rwtpl.com/demov/gt/wp-content/uploads/2023/01/bg-program.jpg)",
  // };
  const backgroundImageStyle = {
    backgroundImage: bannerData && `url(${bannerData?.featured_image})`,
  };

  const programData = useSelector(
    (state: any) => state.programData.programData
  );

  useEffect(() => {
    axios
      .get(BASE_URL + "/index.php/wp-json/page/id?page_id=2401")
      .then((response) => {
        setbannerData(response.data.data);
      })
      .catch((err) => console.log(err, "err"));
  }, []);

  const handleSubmit = async () => {
    setloadData(true);
    const formData1 = {
      "first-name": firstName,
      "last-name": lastName,
      "course-name": course,
      "your-email": email,
      "company-name": company,
      "your-mobile": phone,
      designation: designation,
      qualification: qualification,
      location: location,
      "hear-from": hearFrom,
    };

    const formData2 = {
      FirstName: firstName,
      LastName: lastName,
      Course: course,
      Email: email,
      Textb1: company,
      MobileNumber: phone,
      Textb2: designation,
      Textb3: qualification,
      Field5: location,
      Field4: hearFrom,
    };

    try {
      if (!firstName) {
        toast.error("Please enter your first name");
      } else if (!lastName) {
        toast.error("Please enter your last name");
      } else if (!course) {
        toast.error("Please select your course");
      } else if (!email) {
        toast.error("Please enter your email");
      } else if (!company) {
        toast.error("Please enter your company name");
      } else if (!phone) {
        toast.error("Please enter your phone number");
      } else if (!designation) {
        toast.error("Please enter your designation");
      } else if (!qualification) {
        toast.error("Please enter your qualification");
      } else if (!location) {
        toast.error("Please enter your location");
      } else if (!hearFrom) {
        toast.error("Please select your hear from");
      } else {
        const submitfeedback = await submitFeedbackLandingPage({
          formData1,
          formData2,
        }).then((res) => {
          toast.success("Thank you for your message. It has been sent.");
          navigate("/thankyou");
        });
      }

      // if (submitfeedback.status === "validation_failed") {
      //   toast.error(submitfeedback.message);
      //   if (Array.isArray(submitfeedback.invalid_fields)) {
      //     const firstName = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "first-name";
      //     });
      //     const lastName = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "last-name";
      //     });
      //     const email = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "your-email";
      //     });
      //     const phone = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "your-mobile";
      //     });
      //     const message = submitfeedback.invalid_fields.find((item: any) => {
      //       return item.field === "your-message";
      //     });
      //     if (firstName !== undefined) {
      //       setFirstnameErMsg(firstName.message);
      //     } else {
      //       setFirstnameErMsg("");
      //     }
      //     if (lastName !== undefined) {
      //       setLastnameErMsg(lastName.message);
      //     } else {
      //       setLastnameErMsg("");
      //     }
      //     if (email !== undefined) {
      //       setEmailErMsg(email.message);
      //     } else {
      //       setEmailErMsg("");
      //     }
      //     if (phone !== undefined) {
      //       setPhErr(phone.message);
      //     } else {
      //       setPhErr("");
      //     }
      //   }
      // }
      //  else if (submitfeedback.status === "mail_failed") {
      //   toast.error(submitfeedback.message);
      // }
      // else {
      //   setloadData(false);
      //   toast.success(submitfeedback.message);
      //   setfirstName("");
      //   setlastName("");
      //   setcourse("");
      //   setcompany("");
      //   setemail("");
      //   setphone("");
      //   setdesignation("");
      //   setqualification("");
      //   setlocation("");
      //   sethearFrom("");
      //   setFirstnameErMsg("");
      //   setLastnameErMsg("");
      //   setPhErr("");
      //   setEmailErMsg("");
      //   navigate("/thankyou");
      // }
      setloadData(false);
    } catch (error: any) {}
  };

  let xxx: any =
    Array.isArray(programData) &&
    programData.length > 0 &&
    programData.map((elm: any, index) =>
      id && id === elm?.slug ? true : false
    );

  return (
    <div>
      {/* <!-- INNER BANNER --> */}

      <section
        className="inner-banner-top Enquire"
        style={backgroundImageStyle}
      >
        {/* <div className="container customwid h-100">
          <div className="row h-100">
            <div className="col-xl-12">
              <div className="gt-breadcrumb mt-3">
                <div aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      Enquire Now
                    </li>
                  </ol>
                </div>
              </div>
            </div>
            <div className="main-content">
              <h1 className="banner__title ">Enquire for</h1>
              <p className="mt-2">ACCA Qualification</p> 
              {course && <p className="mt-2">{course}</p>}
              {!course && id && params && <p className="mt-2">{params}</p>}
            </div>
          </div>
        </div> */}
      </section>

      <div className="Contact_page  sec-spacing light-bg">
        <div className="container customwid">
          <h2 className="text-center all_heading mb-4">Enquire Now</h2>
          <div className="form-contact-pg-container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="left-side-form cor_popup">
                  {/* <form
                    method="post"
                    action="#"
                    id="email-form"
                    className="pt-3"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12 form-group">
                        <input
                          type="text"
                          name="username"
                          className="form-control"
                          placeholder="First Name*"
                          required
                          onChange={(e)=>setfirstName(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 form-group">
                        <input
                          type="text"
                          name="username"
                          className="form-control"
                          placeholder="Last Name*"
                          required
                          onChange={(e)=>setlastName(e.target.value)}
                          
                        />
                      </div>

                      <div className="col-lg-6 col-md-12 col-12 form-group">
                        <select
                          className="form-select"
                          aria-label=".form-select-lg example"
                          onChange={(e)=>setcourse(e.target.value)}
                        >
                          <option value="">Select one Cource</option>
                          {Array.isArray(programData) &&
                            programData.map((m: any, r: any) => (
                              <option
                                value={m.title}
                                selected={
                                  id && id === m.slug
                                    ? true
                                    : false
                                }
                               disabled={ id && id === m.slug ? true : false}
                               key={r}
                              >
                                {m.title}
                              </option>
                              // <option value={m.slug} key={r}>{m.title}</option>
                              // id && id === m.slug ? <option style={{fontWeight:"600",color:"black"}} value={m.slug} key={r}>{m.title}</option> : <option disabled value={m.slug} key={r}>{m.title}</option>
                            ))}
                        </select>
                      </div>

                      <div className="col-lg-6 col-md-12 col-12 form-group">
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Email*"
                          required
                          onChange={(e)=>setemail(e.target.value)}

                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 form-group">
                        <input
                          type="tel"
                          className="form-control"
                          id="mobile"
                          placeholder="Phone No.*"
                          onChange={(e)=>setphone(e.target.value)}

                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 form-group">
                        <input
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder="Company*"
                          onChange={(e)=>setcompany(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 form-group">
                        <input
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder="Designation"
                          onChange={(e)=>setdesignation(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 form-group">
                        <input
                          type="text"
                          name="subject"
                          className="form-control"
                          placeholder="Qualification"
                          onChange={(e)=>setqualification(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 form-group">
                        <select
                          className="form-select"
                          aria-label=".form-select-lg example"
                          onChange={(e)=>setlocation(e.target.value)}
                        >
                          <option value=""> Location </option>
                          <option value="Delhi">Delhi</option>
                          <option value="Mumbai">Mumbai</option>
                          <option value="Bengaluru">Bengaluru</option>
                          <option value="Hyderabad">Hyderabad</option>
                          <option value="Chennai">Chennai</option>
                          <option value="Pune">Pune</option>
                          <option value="Rest of India">Rest of India</option>
                        </select>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12 form-group">
                        <select
                          className="form-select"
                          aria-label=".form-select-lg example"
                          onChange={(e)=>sethearFrom(e.target.value)}
                        >
                          <option value=""> hearFrom did you hear about us?</option>
                          <option value="Google">Google</option>
                          <option value="Email">Email</option>
                          <option value="LinkedIn">LinkedIn</option>
                          <option value="Twitter">Twitter</option>
                          <option value="CAClub">CAClub</option>
                          <option value="Study at Home">Study at Home</option>
                          <option value="IIM Jobs Website">
                            IIM Jobs Website
                          </option>
                          <option value="Naukri Website">Naukri Website</option>
                          <option value="Reference">Reference</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>

                      <div className="col-lg-6 col-md-12 col-12 text-center  ">
                        <button
                          className="btn btn-dark btn-lg py-3 px-5 w-100"
                          type="button"
                          id="submit"
                          name="submit-form"
                          onClick={handleSubmit}
                        >
                          Submit Form
                        </button>
                      </div>
                    </div>
                  </form> */}
                  <form method="post" action="#" id="email-form" className="">
                    <div className="enquire-now-sideform">
                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="first-name"
                            id="first-name"
                            placeholder="First Name*"
                            value={firstName}
                            onChange={(e) => setfirstName(e.target.value)}
                            required
                          />
                          {firstnameErr && (
                            <p style={{ color: "red" }}>{firstnameErr}</p>
                          )}
                          <label htmlFor="first-name">
                            First Name <span>*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="last-name"
                            id="last-name"
                            value={lastName}
                            onChange={(e) => setlastName(e.target.value)}
                            placeholder="Last Name*"
                            required
                          />
                          {lastnameErr && (
                            <p style={{ color: "red" }}>{lastnameErr}</p>
                          )}
                          <label htmlFor="last-name">
                            Last Name <span>*</span>
                          </label>
                        </div>
                      </div>

                      <div className="form-floating mb-3">
                        <select
                          className="form-select"
                          id="course-name"
                          aria-label="How Did You Hear About Us?"
                          onChange={(e) => setcourse(e.target.value)}
                          defaultValue={id && id}
                          disabled={xxx[0]}
                          required
                        >
                          <option value="">Select one course</option>
                          {Array.isArray(programData) &&
                            programData.map((m: any, r: any) => (
                              <option
                                value={m.slug}
                                selected={id && id === m.slug ? true : false}
                                disabled={id && id === m.slug ? true : false}
                                key={r}
                              >
                                {m.title}
                              </option>
                            ))}
                        </select>
                        <label htmlFor="your-mobile">
                          Course<span></span>
                        </label>
                      </div>

                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="form-floating mb-3">
                          <input
                            type="email"
                            className="form-control"
                            name="your-email"
                            id="your-email"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                            placeholder="Email ID*"
                            required
                          />
                          {emailErr && (
                            <p style={{ color: "red" }}>{emailErr}</p>
                          )}
                          <label htmlFor="your-email">
                            Email ID <span>*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="your-company"
                            id="your-company"
                            value={company}
                            onChange={(e) => setcompany(e.target.value)}
                            placeholder="Company*"
                            required
                          />
                          {emailErr && (
                            <p style={{ color: "red" }}>{emailErr}</p>
                          )}
                          <label htmlFor="your-company">
                            Company <span>*</span>
                          </label>
                        </div>
                      </div>

                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="form-floating mb-3">
                          <input
                            type="number"
                            className="form-control"
                            id="your-mobile"
                            name="your-mobile"
                            value={phone}
                            onChange={(e) => setphone(e.target.value)}
                            placeholder="Mobile No*"
                            required
                          />
                          {phErr && <p style={{ color: "red" }}>{phErr}</p>}
                          <label htmlFor="your-mobile">
                            Mobile No. <span>*</span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="your-designation"
                            name="your-designation"
                            value={designation}
                            onChange={(e) => setdesignation(e.target.value)}
                            placeholder="Designation*"
                            required
                          />
                          {phErr && <p style={{ color: "red" }}>{phErr}</p>}
                          <label htmlFor="your-designation">
                            Designation. <span></span>
                          </label>
                        </div>
                      </div>

                      <div style={{ display: "flex", gap: "5px" }}>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="your-qualification"
                            name="your-qualification"
                            value={qualification}
                            onChange={(e) => setqualification(e.target.value)}
                            placeholder="Qualification*"
                            required
                          />
                          {phErr && <p style={{ color: "red" }}>{phErr}</p>}
                          <label htmlFor="your-designation">
                            Qualification. <span></span>
                          </label>
                        </div>
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control"
                            id="your-location"
                            name="your-location"
                            value={location}
                            onChange={(e) => setlocation(e.target.value)}
                            placeholder="Location*"
                            required
                          />
                          {phErr && <p style={{ color: "red" }}>{phErr}</p>}
                          <label htmlFor="your-designation">
                            Location. <span></span>
                          </label>
                        </div>
                      </div>

                      <div className="form-floating mb-3">
                        <select
                          className="form-select"
                          id="hearfrom"
                          aria-label="How Did You Hear About Us?"
                          onChange={(e) => sethearFrom(e.target.value)}
                          required
                        >
                          <option value="">How Did You Hear About Us?</option>
                          <option value="CA club">CA Club</option>
                          <option value="Google">Google</option>
                          <option value="Linkedin">Linkedin</option>
                          <option value="Email">Email</option>
                          <option value="IIM">IIM</option>
                          <option value="Naukri">Naukri</option>
                          <option value="Study at home">Study at home</option>
                          <option value="Others">Others</option>
                        </select>
                        <label htmlFor="your-mobile">
                          How Did You Hear About Us?<span></span>
                        </label>
                      </div>

                      <div className="col-lg-12 pt-3 text-end">
                        <button
                          className="btn btn-dark btn-lg py-3 px-5 w-100"
                          type="button"
                          id="submit"
                          name="submit-form"
                          onClick={handleSubmit}
                          disabled={loadData ? true : false}
                        >
                          Submit Form
                        </button>
                      </div>
                    </div>
                    {/* <div className="form-group">
                    <label> Last Name*</label>
                    <input
                      type="text"
                      name="last-name"
                      className="form-control"
                      placeholder="Enter Last Name"
                      id="last-name"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                      required
                    />
                    {lastnameErr && (
                      <p style={{ color: "red" }}>{lastnameErr}</p>
                    )}
                  </div>
                  <div className=" form-group">
                    <label>Email ID*</label>
                    <input
                      type="email"
                      name="your-email"
                      id="your-email"
                      className="form-control"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {emailErr && <p style={{ color: "red" }}>{emailErr}</p>}
                  </div>
                  <div className=" form-group">
                    <label>Phone No.*</label>
                    <input
                      type="tel"
                      className="form-control"
                      id="your-mobile"
                      name="your-mobile"
                      placeholder="Enter your phone no."
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    {phErr && <p style={{ color: "red" }}>{phErr}</p>}
                  </div>
                  <div className=" form-group">
                    <label>Enquiry</label>
                    <input
                      type="text"
                      id="your-message"
                      name="your-message"
                      className="form-control"
                      placeholder="Enter your Enquiry"
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                    />
                    {msgEr && <p style={{ color: "red" }}>{msgEr}</p>}
                  </div>
                  <div className="mt-4 text-center">
                    <button
                      className="btn btn-dark btn-lg py-3 px-5 w-100"
                      type="button"
                      id="submit"
                      name="submit-form"
                      data-bs-dismiss="modal"
                      onClick={() => feedbackForm()}
                    >
                      {loadData && <i className="fa fa-sync fa-spin"></i>}&nbsp;
                      SUBMIT DETAILS
                    </button>
                  </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquire;
