import React from "react";
import Image from "../../elements/Image";
import { Link, NavLink } from "react-router-dom";
import CountUp from "react-countup";
type AppProps = {
  data: any;
};
const AboutUs = ({ data }: AppProps) => {

  return (
    <>
      <section className="About_Grant-H sec-spacing">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto text-center ">
              <h2 className="all_heading text-white">{data?.about_heading}</h2>
              <p className="text-white-50 about-subtitle">{data?.about_text}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mx-auto col-12">
              <div className="row">
                <div className="col-lg-3 col-6 mb-4">
                  <div className="stats-info-block text-center position-relative">
                    <span className="stats-icon">
                      <Image
                        src={data?.country_icon}
                        alt={"Countries"}
                        width={"66"}
                        height={"59"}
                        effect={"blur"}
                      />
                    </span>
                    <div className="stat-number text-white">
                      <span className="counter">{data?.countries_number} </span>
                      {/* <span className="counter">
                       <CountUp
                          decimals={1}
                          decimal="."
                          start={0}
                          end={ Array.isArray(data) && data.length>0 && data?.countries_number.split("/")[0]}
                        />
                        /{data && data?.countries_number.split("/")[1]}
                      </span> */}
                    </div>
                    <div className="stats-info-title text-white text-uppercase">
                      {data?.rating_title}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-6 mb-4">
                  <div className="stats-info-block text-center position-relative">
                    <span className="stats-icon">
                      <Image
                        src={data?.revenue_icon}
                        alt={"Revenue"}
                        width={"66"}
                        height={"59"}
                        effect={"blur"}
                      />
                    </span>
                    <div className="stat-number text-white">
                      <span className="stat-big-text"></span>
                      {/* <span className="counter">{data?.revenue_number}</span> */}
                      <span className="counter">
                        {" "}
                        <CountUp
                          start={0}
                          end={parseFloat(data?.revenue_number)}
                        />
                        +
                      </span>
                      {data?.revenue_text}
                    </div>
                    <div className="stats-info-title text-white text-uppercase">
                      {data?.revenue_title}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-6 mb-4">
                  <div className="stats-info-block text-center position-relative">
                    <span className="stats-icon">
                      <Image
                        src={data?.people_icon}
                        alt={"People"}
                        width={"66"}
                        height={"59"}
                        effect={"blur"}
                      />
                    </span>
                    <div className="stat-number text-white">
                      {/* <span className="counter">{data?.people_number}</span> */}
                      <span className="counter">
                        {" "}
                        <CountUp
                          start={0}
                          end={parseFloat(data?.people_number)}
                        />
                      </span>
                      <span className="stat-big-text">
                        {data?.people_letter}
                      </span>
                    </div>
                    <div className="stats-info-title text-white text-uppercase">
                      {data?.people_title}
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-6 mb-4">
                  <div className="stats-info-block text-center position-relative">
                    <span className="stats-icon">
                      <Image
                        src={data?.learning_hours_icon}
                        alt={"Learning"}
                        width={"66"}
                        height={"59"}
                        effect={"blur"}
                      />
                    </span>
                    <div className="stat-number text-white">
                      <span className="stat-big-text"></span>
                      {/* <span className="counter">{data?.learning_hours}</span> */}
                      <span className="counter">
                        {" "}
                        <CountUp
                          start={0}
                          end={parseFloat(data?.learning_hours)}
                        />
                        +
                      </span>
                    </div>
                    <div className="stats-info-title text-white text-uppercase">
                      {data?.learning_hours_title}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="Learning_Development dark-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-11 mx-auto text-center">
              <div className="Learn_Dev-block foldtr">
                <h2 className="all_heading text-white">{data?.ld_heading}</h2>
                <p className="text-white-90">{data?.ld_text}</p>
                <a href={data?.ld_link} className="btn btn-light btn-sm learn-bt ps-5 pe-5 fw-semibold">EXPLORE MORE</a>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default AboutUs;
