import { Helmet } from "react-helmet";

const SeoComponent = ({ title, description, keyword }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keyword} />
    </Helmet>
  );
};

export default SeoComponent;
