import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewsLetterCTA from "../components/pagesections/Shared/NewsLetterCTA";
import Banner from "../components/pagesections/Testimonials/Banner";
import TestimonialBlock from "../components/pagesections/Testimonials/TestimonialBlock";
import HomeLoader from "../shared/HomeLoader";
import { fetchTestimonialData } from "../store/page";
import SeoComponent from "../components/elements/SeoComponent";

const Testimonials = () => {
  const dispatch = useDispatch();
  const testimonialData = useSelector(
    (state: any) => state.pageData.testimonialPageData.data
  );
  const loading = useSelector(
    (state: any) => state.pageData.testimonialPageData.loading
  );

  useEffect(() => {
    dispatch(fetchTestimonialData());
  }, [dispatch]);

  return (
    <>
      {loading && (
        <section className="inner-banner-top">
          <HomeLoader />
        </section>
      )}
      {!loading && (
        <>
         {testimonialData && testimonialData?.seo_data && (
            <SeoComponent
              title={testimonialData?.seo_data?.meta_title}
              description={testimonialData?.seo_data?.meta_description}
              keyword={testimonialData?.seo_data?.meta_keyword}
            />
          )}
          <Banner />
          <TestimonialBlock testimonialsData={testimonialData} />
          {/* <NewsLetterCTA /> */}
        </>
      )}
    </>
  );
};

export default Testimonials;
