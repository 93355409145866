import React from "react";
import Image from "../../elements/Image";
import { Link, NavLink } from "react-router-dom";
type AppProps = {
  data: any;
};
const Vision = ({ data }: AppProps) => {
  return (
    <>
      <section className="Vibrant_Bharat position-relative overflow-hidden">
        {Array.isArray(data.vibrant_bharat_images) &&
          data.vibrant_bharat_images.length > 0 && (
            <figure className="VB-Image-Container">
              <Image
                src={data && data?.vibrant_bharat_images[0]?.v_image}
                alt={""}
                width={"100%"}
                height={"100%"}
                effect={"blur"}
                classes={"VB-img"}
              />
            </figure>
          )}
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="VB-block">
                <h5 className="text-white"> {data?.vibrant_bharat_heading}</h5>
                <div className="d-flex justify-content-sm-start">
                  <a
                    href={data?.vibrant_bharat_link}
                    className="btn-exp btn-lg btn"
                  >
                    Learn More
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Vision;
