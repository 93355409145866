// import { Link } from 'react-router-dom';

const Content = ({ refundData }: any) => {
 
  return (
    <section className='content-wrapper bg-light'>
      <div className='container'
        dangerouslySetInnerHTML={{ __html: refundData?.description }}
      />
      
    </section>
  );
};

export default Content;
