import React from 'react'

type AppProps = {
    icons: string,
    info: string,
    infoType: string,
    type: string,
}

const ContactInfoItem = ({ contactData }: any) => {
    return (
        <div>
            <div className="contact-info-block">
                {/* <span className="icon flex-shrink-0 text-prime me-4"><i className={icons}></i></span> */}
                <div className="info-content flex-grow-1">
                    {/* <h6 className="fs15 text-white">{infoType}</h6> */}
                    {
                     <p className="fs13"><a href={`mailto:${contactData?.email}`} target="_blank">Email : {contactData?.email}</a></p>
                    }
                    {
                        <p className="fs13"><a href={`tel:${contactData?.mobile}`} target="_blank">Phone : {contactData?.mobile}</a></p>
                    }
                    {
                        <p className="fs13">Location : {contactData?.location}</p>
                    }
                    {
                        <p className="fs13"><a href={`https://${contactData?.website}`} target="_blank">Website : {contactData?.website}</a></p>
                    }
                </div>
            </div>
        </div>
    )
}

export default ContactInfoItem