import React from 'react'

interface Props {who_can_pursue_title:any,who_can_pursue_content:any,who_can_pursue:any}

function PursueCma({who_can_pursue_title,who_can_pursue_content,who_can_pursue}: Props) {
    

    return (
        <><section className="pursue-CMA-section py-3 section-content section-bt bg-light-purple-1 program-Outcomes">
        <div className="container customwid ">
          <div className="text-center section-heading-lg mb-4">
            <h4>{who_can_pursue_title}</h4>
          </div>
          <div className="card text-center card-style shadow-sm">
            <div className="card-body">
              <h5 className="text-dark-purple mb-3 fs21 mt-4">{who_can_pursue_content}</h5>
              <div className="tags-wrap mb-5"> 
              {Array.isArray(who_can_pursue) && who_can_pursue.map((item:any)=>{
                return (<><span className="tag">{item?.course_name}</span></>)
              })}
              
               </div>
            </div>
          </div>
        </div>
      </section></>
    )
}

export default PursueCma
