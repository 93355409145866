import React from 'react'

interface Props {
    acca_with_title:any,
    acca_with_image:any,
    acca_with_content:any
}

function AccaWithGTB({acca_with_title,acca_with_image,acca_with_content}: Props) {
    function createMarkup() {
        return {__html: acca_with_content }
      }
    return (
        <>
        <section className="roles-section GPB py-3 section-content section-bt" id=" ">
  <div className="container customwid ">
    <div className="text-center section-heading-lg mb-4">
      <h4>{acca_with_title}</h4>
    </div>
    <div className="row pt-2 d-flex justify-content-between align-items-center">
      <div className="col-md-6 col-12">
        <figure><img src={acca_with_image} className="w-100"/> </figure>
      </div>
      <div className="col-md-5 col-12 should_attend-Ul Roles">
      <div dangerouslySetInnerHTML={createMarkup()}></div> 
      </div>
    </div>
  </div>
</section>
        </>
    )
}

export default AccaWithGTB
