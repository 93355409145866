import React, {useState} from 'react';

type AppProps = {
  title: string;
  text: string;
};

const FaqItem = ({title, text} : AppProps) => {
  const [isOpen, setIsOpen] = useState(false);
  function createMarkup() {
    return {__html: text }
  }
  return (
    <>
      <div className={"faq-block rounded " + (isOpen?'isOpen':'')}>
        <div className="faq-header" onClick={() => setIsOpen(!isOpen)}>{title}</div>
        <div className="faq-content">
          <div className="faq-text should_attend-Ul" dangerouslySetInnerHTML={createMarkup()}></div>
        </div>
      </div>
    </>
  );
};

export default FaqItem;