import SkillItem from '../../items/SkillItem';

type AppProps = {
  skillData: any[];
  programData: any;
};

const Skills = ({ skillData, programData }: AppProps) => {
 
  return (
    <>
     <section className="new-Skills-section py-3 section-content section-bt" id="skills">
  <div className="container customwid ">
    <div className="text-center section-heading-lg mb-3">
      <h4>{programData?.skills_covered_heading}</h4>
      <p className="fs18">{programData?.skills_covered_text}</p>
    </div>
    <div className="row">
      {
        Array.isArray(programData.skills_covered) && programData.skills_covered.map((item:any)=>{
          function createMarkup() {
            return {__html: item.text }
          }
          return (<>
            <div className="col-md-6 col-12">
           <div className="course-cma  rounded mb-3">
             <div className="text-center py-3  Part-hdng">{item.heading} </div>
             <div className="program-Outcomes Skills-texts p-3">
             <div dangerouslySetInnerHTML={createMarkup()}></div>
             </div>
             <div className="clearfix"></div>
           </div>
         </div></>)
        })
      }
     
      
    </div>
  </div>
</section>
    </>
  );
};

export default Skills;
