import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import BASE_URL from "../config/host";

export const pageSlice = createSlice({
  name: "pageData",
  initialState: {
    loading: false,
    privacyPageData: {
      loading: false,
      data: {},
    },
    refundPageData: {
      loading: false,
      data: {},
    },
    cookiePageData: {
      loading: false,
      data: {},
    },
    termPageData: {
      loading: false,
      data: {},
    },
    aboutPageData: {
      loading: false,
      data: {},
    },
    corporatePageData: {
      loading: false,
      data: {},
    },
    faqPageData: {
      loading: false,
      data: {},
    },
    leaderPageData: {
      loading: false,
      data: {},
    },
    feedbackPageData: {
      loading: false,
      data: {},
    },
    testimonialPageData: {
      loading: false,
      data: {},
    },
    contactPageData: {
      loading: false,
      data: {},
    },
    landingPageData: {
      courseUrl: null,
      loading: false,
      data: {},
    },
  },
  reducers: {
    privacyDataRequested: (state, action) => {
      state.privacyPageData = {
        ...state.privacyPageData,
        loading: true,
      };
    },
    privacyDataRequestFailed: (state, action) => {
      state.privacyPageData = {
        loading: false,
        data: {},
      };
    },
    privacyDataReceived: (state, action) => {
      state.privacyPageData = {
        loading: false,
        data: action.payload.data,
      };
    },

    refundDataRequested: (state, action) => {
      state.refundPageData = {
        ...state.refundPageData,
        loading: true,
      };
    },
    refundDataRequestFailed: (state, action) => {
      state.refundPageData = {
        loading: false,
        data: {},
      };
    },
    refundDataReceived: (state, action) => {
      state.refundPageData = {
        loading: false,
        data: action.payload.data,
      };
    },

    cookieDataRequested: (state, action) => {
      state.cookiePageData = {
        ...state.cookiePageData,
        loading: true,
      };
    },
    cookieDataRequestFailed: (state, action) => {
      state.cookiePageData = {
        loading: false,
        data: {},
      };
    },
    cookieDataReceived: (state, action) => {
      state.cookiePageData = {
        loading: false,
        data: action.payload.data,
      };
    },
    termDataRequested: (state, action) => {
      state.termPageData = {
        ...state.termPageData,
        loading: true,
      };
    },
    termDataRequestFailed: (state, action) => {
      state.termPageData = {
        loading: false,
        data: {},
      };
    },
    termDataReceived: (state, action) => {
      state.termPageData = {
        loading: false,
        data: action.payload.data,
      };
    },
    aboutDataRequested: (state, action) => {
      state.aboutPageData = {
        ...state.aboutPageData,
        loading: true,
      };
    },
    aboutDataRequestFailed: (state, action) => {
      state.aboutPageData = {
        loading: false,
        data: {},
      };
    },
    aboutDataReceived: (state, action) => {
      state.aboutPageData = {
        loading: false,
        data: action.payload.data,
      };
    },
    faqDataRequested: (state, action) => {
      state.faqPageData = {
        ...state.faqPageData,
        loading: true,
      };
    },
    faqDataRequestFailed: (state, action) => {
      state.faqPageData = {
        loading: false,
        data: {},
      };
    },
    faqDataReceived: (state, action) => {
      state.faqPageData = {
        loading: false,
        data: action.payload.data,
      };
    },
    corporateDataRequested: (state, action) => {
      state.corporatePageData = {
        ...state.corporatePageData,
        loading: true,
      };
    },
    corporateDataRequestFailed: (state, action) => {
      state.corporatePageData = {
        loading: false,
        data: {},
      };
    },
    corporateDataReceived: (state, action) => {
      state.corporatePageData = {
        loading: false,
        data: action.payload.data,
      };
    },
    leaderDataRequested: (state, action) => {
      state.leaderPageData = {
        ...state.leaderPageData,
        loading: true,
      };
    },
    leaderDataRequestFailed: (state, action) => {
      state.leaderPageData = {
        loading: false,
        data: {},
      };
    },
    leaderDataReceived: (state, action) => {
      state.leaderPageData = {
        loading: false,
        data: action.payload.data,
      };
    },
    feedbackDataRequested: (state, action) => {
      state.feedbackPageData = {
        ...state.feedbackPageData,
        loading: true,
      };
    },
    feedbackDataRequestFailed: (state, action) => {
      state.feedbackPageData = {
        loading: false,
        data: {},
      };
    },
    feedbackDataReceived: (state, action) => {
      state.feedbackPageData = {
        loading: false,
        data: action.payload.data,
      };
    },
    TestimonialDataRequested: (state, action) => {
      state.testimonialPageData = {
        ...state.testimonialPageData,
        loading: true,
      };
    },
    TestimonialDataRequestFailed: (state, action) => {
      state.testimonialPageData = {
        loading: false,
        data: {},
      };
    },
    TestimonialDataReceived: (state, action) => {
      state.testimonialPageData = {
        loading: false,
        data: action.payload.data,
      };
    },

    contactDataRequested: (state, action) => {
      state.contactPageData = {
        ...state.contactPageData,
        loading: true,
      };
    },
    contactDataRequestFailed: (state, action) => {
      state.contactPageData = {
        loading: false,
        data: {},
      };
    },
    contactDataReceived: (state, action) => {
      state.contactPageData = {
        loading: false,
        data: action.payload.data,
      };
    },
    landingPageDataRequested: (state, action) => {
      state.landingPageData = {
        loading: true,
        data: action.payload,
        courseUrl: null,
      };
    },
    landingPageDataReceived: (state, action) => {
      state.landingPageData = {
        loading: false,
        data: action.payload,
        courseUrl: action.payload.data.data.course_url,
      };
    },
    landingPageDataRequestFailed: (state, action) => {
      state.landingPageData = {
        loading: false,
        data: action.payload,
        courseUrl: null,
      };
    },
    landingPageFDDataRequested: (state, action) => {
      state.landingPageData = {
        loading: true,
        data: action.payload,
        courseUrl: null,
      };
    },
    landingFDPageDataReceived: (state, action) => {
      state.landingPageData = {
        loading: false,
        data: action.payload,
        courseUrl: action.payload.data.data.course_url,
      };
    },
    landingPageFDDataRequestFailed: (state, action) => {
      state.landingPageData = {
        loading: false,
        data: action.payload,
        courseUrl: null,
      };
    },
  },
});

export const {
  privacyDataReceived,
  privacyDataRequested,
  privacyDataRequestFailed,
  refundDataReceived,
  refundDataRequested,
  refundDataRequestFailed,
  cookieDataRequested,
  cookieDataReceived,
  cookieDataRequestFailed,
  termDataRequested,
  termDataReceived,
  termDataRequestFailed,
  aboutDataReceived,
  aboutDataRequested,
  aboutDataRequestFailed,
  corporateDataReceived,
  corporateDataRequested,
  corporateDataRequestFailed,
  faqDataReceived,
  faqDataRequested,
  faqDataRequestFailed,
  leaderDataReceived,
  leaderDataRequested,
  leaderDataRequestFailed,
  feedbackDataReceived,
  feedbackDataRequested,
  feedbackDataRequestFailed,
  TestimonialDataReceived,
  TestimonialDataRequested,
  TestimonialDataRequestFailed,
  contactDataReceived,
  contactDataRequested,
  contactDataRequestFailed,
  landingPageDataRequested,
  landingPageDataReceived,
  landingPageDataRequestFailed,
  landingPageFDDataRequested,
  landingFDPageDataReceived,
  landingPageFDDataRequestFailed,
} = pageSlice.actions;

export const fetchPrivacyData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/page/id?page_id=211",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: privacyDataReceived.type,
    onStart: privacyDataRequested.type,
    onError: privacyDataRequestFailed.type,
  });

export const fetchContactData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/page/id?page_id=418",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: contactDataReceived.type,
    onStart: contactDataRequested.type,
    onError: contactDataRequestFailed.type,
  });

export const fetchRefundData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/page/id?page_id=204",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: refundDataReceived.type,
    onStart: refundDataRequested.type,
    onError: refundDataRequestFailed.type,
  });

export const fetchCookieData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/page/id?page_id=400",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: cookieDataReceived.type,
    onStart: cookieDataRequested.type,
    onError: cookieDataRequestFailed.type,
  });

export const fetchTermData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/page/id?page_id=206",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: termDataReceived.type,
    onStart: termDataRequested.type,
    onError: termDataRequestFailed.type,
  });

export const fetchAboutData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/page/about_us",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: aboutDataReceived.type,
    onStart: aboutDataRequested.type,
    onError: aboutDataRequestFailed.type,
  });

export const fetchFaqData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/page/faq",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: faqDataReceived.type,
    onStart: faqDataRequested.type,
    onError: faqDataRequestFailed.type,
  });

export const fetchCorporateData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/page/corporate_training",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: corporateDataReceived.type,
    onStart: corporateDataRequested.type,
    onError: corporateDataRequestFailed.type,
  });

export const fetchLeaderData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/thought_leadership/list",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: leaderDataReceived.type,
    onStart: leaderDataRequested.type,
    onError: leaderDataRequestFailed.type,
  });

export const fetchShareFeedbackData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/page/id?page_id=414",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: feedbackDataReceived.type,
    onStart: feedbackDataRequested.type,
    onError: feedbackDataRequestFailed.type,
  });

export const fetchTestimonialData = () =>
  apiCallBegan({
    url: "/index.php/wp-json/testimonials/list",
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: TestimonialDataReceived.type,
    onStart: TestimonialDataRequested.type,
    onError: TestimonialDataRequestFailed.type,
  });

export const fetchLandingPageDetails = (slug: any) =>
  apiCallBegan({
    url: `/index.php/wp-json/landing_page/detail?slug=${slug}`,
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: landingPageDataReceived.type,
    onStart: landingPageDataRequested.type,
    onError: landingPageDataRequestFailed.type,
  });

  export const fetchLandingFDDetails = (slug: any) =>
  apiCallBegan({
    url: `/index.php/wp-json/landing_fd/detail?slug=${slug}`,
    method: "get",
    data: {},
    baseUrl: BASE_URL,
    onSuccess: landingFDPageDataReceived.type,
    onStart: landingPageFDDataRequested.type,
    onError: landingPageFDDataRequestFailed.type,
  });
