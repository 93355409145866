import CurriculamItem from "../../items/CurriculamItem";
import CurriculumItem from "../../items/CurriculumItem";
import React, { useState } from "react";

type AppProps = {
  curriculumData: any[];
  heading_about_this_course: any;
};

const Curriculum = ({
  curriculumData,
  heading_about_this_course,
}: AppProps) => {
  return (
    <>
      <section
        className="curriculum-section py-3 section-content section-bt"
        id="curriculum"
      >
        <div className="container customwid ">
          <div className="text-center section-heading-lg mb-4">
          { curriculumData && <h4 className="mb-1">Course Curriculum</h4>}  
            <div className="row">
              <p className="fs18 col-md-8 mx-auto">
                {heading_about_this_course}
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-10 px-5 mx-auto">
              <div className="faq-container mx-0 content-collapse mt-0">
                {curriculumData &&
                  curriculumData.length > 0 &&
                  curriculumData.map((item, index) => (
                    <CurriculamItem key={index} title={item?.faq_heading} content={item?.faq_content}/>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Curriculum;
