
const Banner = ({aboutData} : any) => {
    const image = window.location.origin + "/images/about-us-banner.jpg"
    return (
        <>
        <section className="About-banner-top" style={{backgroundColor:'#fbfafd', backgroundImage:`url(${image})`, backgroundRepeat:'no-repeat', backgroundPosition: 'top center'}}>
                <div className="container h-100">
                    <div className="about-top-textprt position-relative"> <img src={aboutData?.banner} className="rounded-4 w-100" alt='banner'/>
                        <div className="banner__info position-absolute bottom-0 start-0 p-5 text-white">
                            <h6 className="fs12 fw500 ls-7 text-uppercase text-white">{aboutData?.banner_heading}</h6>
                            <h1 className="fw700 fs24 lh-lg text-uppercase text-white mb-0">{aboutData?.banner_title}</h1>
                            <p className="fs15 fw500 opacity-75">{aboutData?.banner_content}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner