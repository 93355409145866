import React from 'react'

interface Props {
    job_opportunities_title:any,
    company_logos:any[]
}

function JobOpportunity({job_opportunities_title,company_logos}: Props) {
   

    return (
        <>
        <section className="job-opportunities-sec py-3 section-content section-bt" id=" ">
  <div className="container customwid ">
    <div className="text-center section-heading-lg mb-4">
      <h4>{job_opportunities_title}</h4>
    </div>
    <div className="row pt-2 justify-content-center">
        {
            Array.isArray(company_logos) && company_logos.map((item,index)=>{
               return (<> <div className="col-md-2 col-6 mb-2">
               <figure className="job-logos border shadow-sm"> <img src={item.logo} className="img-fluid"/> </figure>
             </div></>);
            })
        }
     
      
    </div>
  </div>
</section>
        </>
    )
}

export default JobOpportunity
