import { Link, useNavigate } from "react-router-dom";
import BatchItem from "../../items/BatchItem";
import { useDispatch, useSelector } from "react-redux";
import { addtocart } from "../../../store/cart";
import { useEffect } from "react";
import { numberWithCommas, onlyUnique } from "../../../helpers";
import CartModal from "./CartModal";
import AddToCartModal from "./AddToCartModal";
import CartAdded from "./CartAdded";

type AppProps = {
  batchData: any[];
  programData: any;
};

const Batches = ({ batchData, programData }: AppProps) => {
  const navigate = useNavigate();

  // const addTocart = (programData: any) => {
  //   if (programData) {
  //     let countData = localStorage.getItem("countData")
  //       ? localStorage.getItem("countData")
  //       : 0;
  //     let count: number = Number(countData) + 1;
  //     const items = {
  //       program_id: programData?.program_id,
  //       title: programData?.title,
  //       program_fees: programData?.program_fees,
  //       featured_image: programData?.featured_image,
  //     };
  //     localStorage.setItem("countData", count.toString());
  //     let cartData = [];
  //     cartData.push(items);
  //     localStorage.setItem("cartData", JSON.stringify(cartData));
  //     dispatch(increment());
  //   }
  // };
  const cartData = useSelector((state: any) => state.counter.cartData);
  const dispatch = useDispatch();
  const addTocart = (programData: any) => {
    if (programData) {
      const items = {
        program_id: programData?.program_id,
        title: programData?.title,
        category: programData?.category,
        program_fees: programData?.program_fees,
        featured_image: programData?.featured_image,
      };
      dispatch(addtocart(items));
      // localStorage.setItem("cartData", JSON.stringify(cartData));
      //navigate("/checkout");
    }
  };

  useEffect(() => {
    if (cartData.length > 0) {
      let unique = onlyUnique(cartData);
      localStorage.setItem("cartData", JSON.stringify(unique));
    }
  }, [cartData]);

  return (
    <>
      <section className="enroll-feat-section section-spacing-tb50 section-content bg-light-purple-1">
        <div className="container customwid">
          {batchData && Array.isArray(batchData) && batchData.length > 0 && (
            <div className="text-center section-heading-lg mb-5">
              <h4>Find a Flexible Batch for this Programme</h4>
            </div>
          )}

          {batchData && Array.isArray(batchData) && batchData.length !== 0 && (
            <div className="card card-style shadow-sm mb-4">
              <div className="row gx-0">
                <div className="col-sm-8">
                  {batchData &&
                    Array.isArray(batchData) &&
                    batchData.length > 0 && (
                      <div className="course-dates-list position-relative">
                        <div className="course-date-label row gx-0 align-items-center d-none d-sm-flex">
                          <div className="col-sm-4">
                            <span className="date-label fs13">Date</span>
                          </div>
                          <div className="col-sm-4">
                            <span className="date-label fs13">Days</span>
                          </div>
                          <div className="col-sm-4">
                            <span className="date-label fs13">Timing</span>
                          </div>
                        </div>
                        {batchData &&
                          Array.isArray(batchData) &&
                          batchData.length === 0 && (
                            <div
                              className={
                                "course-date-row d-flex justify-content-center"
                              }
                            >
                              No batches found
                            </div>
                          )}
                        {batchData &&
                          Array.isArray(batchData) &&
                          batchData.length > 0 &&
                          batchData?.map((item) => (
                            <BatchItem
                              key={item._id}
                              id={item._id}
                              tag={item.tag}
                              date={item.date}
                              isWeekend={true}
                              days={item.days}
                              time={item.timing}
                              isDisabled={false}
                            />
                          ))}
                      </div>
                    )}
                </div>
                {programData?.program_fees && (
                  <div
                    className={
                      batchData &&
                      Array.isArray(batchData) &&
                      batchData.length > 0
                        ? "col-sm-4 my-1"
                        : ""
                    }
                    // style={{ margin: "auto", border: "1px solid red" }}
                  >
                    <div className="course-fee-details text-center bg-light-purple-1 py-5">
                      {programData?.sale_price && (
                        <h3
                          className="fw600 mb-1"
                          style={{
                            color: "gray",
                            textDecoration: "line-through",
                          }}
                        >
                          INR {numberWithCommas(programData?.program_fees)}
                        </h3>
                      )}
                      <h3 className="fw600 mb-1">
                        INR{" "}
                        {numberWithCommas(
                          programData?.sale_price
                            ? programData?.sale_price
                            : programData?.program_fees
                        )}
                      </h3>
                      {/* <p className="fs15 text-muted mb-0">Starts at Rs. {programData[0].emiMonthly} / month</p> */}
                      <div className="fs12 mb-3">
                        <small>(Exclusive of 18% GST)</small>{" "}
                        {/* <button
                      className="fw500 text-decoration-underline border-0 bg-transparent"
                      type="button"
                    >
                      View More
                    </button> */}
                      </div>

                      <button
                        className="btn btn-success btn-lg px-5 m-2"
                        type="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#staticBackdrop"
                        onClick={() => {
                          addTocart(programData);
                          navigate("/checkout");
                        }}
                      >
                        ENROLL NOW
                      </button>
                      <button
                        // className="btn btn-dark btn-lg px-5 mb-3"
                        className="btn btn-dark btn-lg px-5 m-2 "
                        type="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#staticBackdrop"
                        onClick={() => addTocart(programData)}
                      >
                        ADD TO CART
                      </button>

                      <div className="clearfix"></div>
                      {/* <Link
                    className="fw500 text-decoration-underline fs12 text-muted"
                    to="#"
                  >
                    Secure Transaction
                  </Link> */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* <AddToCartModal title={""} position={"centered"} size={"lg"}>
          <CartAdded/>
        </AddToCartModal> */}
      </section>
    </>
  );
};

export default Batches;
