import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCookieData } from "../store/page";
import Banner from "../components/pagesections/Cookies/Banner";
import Policy from "../components/pagesections/Cookies/Policy";
import NewsLetterCTA from "../components/pagesections/Shared/NewsLetterCTA";
import HomeLoader from "../shared/HomeLoader";

const Cookies = () => {
  const dispatch = useDispatch();
  const cookieData = useSelector<any>(
    (state) => state.pageData.cookiePageData.data
  );

  const loading = useSelector(
    (state: any) => state.pageData.cookiePageData.loading
  );

  useEffect(() => {
    dispatch(fetchCookieData());
  }, [dispatch]);

  return (
    <>
      {loading && <section className="inner-banner-top"><HomeLoader/></section>}
      {!loading && (
        <>
          <Banner cookieData={cookieData} />
          <Policy cookieData={cookieData} />
          {/* <NewsLetterCTA /> */}
        </>
      )}
    </>
  );
};

export default Cookies;
