/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from "react";
import RegisterForm from "../components/pagesections/Register/RegisterForm";
import NewsLetterCTA from "../components/pagesections/Shared/NewsLetterCTA";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoupon, fetchProgrameDetails } from "../store/programe";
import { useParams } from "react-router-dom";
import { removetocart } from "../store/cart";
import { numberWithCommas, onlyUnique } from "../helpers";
import { fetchShareFeedbackData } from "../store/page";
import SignupForm from "../components/pagesections/SignUp/SignupForm";
const Register = () => {
  const dispatch = useDispatch();
  const cart = useSelector((state: any) => state.counter.cartData);
  const couponlist = useSelector((state: any) => state.programData.couponlist);
  const cartData = onlyUnique(cart);
  const [products, setProducts] = useState(cartData);
  var totPrice =
    localStorage.getItem("cartData") &&
    products.reduce(
      (sum: 0, item: any) =>
        sum + Number(item?.sale_price ? item?.sale_price : item.program_fees),
      0
    );
  const [totalPrice, settotalPrice] = useState(totPrice);
  const [coupon, setCoupon] = useState("");
  const [appliedCoupon, setAppliedCoupon] = useState("");
  const [discount, setDiscount] = useState(0);
  const [fixedDiscount, setFixedDiscount] = useState(0);

  useEffect(() => {
    dispatch(fetchCoupon({}));
  }, [dispatch]);

  useEffect(() => {
    setProducts(cartData);
  }, [cartData.length]);

  useEffect(() => {
    if (cartData.length === 0) {
      window.location.href = "/";
    }
  }, [cartData]);

  const discountFlaged = products.map((elm: any) => {
    return { ...elm, discount: false };
  });

  useEffect(() => {
    setProducts(discountFlaged);
  }, []);

  // const percent = totalPrice-discount*(1 + 0.18);
  const applyCoupon = () => {
    // const totPrice:number = parseInt(totalPrice);
    const filterCoupon = couponlist.find(
      (item: any) => item.coupon_code === coupon
    );
    console.log(filterCoupon, "filtercoupon");
    if (filterCoupon) {
      const split = filterCoupon.date_expires.split("-");
      if (new Date(split[2] + "-" + split[1] + "-" + split[0]) <= new Date()) {
        toast.error("Coupon Code is exipired");
        return false;
      }
      if (filterCoupon.discount_type === "fixed_product") {
        const filteredData =
          Array.isArray(products) &&
          products.map((it: any, i: any) => {
            const ids = filterCoupon?.product_ids.map((elm: any) => {
              return parseInt(elm.id);
            });

            if (Array.isArray(ids) && ids.includes(it.program_id)) {
              return {
                ...it,
                discount: true,
                program_fees:
                  parseInt(it.program_fees) - filterCoupon?.coupon_amount,
              };
            } else {
              return it;
            }
          });
        setProducts(filteredData);

        if (parseInt(filterCoupon.coupon_amount) < parseInt(totPrice)) {
          settotalPrice(
            parseInt(totPrice) - parseInt(filterCoupon.coupon_amount)
          );
          toast.success("Coupon Applied");
          setAppliedCoupon(coupon);
          setFixedDiscount(parseInt(filterCoupon?.coupon_amount));
        } else {
          toast.error("Coupon amount must be less then total amount");
        }
      } else {
        const percentage: any =
          (parseInt(totPrice) / 100) * filterCoupon.coupon_amount;
        // setDiscount(parseInt(filterCoupon?.coupon_amount));
        setDiscount(parseInt(percentage));
        // settotalPrice(parseInt(totPrice) - filterCoupon?.coupon_amount);
        toast.success("Coupon Applied");
        setAppliedCoupon(coupon);
      }
    }
    if (!filterCoupon) {
      toast.error("Invalid Coupon Code");
    }
  };

  useEffect(() => {
    settotalPrice(totPrice);
  }, [totPrice]);

  return (
    <>
      <section className="registration-section reg-form-4 pt-3 pb-5">
        <div className="container customwid">
          <div className="form-option-container">
            <div className="row gx-0">
              <div className="col-lg-5">
                <div className="program-detail-column">
                  {/* <div className="pointer"></div> */}
                  <div className="pg-detail-inner">
                    {products &&
                      Array.isArray(products) &&
                      products.length > 0 &&
                      products.map((item: any, index: any) => {
                        return (
                          <>
                            <div
                              className="register-course-block d-flex justify-content-between"
                              key={item?._id}
                            >
                              <div>
                                <p>Programme Name</p>
                                <h4>{item?.title}</h4>
                                {item?.program_id !== 1855 && (
                                  <div
                                    className="h5 d-inline-block text-dark"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {numberWithCommas(
                                      item?.sale_price
                                        ? item?.sale_price
                                        : item?.program_fees
                                    )}{" "}
                                    INR{" "}
                                    {item?.discount === true ? (
                                      `(${fixedDiscount} INR coupon discount applied!)`
                                    ) : (
                                      <></>
                                    )}
                                    {item?.sale_price && (
                                      <s
                                        style={{
                                          color: "gray",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        {numberWithCommas(item?.program_fees)}{" "}
                                        INR{" "}
                                      </s>
                                    )}
                                  </div>
                                )}

                                {item?.program_id === 1855 &&
                                  item?.level &&
                                  Array.isArray(item?.level) &&
                                  item?.level.length > 0 &&
                                  item?.level.map((t: any, i: any) => {
                                    const slval = t?.selectedOption.filter(
                                      (m: any) => {
                                        return (
                                          m.program_type === t.program_title
                                        );
                                      }
                                    );
                                    return (
                                      <>
                                        <hr />
                                        <p
                                          style={{
                                            fontStyle: "italic",
                                            color: "#4f2d7f",
                                          }}
                                        >
                                          {t.program_title}
                                        </p>
                                        <hr />
                                        <p
                                          style={{
                                            fontStyle: "italic",
                                            color: "black",
                                          }}
                                        >
                                          No.Of paper :{" "}
                                          {t.program_sku
                                            ? parseInt(t.program_sku)
                                            : 1}
                                        </p>
                                        <p
                                          style={{
                                            fontStyle: "italic",
                                            color: "black",
                                          }}
                                        >
                                          Amount : {t.program_price}
                                        </p>
                                        <p
                                          style={{
                                            fontStyle: "italic",
                                            color: "black",
                                          }}
                                        >
                                          Paper names :{" "}
                                          {slval.map((j: any, k: any) => {
                                            return <>{j.program_title},</>;
                                          })}
                                        </p>
                                      </>
                                    );
                                  })}
                              </div>

                              <span
                                style={{
                                  height: "25px",
                                  width: "35px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  dispatch(removetocart(item?.program_id))
                                }
                                className="text-white bg-danger d-flex align-items-center justify-content-center"
                              >
                                X
                              </span>
                              {/* <hr/>
                      <CouponCodeInput/> */}
                            </div>
                          </>
                        );
                      })}
                    <hr></hr>
                    <div
                      className="h6 d-inline-block text-dark"
                      style={{
                        fontSize: "15px",
                        width: "100%",
                        fontWeight: 700,
                      }}
                    >
                      Sub Total : {numberWithCommas(totalPrice)} INR{" "}
                    </div>
                    {discount > 0 ? (
                      <div
                        className="h6 d-inline-block text-dark"
                        style={{ fontSize: "15px", fontWeight: 400 }}
                      >
                        DISCOUNT : {numberWithCommas(discount)} INR (
                        {appliedCoupon}){" "}
                      </div>
                    ) : (
                      <></>
                    )}
                    {discount > 0 ? (
                      <div
                        className="h6 d-inline-block text-dark"
                        style={{ fontSize: "15px", fontWeight: 400 }}
                      >
                        DISCOUNTED PRICE :{" "}
                        {numberWithCommas(totalPrice - discount)} INR{" "}
                      </div>
                    ) : (
                      <></>
                    )}
                    <br></br>
                    <div
                      className="h6 d-inline-block text-dark"
                      style={{ fontSize: "15px", fontWeight: 400 }}
                    >
                      GST (18%) :{" "}
                      {numberWithCommas(
                        Number((totalPrice - discount) * 0.18).toFixed(2)
                      )}{" "}
                      INR{" "}
                    </div>{" "}
                    <br></br>
                    <div
                      className="border-[#d9d9d9] w-1/2 mb-2 "
                      style={{
                        borderBottom: "0.5px solid #d9d9d9",
                        width: "100%",
                      }}
                    ></div>
                    {/* <div className="h5 d-inline-block text-dark" style={{ fontSize: "16px", fontWeight: 500,width:"100%" }}>Total Amount: {numberWithCommas(totalPrice-discount+(totalPrice-discount)*0.18)} INR </div> */}
                    <div
                      className="h5 d-inline-block text-dark"
                      style={{
                        fontSize: "16px",
                        fontWeight: 700,
                        width: "100%",
                      }}
                    >
                      Total Amount:{" "}
                      {numberWithCommas(
                        Number(
                          totalPrice - discount + (totalPrice - discount) * 0.18
                        ).toFixed(2)
                      )}{" "}
                      INR{" "}
                    </div>
                    <hr></hr>
                    <h6 className="text-dark" style={{ fontSize: "14px" }}>
                      Got a Coupon Code?
                    </h6>
                    <div className="apply-coupon input-group mb-4">
                      <input
                        type="text"
                        style={{ color: "black" }}
                        className="form-control"
                        placeholder="Enter Coupon Code"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                      />
                      {appliedCoupon?.length > 0 ? (
                        <button
                          className="btn btn-light"
                          type="button"
                          id="coupon"
                          onClick={() => {
                            setDiscount(0),
                              setProducts(() => cartData),
                              setCoupon(""),
                              setAppliedCoupon(""),
                              settotalPrice(totPrice);
                          }}
                        >
                          Remove Coupon
                        </button>
                      ) : (
                        <button
                          className="btn btn-light"
                          type="button"
                          id="coupon"
                          onClick={() => applyCoupon()}
                        >
                          Apply Coupon
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                {appliedCoupon?.length === 0 && (
                  <SignupForm totalPrice={totalPrice} appliedCoupon={""} />
                )}

                {appliedCoupon?.length > 0 && (
                  <SignupForm
                    totalPrice={totalPrice}
                    appliedCoupon={appliedCoupon}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <NewsLetterCTA /> */}
    </>
  );
};

export default Register;
