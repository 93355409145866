import { Link } from "react-router-dom"

const Banner = ({leadershipNewsData}: any) => {
    return (
        <>
            <section className="inner-banner-top " style={{ backgroundImage: `url(${leadershipNewsData?.featured_image})` }}>
                <div className="container customwid h-100">
                    <div className="row h-100">
                        <div className="col-xl-12 breadcrumbmain">
                            <div className="gt-breadcrumb mt-3">
                                <div aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb-item active text-white" aria-current="page">Thought Leadership</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="main-content">
                            <h1 className="banner__title ">{leadershipNewsData?.title}</h1>
                            <p className="mt-2">{leadershipNewsData?.description}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner